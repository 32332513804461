import React from "react";
import { connect } from "react-redux";
import { Input } from "../Input";
import { Button } from "../Button";
import language from "../../language";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import { getStorage } from "../../utils/storage";
import availableOptions from "../../constants/availableOptions";
import { fetchEquipments } from "../../utils/queries/equipmentQuery";
import { fetchPreAssessmentInformation } from "../../utils/queries/preAssessmentQuery";
import moment from "moment";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

const questionFormat = {
  value: "",
  measure: null,
  error: {},
};

class NewSign extends React.Component {
  state = {
    error: {},
    isSubmitting: false,
    name: "",
    code: "",
  };

  componentDidMount = async () => {
    if (this.props._id && this.props._id !== "") {
      this.setState({ ...this.props });
    }
  };

  onChangeText(field, e, index) {
    let { error } = this.state;

    let value = e.target ? e?.target?.value : e;

    delete error[field];
    delete error.errorMessage;

    this.setState({
      [field]: value,
      error,
    });
  }

  validateForm() {
    let { code, error, name } = this.state;

    if (code === "") {
      error.code = language[this.props.defaultLanguage].code_required;
    }

    if (name === "") {
      error.name = language[this.props.defaultLanguage].name_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let { name, code } = this.state,
        url = `${API_URL}/vitalSignMeasure`,
        method = "POST",
        user = await getStorage(),
        { _id } = this.props,
        requestBody = {
          name,
          code,
        };

      if (_id) {
        method = "PUT";
        requestBody.id = _id;
      }

      const options = {
        method,
        url,
        data: requestBody,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        data: requestBody,
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            method === "PUT"
              ? language[this.props.defaultLanguage].update_data_success
              : language[this.props.defaultLanguage].add_data_success
          );

          this.props.getData(true);
          this.props.handleCloseModal();
        })
        .catch((error) => {
          toastMessage("error", error);

          this.setState({
            isSubmitting: false,
          });
        });
    }
  };
  render() {
    return (
      <div className="card-body">
        <div className="row">
          <div className="col-md-12">
            <Input
              options={this.state.code}
              placeholder={
                language[this.props.defaultLanguage].code_placeholder
              }
              label={language[this.props.defaultLanguage].code}
              required
              value={this.state.code}
              onChange={(e) => this.onChangeText("code", e)}
              error={this.state.error.code}
            />
          </div>
          <div className="col-md-12">
            <Input
              options={this.state.name}
              placeholder={
                language[this.props.defaultLanguage].name_placeholder
              }
              label={language[this.props.defaultLanguage].name}
              required
              value={this.state.name}
              onChange={(e) => this.onChangeText("name", e)}
              error={this.state.error.name}
            />
          </div>
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          <Button
            text={language[this.props.defaultLanguage].submit}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewSign);
