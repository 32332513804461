import React from "react";
import language from "../../../language";
import bloodCenterSiteQuery from "../../../utils/queries/bloodCenterSiteQuery";
import bloodCenterQuery from "../../../utils/queries/bloodCenterQuery";
import { Select } from "../../Input";
import { connect } from "react-redux";
import { defineRoleCenterSite } from "../../../utils/handleAccessRoles";

class CenterReceiver extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: {},
      centers: [],
      center: props.center,
      centerSite: props.centerSite,
    };
  }

  componentDidMount = async () => {
    await this.getCenters(true);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.center !== this.props.center) {
      this.setState({ centerSite: null });
      this.getCenterSites(true, { center: this.props?.center?.value });
    }

    if (prevProps.centerSite !== this.props.centerSite) {
      this.setState({ centerSite: this.props.centerSite });
    }
  }

  getCenters = async (isFetchingCenter) => {
    try {
      this.setState({
        isFetchingCenter,
        centerSite: null,
      });

      const { centerRole } = await defineRoleCenterSite();

      const data = await bloodCenterQuery(this.props.defaultLanguage, {
        type: "dropdown",
        center: centerRole,
        page: 1,
        limit: 50,
      });

      this.setSelectedCenter(data);

      this.setState({
        centers: data,
        isFetchingCenter: false,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingCenter: false });
    }
  };

  getCenterSites = async (isFetchingCenterSite, search = {}) => {
    if (!search.center) return [];
    try {
      this.setState({
        isFetchingCenterSite,
        centerSites: [],
        centerSite: undefined,
      });

      const data = await bloodCenterSiteQuery(this.props.defaultLanguage, {
        type: "dropdown",
        page: 1,
        limit: 50,
        ...search,
      });

      this.setSelectedCenterSites(data);

      this.setState({
        centerSites: data,
        isFetchingCenterSite: false,
      });

      return data;
    } catch (error) {
      console.log(error);
      this.setState({ isFetchingCenterSite: false });
    }
  };

  setSelectedCenter = async (centers) => {
    const { center } = this.props;
    if (center) {
      const selectedCenter = centers.find(
        (el) => el.value === (center._id || center)
      );

      if (selectedCenter?._id) {
        this.setState(
          {
            center: {
              label: selectedCenter.name,
              value: selectedCenter._id,
            },
          },
          () => {
            this.getCenterSites(true, { center: selectedCenter._id });
          }
        );
      }
    }
  };

  setSelectedCenterSites(centerSites) {
    const { centerSite } = this.props;
    if (centerSite && centerSite?._id) {
      const selectedData = centerSites.find(
        (el) => el._id === (centerSite._id || centerSite)
      );

      console.log({ selectedData });

      if (selectedData && selectedData._id) {
        this.setState({
          centerSite: {
            label: selectedData.name,
            value: selectedData._id,
          },
        });
      }
    }
  }
  render() {
    return (
      <>
        <div className="row">
          <div className={`col-md-${this.props.size || 4}`}>
            <Select
              options={this.state.centers}
              label={language[this.props.defaultLanguage].center}
              required
              placeholder={language[this.props.defaultLanguage].select}
              value={this.props.center}
              error={this.props.error.center}
              onChange={(e) => this.props.onChangeText("center", e)}
              isLoading={this.state.isFetchingCenter}
            />
          </div>
          <div className={`col-md-${this.props.size || 4}`}>
            <Select
              key={this.state?.center?.value || "no-center"}
              isDisabled={!this.state?.center?.value}
              loadOptions={(e) =>
                this.getCenterSites(true, {
                  center: this.state?.center?.value,
                  q: e,
                })
              }
              label={language[this.props.defaultLanguage].center_site}
              required
              placeholder={language[this.props.defaultLanguage].select}
              value={this.state.centerSite}
              error={this.props.error.centerSite}
              onChange={(e) => this.props.onChangeText("centerSite", e)}
              isLoading={this.state.isFetchingCenterSite}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(CenterReceiver);
