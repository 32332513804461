import React from "react";
import { connect } from "react-redux";
import language from "../../language/index.js";
import { Input } from "../Input";
import { Button } from "../Button";
import { getStorage } from "../../utils/storage.js";
import {
  fetchDonationWithWrongVitalSign,
  correctDonationWithWrongVitalSign,
} from "../../utils/queries/exceptionCases/donationWithWrongVitalSign.js";
import bloodDonationQuery from "../../utils/queries/bloodDonationQuery";
import toastMessage from "../../utils/toastMessage";
import CardHeader from "../Header/CardHeader.js";
import icons from "../../constants/icons.js";
import Table from "../Table/Table.js";

class DonationWithWrongVitalSign extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selectedData: {},
    delete_value: "",
    error: {},
    csvData: [],
    user: {},
    donationNumber: "",
    donations: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();

    await this.setState({ user });
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e?.target?.value,
      error,
    });
  }

  getData = async (isLoading) => {
    try {
      const { defaultLanguage } = this.props;
      const { donationNumber } = this.state;

      this.setState({ isLoading });

      const { data, count } = await fetchDonationWithWrongVitalSign(
        defaultLanguage,
        {
          donationNumber,
          page: 1,
          limit: 10,
        }
      );

      if (data.length === 0) {
        toastMessage(
          "error",
          language[this.props.defaultLanguage].no_data_found
        );
      }

      this.setState({
        data,
        totalPageCount: count,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleShowModal(modal, modalTitle, selectedData = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selectedData,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].center,
        key: "center.name",
      },
      {
        title: language[this.props.defaultLanguage].center_site,
        key: "centerSite.name",
      },
      {
        title: language[this.props.defaultLanguage].donor_number,
        key: "donor.donorNumber",
      },
      {
        title: language[this.props.defaultLanguage].added_by,
        key: "addedBy.name",
      },
      {
        title: language[this.props.defaultLanguage].contact,
        key: "addedBy.contact",
      },
      {
        title: language[this.props.defaultLanguage].date,
        key: "createdAt",
      },
    ];

    return headers;
  }

  handleSearch = async (e) => {
    const search = e?.target?.value;
    this.setState({ search_text: search });
  };

  handleFix = async () => {
    try {
      const { defaultLanguage } = this.props;
      const { donationNumber } = this.state;

      this.setState({ isFixing: true });

      const { data, count } = await correctDonationWithWrongVitalSign(
        defaultLanguage,
        {
          donationNumber,
        }
      );

      this.setState({
        data: data,
        totalPageCount: count,
        isFixing: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isFixing: false,
      });
    }
  };

  validateDonationSearch() {
    let { error, donationNumber } = this.state;

    if (donationNumber === "") {
      error.donationNumber =
        language[this.props.defaultLanguage].donation_number_required;
    }

    this.setState({
      error,
    });
  }

  getDonation = async (searchDonation) => {
    try {
      await this.validateDonationSearch();

      let { error, donationNumber } = this.state;

      if (Object.keys(error).length > 0) return;

      this.setState({
        searchDonation,
      });

      const data = await bloodDonationQuery(this.props.defaultLanguage, {
        donationNumber: donationNumber?.replace(/\s+/g, ""),
      });

      let donation = {};

      if (data.length > 0) {
        donation = data[0];

        this.setState({
          donation,
        });
      } else {
        toastMessage("error", "Donation not found!");
      }

      this.setState({
        searchDonation: false,
      });
    } catch (error) {
      this.setState({ searchDonation: false });
      toastMessage("error", "Donation not found!");
    }
  };

  render() {
    return (
      <>
        <div className="card mb-3">
          <div className="card-body">
            <div className="col-md-12">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].search + "..."
                }
                label={language[this.props.defaultLanguage].donation_number}
                required
                value={this.state.donationNumber}
                error={this.state.error.donationNumber}
                onChange={(e) => this.onChangeText("donationNumber", e)}
                disabled={this.props._id}
              />
            </div>
            <div>
              <Button
                text={language[this.props.defaultLanguage].search}
                onPress={(e) => {
                  e.preventDefault();
                  this.getData(true);
                }}
                isSubmitting={this.state.isLoading}
              />
            </div>
          </div>
        </div>
        {!this.state.isLoading && this.state.data.length > 0 && (
          <div className="card">
            <CardHeader title={`${this.state.data.length} found`} />
            <div className="card-body">
              <Table
                data={this.state.data}
                showAdd
                addButtonText={language[this.props.defaultLanguage].fix}
                addButtonLoading={this.state.isFixing}
                handleAddPressed={this.handleFix.bind(this)}
                page={this.state.page}
                limit={this.state.limit}
                search_text={this.state.search_text}
                totalPageCount={this.state.totalPageCount}
                isLoading={this.state.isLoading}
                handlePagination={this.handlePagination.bind(this)}
                headers={this.returnTableHeaders()}
                filters={[
                  {
                    type: "button",
                    title: language[this.props.defaultLanguage].refresh,
                    button_type: "button",
                    icon: icons.refresh,
                    onPress: (item) => this.getData(true),
                  },
                ]}
              />
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(DonationWithWrongVitalSign);
