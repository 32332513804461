import React from "react";
import { connect } from "react-redux";
import language from "../../language";
import { Table } from "../Table";
import bloodTestQuery, {
  deletebloodtest,
  searchBloodTest,
} from "../../utils/queries/bloodTest/bloodTestQuery";
import { DeleteModal, Modal } from "../Modal";
import {
  defineRole,
  defineRoleCenterSite,
} from "../../utils/handleAccessRoles";
import { getStorage } from "../../utils/storage";
import icons from "../../constants/icons";
import { ROLE_BLOOD_TEST } from "../../constants/ROLES";
import BloodTestFilters from "../Filters/bloodTestFilters/BloodTestFilters";
import Worksheets from "./export/Worksheets";
import LabResults from "./LabResults";
import moment from "moment";
import toastMessage from "../../utils/toastMessage";
import CardCount from "../CardCount/CardCount";
import NewBloodTest from "./NewBloodTest";
import { Loading } from "../Loader";

class BloodTesting extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selectedData: {},
    delete_value: "",
    error: {},
    csvData: [],
    user: {},
    counts: {
      testedBothEntries: 0,
      firstEntryBloodGrouping: 0,
      firstEntrySerology: 0,
      pending: 0,
    },
    filters: {
      startDate: moment().subtract(30, "d").format("YYYY-MM-DD"),
      endDate: moment().endOf("d").format("YYYY-MM-DD"),
    },
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });

    this.fetchData(true, this.state.filters);
  };

  fetchData = async (isLoading, params = {}) => {
    this.getData(true, params);
    this.getCounts("testedBothEntries", isLoading, {
      ...params,
      bloodGroupingTestCount: 2,
      serologyTestCount: 2,
    });

    this.getCounts("firstEntryBloodGrouping", isLoading, {
      ...params,
      bloodGroupingTestCount: 1,
    });

    this.getCounts("firstEntrySerology", isLoading, {
      ...params,
      serologyTestCount: 1,
    });

    this.getCounts("pending", isLoading, {
      ...params,
      tested: false,
    });
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e?.target?.value,
      error,
    });
  }

  returnFilters = async (filters = {}) => {
    const { donationId, isSearch, donorId } = this.props;
    const { centerRole, centerSiteRole } = await defineRoleCenterSite();

    let params = {
      donor: donorId,
      donation: donationId,
      center: centerRole,
      centerSite: centerSiteRole,
      startCollectionDate: moment(filters.startDate)
        .startOf("D")
        .format("YYYY-MM-DD HH:mm"),
      endCollectionDate: moment(filters.endDate)
        .endOf("D")
        .format("YYYY-MM-DD HH:mm"),
      status:
        filters?.status?.value === "pending" ||
        filters?.status?.value === "completed"
          ? filters?.status?.value
          : undefined,
    };

    if (filters?.status?.value === "bloodGroupingTested") {
      params.bloodGroupingTested = true;
    }

    if (filters?.status?.value === "serologyTested") {
      params.serologyTested = true;
    }

    if (params?.search?.q) {
      delete params.startCollectionDate;
      delete params.endCollectionDate;
    }
    if (isSearch) {
      delete params.startCollectionDate;
      delete params.endCollectionDate;
      delete params.endDate;
      delete params.startDate;
    }
    return params;
  };

  getData = async (isLoading, search = {}) => {
    try {
      const { page, limit } = this.state;
      const { status } = this.props;
      const { defaultLanguage, isSearch } = this.props;

      this.setState({ isLoading });

      let additionalFilters = {};

      if (status === "bothEntries") {
        additionalFilters.bloodGroupingTestCount = 2;
        additionalFilters.serologyTestCount = 2;
      }

      if (status === "firstEntryBloodGrouping") {
        additionalFilters.bloodGroupingTestCount = 1;
      }

      if (status === "firstEntrySerology") {
        additionalFilters.serologyTestCount = 1;
      }

      if (status === "pending") {
        additionalFilters.tested = false;
      }

      let params = {
        page,
        limit,
        ...(await this.returnFilters(search)),
        ...additionalFilters,
        ...search,
      };

      const { data, count } = await bloodTestQuery(defaultLanguage, params);

      if (isSearch && data.length === 1) {
        window.location.href = `/dashboard/testing/${data[0]._id}/${data[0]?.donation?.donationNumber}`;

        return;
      }

      this.setState({
        data,
        totalPageCount: count,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
      console.log(error);
      toastMessage("error", error);
    }
  };

  getCounts = async (name, isLoadingCounts, filters) => {
    try {
      const { defaultLanguage } = this.props;
      let { counts } = this.state;

      this.setState({ isLoadingCounts });

      let params = {
        ...(await this.returnFilters(filters)),
        totalCounts: true,
        ...filters,
      };

      delete params.page;
      delete params.limit;

      const data = await bloodTestQuery(defaultLanguage, params);

      counts[name] = data?.totalCount || 0;

      this.setState({
        counts,
        isLoadingCounts: false,
      });
    } catch (error) {
      this.setState({
        isLoadingCounts: false,
      });
      toastMessage("error", error);
      console.log(error);
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true, this.state.filters);
      }
    );
  }

  returnTableHeaders() {
    let headers = [
      this.props.showLabResult && {
        title: language[this.props.defaultLanguage].lab_results,
        type: "labResult",
        onPress: (item) => {
          this.handleShowModal("showLabResults", item.code, item);
        },
      },
      {
        title: language[this.props.defaultLanguage].donation,
        key: "donation.donationNumber",
        type: "link",
        onPress: (item) =>
          (window.location.href = `/dashboard/testing/${item._id}/${item.donation.donationNumber}`),
        styles: {
          textTransform: "uppercase",
        },
        copyEnabled: true,
      },
      {
        title: language[this.props.defaultLanguage].blood_group,
        key: "bloodGroup",
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].center,
        key: "center.name",
      },
      {
        title: language[this.props.defaultLanguage].center_site,
        key: "centerSite.name",
      },
      {
        title: language[this.props.defaultLanguage].activate_donation,
        key: "activateDonation",
      },
      // {
      //   title: language[this.props.defaultLanguage].date,
      //   key: "createdAt",
      //   isMoment: true,
      //   formatTime: "lll",
      // },
      {
        title: language[this.props.defaultLanguage].blood_grouping_tested,
        key: "bloodGroupingTestCount",
        type: "countCheck",
      },
      {
        title: language[this.props.defaultLanguage].serology_tested,
        key: "serologyTestCount",
        type: "countCheck",
      },
      {
        title: language[this.props.defaultLanguage].collection_date,
        key: "donation.collectionDate",
        isMoment: true,
        formatTime: "ll",
      },
      {
        title: language[this.props.defaultLanguage].collection_start_time,
        key: "donation.startTime",
      },
      {
        title: language[this.props.defaultLanguage].collection_end_time,
        key: "donation.endTime",
      },
      {
        title: language[this.props.defaultLanguage].status,
        key: "status",
        type: "status",
      },
      {
        title: language[this.props.defaultLanguage].actions,
        key: "action",
      },
    ];

    return headers.filter(Boolean);
  }

  handleShowModal(modal, modalTitle, selectedData = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selectedData,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  onDelete = async () => {
    try {
      this.setState({ isDeleting: true });

      await deletebloodtest(
        this.props.defaultLanguage,
        this.state.selectedData._id
      );

      this.setState({ isDeleting: false });

      this.getData(true);
      this.handleCloseModal("showDeleteModal");
    } catch (error) {
      console.log(error);
      this.setState({
        isDeleting: false,
      });
    }
  };

  handleSearchButton = async () => {
    try {
      const { search_text } = this.state;
      const { defaultLanguage } = this.props;

      this.setState({ searching: true });

      const data = await searchBloodTest(defaultLanguage, {
        donorNumber: search_text,
      });

      console.log("searched results", data);
      this.setState({ data, searching: false });
    } catch (error) {
      console.log("Blood test error:", error);
      this.setState({ searching: false });
    }
  };

  handleRemoteSearch = async (search) => {
    this.setState({
      searching: true,
    });

    await this.fetchData(true, {
      q: search || search !== "" ? search : undefined,
    });

    this.setState({
      searching: false,
    });
  };

  renderCounts() {
    return (
      <div className="row">
        <div className="col-md-3 ">
          <CardCount
            count={this.state.counts.testedBothEntries}
            title={language[this.props.defaultLanguage].tested_both_entries}
            color="#078ECE"
            icon="check"
            isLoading={this.state.isLoadingCounts}
            onPress={() =>
              (window.location.href = `/dashboard/bloodTesting/bothEntries`)
            }
            isActive={this.props.status === "bothEntries"}
          />
        </div>
        <div className="col-md-3 ">
          <CardCount
            count={this.state.counts.firstEntryBloodGrouping}
            title={
              language[this.props.defaultLanguage].first_entry_blood_grouping
            }
            color="orange"
            icon="labs"
            isLoading={this.state.isLoadingCounts}
            onPress={() =>
              (window.location.href = `/dashboard/bloodTesting/firstEntryBloodGrouping`)
            }
            isActive={this.props.status === "firstEntryBloodGrouping"}
          />
        </div>
        <div className="col-md-3 ">
          <CardCount
            count={this.state.counts.firstEntrySerology}
            title={language[this.props.defaultLanguage].first_entry_serology}
            color="purple"
            icon="lab_panel"
            isLoading={this.state.isLoadingCounts}
            onPress={() =>
              (window.location.href = `/dashboard/bloodTesting/firstEntrySerology`)
            }
            isActive={this.props.status === "firstEntrySerology"}
          />
        </div>
        <div className="col-md-3 ">
          <CardCount
            count={this.state.counts.pending}
            title={language[this.props.defaultLanguage].pending}
            color="gray"
            icon="downloading"
            isLoading={this.state.isLoadingCounts}
            onPress={() =>
              (window.location.href = `/dashboard/bloodTesting/pending`)
            }
            isActive={this.props.status === "pending"}
          />
        </div>
      </div>
    );
  }

  render() {
    // if (this.state.isLoading) return <Loading className="fullscreen" />;
    return (
      <>
        <Table
          data={this.state.data}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          placeholder={`${language[this.props.defaultLanguage].search} ${
            this.props.status || ""
          }`}
          handleSearch={(e) => this.onChangeText("search_text", e)}
          addSearchButton
          searching={this.state.searching}
          handlePressSearch={() =>
            this.handleRemoteSearch(this.state.search_text)
          }
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
          fetchData={(isLoading, search) => this.fetchData(isLoading, search)}
          showAdd={
            this.state.data.length === 0 &&
            !this.props.donorId &&
            defineRole({
              roles: this.state.user.accessRole,
              menu: ROLE_BLOOD_TEST,
              operation: "create",
            })
          }
          addButtonText={language[this.props.defaultLanguage].create}
          handleAddPressed={() =>
            this.state.data.length === 0
              ? this.handleShowModal(
                  "showSearchModal",
                  language[this.props.defaultLanguage].search
                )
              : null
          }
          rowPress={(item) =>
            (window.location.href = `/dashboard/testing/${item._id}/${item?.donation?.donationNumber}`)
          }
          filters={[
            {
              type: "button",
              title: language[this.props.defaultLanguage].refresh,
              button_type: "button",
              icon: icons.refresh,
              onPress: (item) => this.fetchData(true, this.state.filters),
            },
            !this.props.donorId && {
              type: "dropdown",
              filterName: "center",
              title: language[this.props.defaultLanguage].center,
              button_type: "dropdown",
              handleApplyFilters: (isLoading, search) =>
                this.fetchData(isLoading, search),
            },
            !this.props.donorId && {
              type: "dropdown",
              filterName: "bloodGroup",
              title: language[this.props.defaultLanguage].blood_group,
              button_type: "dropdown",
              handleApplyFilters: (isLoading, search) =>
                this.fetchData(isLoading, search),
            },
            !this.props.donorId && {
              type: "dropdown",
              filterName: "date_range",
              title: language[this.props.defaultLanguage].date_range,
              button_type: "dropdown",
              handleApplyFilters: (isLoading, search) =>
                this.fetchData(isLoading, search),
              startDate: this.state.filters.startDate,
              endDate: this.state.filters.endDate,
            },
            // {
            //   type: "button",
            //   title: language[this.props.defaultLanguage].filters,
            //   button_type: "button",
            //   icon: icons.filters,
            //   onPress: (item) =>
            //     this.handleShowModal(
            //       "filtersModal",
            //       language[this.props.defaultLanguage].filters
            //     ),
            // },
            !this.props.donorId && {
              type: "button",
              title: language[this.props.defaultLanguage].generate_work_sheet,
              button_type: "button",
              icon: icons.export,
              onPress: (item) =>
                this.handleShowModal(
                  "exportModal",
                  language[this.props.defaultLanguage].generate_work_sheet
                ),
              className: "primary",
            },
          ].filter(Boolean)}
          actions={[
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_BLOOD_TEST,
                operation: "testResult",
              }),
              name: language[this.props.defaultLanguage].results,
              onPress: (item) =>
                (window.location.href = `/dashboard/testing/${item._id}`),
              icon: icons.eye_on,
            },
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_BLOOD_TEST,
                operation: "delete",
              }),
              name: language[this.props.defaultLanguage].delete,
              onPress: (item) =>
                this.handleShowModal(
                  "showDeleteModal",
                  language[this.props.defaultLanguage].delete,
                  item
                ),
              icon: icons.delete,
            },
          ]}
          counts={!this.props.donorId && this.renderCounts()}
        />

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showSearchModal")}
          show={this.state.showSearchModal}
          title={this.state.modalTitle}
        >
          <NewBloodTest
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showSearchModal"
            )}
            getData={() => this.getData(true, {})}
          />
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "filtersModal")}
          show={this.state.filtersModal}
          title={this.state.modalTitle}
        >
          <div className="card-body">
            <div className="row">
              <BloodTestFilters
                fetchData={(isLoading, params) => {
                  this.fetchData(isLoading, params);
                }}
                handleCloseModal={this.handleCloseModal.bind(
                  this,
                  "filtersModal"
                )}
              />
            </div>
          </div>
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "exportModal")}
          show={this.state.exportModal}
          title={this.state.modalTitle}
          size="fullscreen"
        >
          <div className="card-body">
            <div className="row">
              <Worksheets />
            </div>
          </div>
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showDeleteModal")}
          show={this.state.showDeleteModal}
          title={this.state.modalTitle}
        >
          <DeleteModal
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showDeleteModal"
            )}
            error={this.state.error.delete_password}
            value={this.state.delete_value}
            onDelete={this.onDelete.bind(this)}
            onChange={(e) => this.onChangeText("delete_value", e)}
            isDeleting={this.state.isDeleting}
            name={this.state.selectedData._id}
            getData={this.getData.bind(this)}
          />
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showLabResults")}
          show={this.state.showLabResults}
          title={this.state.modalTitle}
          size="fullscreen"
        >
          <LabResults testId={this.state.selectedData?._id} />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(BloodTesting);
