export const generateRandomString = (length) => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

export const generateRandomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const generateShipmentNumber = (value = 0) => {
  // Generate a random number between min and max
  let incValue = value;
  let formattedNumber;
  if (incValue < 10) {
    formattedNumber = "000000" + incValue; // 1 to 9
  } else if (incValue < 100) {
    formattedNumber = "00000" + incValue; // 10 to 99
  } else if (incValue < 1000) {
    formattedNumber = "0000" + incValue; // 100 to 999
  } else if (incValue < 10000) {
    formattedNumber = "000" + incValue; // 1000 to 9999
  } else if (incValue < 100000) {
    formattedNumber = "00" + incValue; // 10000 to 99999
  } else if (incValue < 1000000) {
    formattedNumber = "0" + incValue; // 100000 to 999999
  } else {
    formattedNumber = Math.floor(Math.random() * (1000000 - 0 + 1)) + 0;
  }

  // Format to always have 7 digits with leading zeros
  return formattedNumber;
};

export const generateRandomCode = () => {
  // Get the current date
  const date = new Date();
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
  const day = date.getDate().toString().padStart(2, "0");
  const second = date.getSeconds().toString();

  // Generate a random number
  const randomNumber = Math.floor(1000 + Math.random() * 9000).toString(); // Random 4 digit number

  // Combine the date and random number to create the invoice number
  const codeNumber = `${year}${month}${day}${second}-${randomNumber}`;

  return codeNumber;
};
