import toastMessage from "../toastMessage";
import { getStorage } from "../storage";
import language from "../../language";
import api from "../api";
import formatSelectData from "../formatSelectData";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

export const fetchRejectionStatus = async (type, params = {}) => {
  try {
    const user = await getStorage();

    const options = {
      method: "GET",
      url: `${API_URL}/rejectionStatus`,
      params: {
        ...params,
        page: params.page | 1,
        limit: params.limit | 50,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    console.log(params);

    const queryRes = await api(options);

    if (type === "dropdown") {
      return formatSelectData(queryRes.data, "label", "type");
    }

    return queryRes.data;
  } catch (error) {
    toastMessage("error", error);
  }
};

export const deleteRejectionStatus = async (lng = "english", id) => {
  try {
    const user = await getStorage();

    const options = {
      method: "DELETE",
      url: `${API_URL}/rejectionStatus`,
      data: {
        id,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    await api(options);

    toastMessage("success", language[lng].success_delete_data);
  } catch (error) {
    toastMessage("error", error);
  }
};
