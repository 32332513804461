import React from "react";
import {
  deletePreAssessmentDonorsInfo,
  fetchPreAssessmentInformation,
} from "../../../../utils/queries/preAssessmentQuery";
import { connect } from "react-redux";
import { ListItem } from "../../../List";
import language from "../../../../language";
import icons from "../../../../constants/icons";
import moment from "moment";
import { DeleteModal, Modal } from "../../../Modal";
import { Table } from "../../../Table";
import handleSearch from "../../../../utils/handleSearch";
import "./styles.css";
import { getStorage } from "../../../../utils/storage";
import {
  defineRole,
  defineRoleCenterSite,
} from "../../../../utils/handleAccessRoles";
import exportPDF from "../../../../utils/exportPDF";
import AnswerResult from "./AnswerResult";
import { Appointments } from "../../../BookAppointment";
import toastMessage from "../../../../utils/toastMessage";
import { ROLE_ASSESSMENT } from "../../../../constants/ROLES";
import accessMenu from "../../../../utils/accessMenu";

let copyData = [];

class PreAssessment extends React.Component {
  state = {
    isLoading: false,
    data: [],
    selectedData: {},
    user: {},
    error: {},
  };

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    try {
      this.setState({ isLoading: true });

      const user = await getStorage();
      const { page, limit } = this.state;
      const { centerId, siteId } = this.props;
      const { centerRole, centerSiteRole } = await defineRoleCenterSite();

      const data = await fetchPreAssessmentInformation(
        this.props.defaultLanguage,
        {
          page,
          limit,
          center: centerId || centerRole,
          centerSite: siteId || centerSiteRole,
          donor: this.props.donorId
            ? this.props.donorId
            : this.props.route === "/user"
            ? user.id
            : undefined,
        }
      );

      this.setState({
        data,
        isLoading: false,
        user,
      });

      copyData = data.slice(0);
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  onChangeText(field, e) {
    const { error } = this.state;

    delete error[field];
    this.setState({
      [field]: e.target ? e?.target?.value : e,
      error,
    });
  }

  handleShowModal(modal, title, data) {
    this.setState({
      [modal]: true,
      modalTitle: title,
      selectedData: data || {},
      date: moment(data?.createdAt).format("lll"),
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].requested_date,
        key: "requestedDate",
        isMoment: true,
        formatTime: "lll",
      },
      {
        title: language[this.props.defaultLanguage].donor_number,
        key: "donor.donorNumber",
        type: "link",
        onPress: (item) =>
          this.handleShowModal(
            "showAnswerModal",
            item?.donor?.donorNumber,
            item
          ),
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].first_name,
        key: "donor.firstName",
      },
      {
        title: language[this.props.defaultLanguage].last_name,
        key: "donor.lastName",
      },
      {
        title: language[this.props.defaultLanguage].center,
        key: "center.name",
      },
      {
        title: language[this.props.defaultLanguage].center_site,
        key: "centerSite.name",
      },
      this.state.user?.role && {
        title: language[this.props.defaultLanguage].selector,
        key: "selector.name",
      },
      {
        title: language[this.props.defaultLanguage].created_at,
        key: "createdAt",
        isMoment: true,
        formatTime: "lll",
      },
      {
        title: language[this.props.defaultLanguage].actions,
        key: "action",
      },
    ].filter(Boolean);

    return headers;
  }

  onDelete = async () => {
    try {
      this.setState({ isDeleting: true });

      await deletePreAssessmentDonorsInfo(
        this.props.defaultLanguage,
        this.state.selectedData._id
      );

      this.setState({ isDeleting: false });

      this.getData(true);
      this.handleCloseModal("showDeleteModal");
    } catch (error) {
      console.log(error);
      this.setState({
        isDeleting: false,
      });
    }
  };

  downloadExcel = async () => {
    try {
      this.setState({
        isLoading: true,
      });

      const { page, limit } = this.state;
      const { defaultLanguage, isActive } = this.props;

      const { data } = await fetchPreAssessmentInformation(defaultLanguage, {
        page,
        limit,
        active: isActive,
      });

      this.setState({
        isLoading: false,
        csvData: data,
      });

      this.refs.csvDownload?.link.click();
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  downloadPDF = async () => {
    this.setState({
      isLoading: true,
    });

    const { page, limit } = this.state;
    const { defaultLanguage, isActive } = this.props;

    const headers = this.returnTableHeaders();

    const { data } = await fetchPreAssessmentInformation(defaultLanguage, {
      page,
      limit,
      active: isActive,
    });

    this.setState({
      isLoading: false,
    });

    exportPDF("My PreAssessments", headers, data);
  };

  render() {
    const { canReadAssessment, canDeleteAssessment, canCreateAssessment } =
      accessMenu();
    return (
      <>
        <>
          <Table
            data={this.state.data}
            isSearch
            page={this.state.page}
            limit={this.state.limit}
            search_text={this.state.search_text}
            handleSearch={(e) =>
              handleSearch(this, {
                copyData,
                value: e?.target?.value,
              })
            }
            showAdd={canCreateAssessment}
            handleAddPressed={() =>
              this.handleShowModal(
                "showNewAppointmentFormModal",
                language[this.props.defaultLanguage].select_appointment
              )
            }
            totalPageCount={this.state.totalPageCount}
            isLoading={this.state.isLoading}
            handlePagination={this.handlePagination.bind(this)}
            headers={this.returnTableHeaders()}
            filters={[
              {
                type: "button",
                title: language[this.props.defaultLanguage].refresh,
                button_type: "button",
                icon: icons.refresh,
                onPress: (item) => this.getData(true),
              },
            ]}
            actions={[
              {
                canAccess: canReadAssessment,
                name: language[this.props.defaultLanguage].view + "",
                onPress: (item) =>
                  this.handleShowModal(
                    "showAnswerModal",
                    item?.donor?.donorNumber,
                    item
                  ),
                icon: icons.eye_on,
              },
              {
                canAccess: canDeleteAssessment,
                name: language[this.props.defaultLanguage].delete + "",
                onPress: (item) => {
                  if (item.status !== "pending")
                    return toastMessage(
                      "error",
                      language[this.props.defaultLanguage].can_not_be_deleted
                    );

                  this.handleShowModal(
                    "showDeleteModal",
                    language[this.props.defaultLanguage].delete,
                    item
                  );
                },
                icon: icons.delete,
              },
            ]}
          />
        </>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showAnswerModal")}
          show={this.state.showAnswerModal}
          title={this.state.modalTitle}
          size="lg"
        >
          <AnswerResult
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showAnswerModal"
            )}
            selectedData={this.state.selectedData}
          />
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showDeleteModal")}
          show={this.state.showDeleteModal}
          title={this.state.modalTitle}
        >
          <DeleteModal
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showDeleteModal"
            )}
            error={this.state.error.delete_password}
            value={this.state.delete_value}
            onDelete={this.onDelete.bind(this)}
            onChange={(e) => this.onChangeText("delete_value", e)}
            isDeleting={this.state.isDeleting}
            name={this.state.selectedData.requestedDate}
            getData={this.getData.bind(this)}
          />
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showAppointmentModal")}
          show={this.state.showAppointmentModal}
          title={this.state.modalTitle}
          size="lg"
        >
          {this.props.donorId && (
            <Appointments
              donorId={this.props.donorId}
              route={this.props.route}
              selection
            />
          )}
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(
            this,
            "showNewAppointmentFormModal"
          )}
          show={this.state.showNewAppointmentFormModal}
          title={this.state.modalTitle}
          size="lg"
        >
          <div className="card-body">
            {this.props.donorId && (
              <Appointments
                donorId={this.props.donorId}
                route={this.props.route}
                selection
              />
            )}
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;

  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(PreAssessment);
