import React from "react";
import { connect } from "react-redux";
import language from "../../language/index.js";
import { Table } from "../Table/index.js";
import icons from "../../constants/icons.js";
import { getStorage } from "../../utils/storage.js";
import { fetchDonationWithWrongVitalSign } from "../../utils/queries/exceptionCases/donationWithWrongVitalSign.js";
import { fetchDonationWithoutDonor } from "../../utils/queries/exceptionCases/donationWithoutDonor.js";

class DonationWithoutDonor extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selectedData: {},
    delete_value: "",
    error: {},
    csvData: [],
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();

    await this.setState({ user });

    await this.getData(true);
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e?.target?.value,
      error,
    });
  }

  getData = async (isLoading, search = {}) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const { data, count } = await fetchDonationWithoutDonor(defaultLanguage, {
        ...search,
      });

      this.setState({
        data: data,
        totalPageCount: count,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleShowModal(modal, modalTitle, selectedData = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selectedData,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].donation_number,
        key: "donationNumber",
      },
      {
        title: language[this.props.defaultLanguage].collection_date,
        key: "collectionDate",
      },
      {
        title: language[this.props.defaultLanguage].phlebotomist,
        key: "phlebotomist.pin",
      },
      {
        title: language[this.props.defaultLanguage].added_by,
        key: "addedBy.pin",
      },
      {
        title: language[this.props.defaultLanguage].center,
        key: "center.name",
      },
      {
        title: language[this.props.defaultLanguage].center_site,
        key: "centerSite.name",
      },
      {
        title: language[this.props.defaultLanguage].date,
        key: "createdAt",
      },
    ];

    return headers;
  }

  handleSearch = async (e) => {
    const search = e?.target?.value;
    this.setState({ search_text: search });
  };

  handleRemoteSearch = async (search) => {
    this.setState({
      searching: true,
    });

    await this.getData(true, {
      code: search || search !== "" ? search : undefined,
    });

    this.setState({
      searching: false,
    });
  };

  handleRemoteSearch = async (search) => {
    this.setState({
      searching: true,
    });

    await this.getData(true, {
      q: search || search !== "" ? search : undefined,
    });

    this.setState({
      searching: false,
    });
  };

  handleSearch = async (e) => {
    const search = e?.target?.value;
    this.setState({ search_text: search });
  };

  render() {
    return (
      <>
        <Table
          data={this.state.data}
          isSearch
          addSearchButton
          searching={this.state.searching}
          handleSearch={this.handleSearch.bind(this)}
          handlePressSearch={() =>
            this.handleRemoteSearch(this.state.search_text)
          }
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
          filters={[
            {
              type: "button",
              title: language[this.props.defaultLanguage].refresh,
              button_type: "button",
              icon: icons.refresh,
              onPress: (item) => this.getData(true),
            },
          ]}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(DonationWithoutDonor);
