import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../Input";
import { Button } from "../Button";
import language from "../../language";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import hospitalQuery from "../../utils/queries/hospitalQuery";
import bloodDonationQuery from "../../utils/queries/bloodDonationQuery";
import icons from "../../constants/icons";
import { getStorage } from "../../utils/storage";
import moment from "moment";
import { fetchPatients } from "../../utils/queries/patient";
import { fetchInventories } from "../../utils/queries/inventories/center";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

class NewUsage extends React.Component {
  state = {
    patientId: "",
    error: {},
    isSubmitting: false,
    hospitals: [],
    donations: [],
    patientId: "",
    donation: "",
    usageDate: moment().format("YYYY-MM-DD"),
  };

  componentDidMount = async () => {
    this.getHospitals(true);

    if (this.props._id) {
      this.setState({
        donation: this.props.donation
          ? this.props.donation._id
          : this.props.donation,
        hospital: this.props.hospital
          ? {
              label: this.props.hospital.name,
              value: this.props.hospital._id,
            }
          : null,
        product: this.props.product
          ? {
              label: this.props.product.code,
              value: this.props.product._id,
            }
          : null,
        patient: this.props.patientId
          ? {
              label: this.props.patientId?.name,
              value: this.props.patientId?._id,
            }
          : null,
        usageDate: moment(this.props.usageDate).format("YYYY-MM-DD"),
        reason: this.props.reason,
      });
    }
  };

  getHospitals = async (isFetchingHospitals, code) => {
    try {
      this.setState({
        isFetchingHospitals,
      });

      const data = await hospitalQuery(this.props.defaultLanguage, {
        type: "dropdown",
        code: code ? code : undefined,
      });

      this.setState({
        hospitals: data,
        isFetchingHospitals: false,
      });
      return data;
    } catch (error) {
      this.setState({ isFetchingHospitals: false });
    }
  };

  getPatients = async (isFetchingPatient, q) => {
    try {
      this.setState({
        isFetchingPatient,
      });

      const data = await fetchPatients(this.props.defaultLanguage, {
        type: "dropdown",
        q,
      });

      this.setState({
        patients: data,
        isFetchingPatient: false,
      });
      return data;
    } catch (error) {
      this.setState({ isFetchingPatient: false });
    }
  };

  handleSearchDonation = async (isFetchingDonation) => {
    const { donation } = this.state;
    try {
      this.setState({
        isFetchingDonation,
      });

      const data = await fetchInventories(this.props.defaultLanguage, {
        type: "dropdown",
        code: donation?.replace(/\s+/g, ""),
      });

      this.setState({
        donationInfo:
          data.length > 0
            ? {
                ...data[0],
              }
            : {},
        isFetchingDonation: false,
      });
    } catch (error) {
      this.setState({ isFetchingDonation: false });
    }
  };

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e.target ? e?.target?.value : e;

    delete error[field];

    await this.setState({
      [field]: inputValue,
      error,
    });
  };

  validateForm() {
    let { hospital, patient, requesterPosition, error } = this.state;

    if (hospital === "") {
      error.hospital = language[this.props.defaultLanguage].hospital_required;
    }

    if (patient === "") {
      error.patient = language[this.props.defaultLanguage].patient_id_required;
    }

    if (requesterPosition === "") {
      error.requesterPosition =
        language[this.props.defaultLanguage].requester_position_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let {
          hospital,
          requesterName,
          requesterPosition,
          reason,
          usageDate,
          donationInfo,
          patient,
        } = this.state,
        url = `${API_URL}/bloodusage`,
        method = "POST",
        { _id } = this.props,
        user = await getStorage();

      let requestedBody = {
        product: donationInfo?._id,
        donation: donationInfo?.donation?._id,
        requester: {
          name: requesterName,
          position: requesterPosition,
        },
        hospital: hospital.value,
        usageDate: usageDate,
        reason: reason,
        patient: patient?.value,
      };

      if (_id && _id !== "") {
        method = "PUT";
        requestedBody.id = _id;
      }

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
        data: requestedBody,
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            language[this.props.defaultLanguage][
              method === "PUT" ? "data_update_success" : "add_data_success"
            ]
          );

          this.props.getData(true);
          this.props.handleCloseModal();
        })
        .catch((error) => {
          toastMessage("error", error);
        });
    }
  };

  render() {
    console.log(this.state.donationInfo);
    return (
      <div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <Input
                leftIcon={icons.bloodtype}
                placeholder={language[this.props.defaultLanguage].search}
                label={language[this.props.defaultLanguage].product}
                required
                value={this.state.donation}
                onChange={(e) => this.onChangeText("donation", e)}
                error={this.state.error.donation}
                rightButtonIcon={icons.search}
                rightButtonPressed={this.handleSearchDonation.bind(this)}
                rightButtonSubmitting={this.state.isFetchingDonation}
              />
            </div>
            {this.state?.donationInfo?.donation?.donationNumber && (
              <div className="col-md-12">
                <Input
                  leftIcon={icons.donation}
                  label={language[this.props.defaultLanguage].donation_number}
                  required
                  value={this.state.donationInfo.donation.donationNumber}
                  onChange={(e) => this.onChangeText("donation", e)}
                  disabled
                />
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-md-12">
              <Select
                loadOptions={(e) => this.getHospitals(true, e)}
                leftIcon={icons.hospitals}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].hospital}
                required
                value={this.state.hospital}
                onChange={(e) => this.onChangeText("hospital", e)}
                error={this.state.error.hospital}
                isLoading={this.state.isLoadingHospitals}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Select
                loadOptions={(e) => this.getPatients(true, e)}
                leftIcon={icons.users}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].patient}
                required
                value={this.state.patient}
                onChange={(e) => this.onChangeText("patient", e)}
                error={this.state.error.patient}
                isLoading={this.state.isFetchingPatient}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Input
                leftIcon={icons.calendar}
                label={language[this.props.defaultLanguage].usage_date}
                required
                value={this.state.usageDate}
                onChange={(e) => this.onChangeText("usageDate", e)}
                error={this.state.error.usageDate}
                type="date"
              />
            </div>
            <div className="col-md-12">
              <Input
                leftIcon={icons.reason}
                label={language[this.props.defaultLanguage].reason}
                placeholder={
                  language[this.props.defaultLanguage].reason_placeholder
                }
                required
                value={this.state.reason}
                onChange={(e) => this.onChangeText("reason", e)}
                error={this.state.error.reason}
                textarea
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          <Button
            text={language[this.props.defaultLanguage].submit}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
            disabled={Object.keys(this.state.donationInfo || {}).length === 0}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewUsage);
