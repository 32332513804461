import React from "react";
import { Checkbox } from "../Input";
import bloodDonationQuery from "../../utils/queries/bloodDonationQuery";
import { onAddFilter, onResetFilters } from "../../actions/Filters";
import { Loading } from "../Loader";
import { CardHeader } from "../Header";
import { connect } from "react-redux";
import handleSearch from "../../utils/handleSearch";

let copyData = [];

class BloodCollectionFilter extends React.Component {
  state = {
    isLoading: true,
    data: [],
    page: 1,
    limit: 10,
  };

  componentDidMount = async () => {
    await this.getData(true);
  };

  componentDidUpdate(prevProps) {
    if (this.props.serviceType !== prevProps.serviceType) {
      this.dispatch(onResetFilters("bloodCollections"));
      this.getData(true);
    }
  }

  getData = async (isLoading) => {
    try {
      const { defaultLanguage } = this.props;
      const { page, limit } = this.state;

      this.setState({ isLoading });

      const { data, count } = await bloodDonationQuery(defaultLanguage, {
        page,
        limit,
      });

      this.setState({
        data: data,
        isLoading: false,
        count,
      });

      copyData = data.slice(0);
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  onCheck(el) {
    let { filters } = this.props;
    let { bloodCollections = [] } = filters;

    const index = bloodCollections.findIndex((sEl) => sEl.value === el._id);

    if (index === -1) {
      bloodCollections.push({
        value: el._id,
        label: el.name,
      });
    } else {
      bloodCollections.splice(index, 1);
    }

    // this.props.dispatch(
    //   onAddFilter({
    //     bloodCollections,
    //   })
    // );
    this.props.handleApplyFilters &&
      this.props.handleApplyFilters(true, { bloodCollections });
  }

  renderHeader() {
    return (
      <>
        <CardHeader
          isSearch
          handleSearch={(e) =>
            handleSearch(this, { copyData, value: e?.target?.value })
          }
          styles={{ height: 0 }}
          reset={
            this.props?.filters?.bloodCollections &&
            this.props?.filters?.bloodCollections?.length > 0
          }
          handleResetFilter={() =>
            this.props.dispatch(onResetFilters("bloodCollections"))
          }
        />
      </>
    );
  }

  render() {
    return (
      <>
        {this.renderHeader()}
        <div className="dropdown-content">
          {this.state.isLoading ? (
            <center>
              <Loading />
            </center>
          ) : (
            this.state?.data?.map((el, i) => {
              return (
                <Checkbox
                  label={el.name}
                  key={i}
                  onCheck={() => this.onCheck(el)}
                  checked={this.props?.filters?.bloodCollections?.find(
                    (sEl) => sEl.value === el._id
                  )}
                />
              );
            })
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(BloodCollectionFilter);
