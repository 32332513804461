import toastMessage from "../toastMessage";
import { getStorage } from "../storage";
import axios from "axios";
import language from "../../language";
import api from "../api";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

export const fetchBagStatus = async (lng = "english", params = {}) => {
  const { page, limit, id } = params;
  try {
    const user = await getStorage();

    const options = {
      method: "GET",
      url: `${API_URL}/bagStatus`,
      params: {
        page: page,
        limit: limit,
        id,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await api(options);

    let { data, count } = queryRes.data;

    return !page && !limit ? queryRes.data : { data, count };
  } catch (error) {
    toastMessage("error", error);
  }
};

export const deleteBagStatus = async (lng = "english", id) => {
  try {
    const user = await getStorage();

    const options = {
      method: "DELETE",
      url: `${API_URL}/bagStatus`,
      data: {
        id,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    await api(options);

    toastMessage("success", language[lng].success_delete_data);
  } catch (error) {
    toastMessage("error", error);
  }
};
