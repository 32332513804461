import React from "react";
import { connect } from "react-redux";
import { Input } from "../Input";
import { getStorage } from "../../utils/storage";
import icons from "../../constants/icons";
import language from "../../language";
import { defineRoleCenterSite } from "../../utils/handleAccessRoles";
class MyProfile extends React.Component {
  state = {
    email: "",
    displayName: "",
    phoneNumber: "",
    error: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();
    const { centerRole, centerSiteRole } = await defineRoleCenterSite();
    this.setState({ ...user, centerRole, centerSiteRole });
  };

  onChangeText(field, e) {
    let { error } = this.state;
    delete error[field];

    this.setState({
      [field]: e?.target?.value,
      error,
    });
  }
  render() {
    return (
      <>
        <div className="row">
          <div className="col-md-4">
            <Input
              placeholder={
                language[this.props.defaultLanguage].donor_number_placeholder
              }
              label={language[this.props.defaultLanguage].donor_number}
              required
              value={this.state.donorNumber}
              onChange={(e) => this.onChangeText("donorNumber", e)}
              error={this.state.error.donorNumber}
              leftIcon={icons.donor}
              disabled
            />
            <Input
              placeholder={
                language[this.props.defaultLanguage].first_name_placeholder
              }
              label={language[this.props.defaultLanguage].first_name}
              required
              value={this.state.firstName}
              onChange={(e) => this.onChangeText("firstName", e)}
              error={this.state.error.firstName}
              leftIcon={icons.user}
              disabled
            />
            <Input
              placeholder={
                language[this.props.defaultLanguage].last_name_placeholder
              }
              label={language[this.props.defaultLanguage].last_name}
              required
              value={this.state.lastName}
              onChange={(e) => this.onChangeText("lastName", e)}
              error={this.state.error.lastName}
              leftIcon={icons.user}
              disabled
            />
            <Input
              placeholder={
                language[this.props.defaultLanguage].username_placeholder
              }
              label={language[this.props.defaultLanguage].username}
              required
              value={this.state.username}
              onChange={(e) => this.onChangeText("username", e)}
              error={this.state.error.username}
              leftIcon={icons.user}
              disabled
            />
            <Input
              placeholder="Enter email address"
              label="Email Address"
              required
              value={this.state.email}
              onChange={(e) => this.onChangeText("email", e)}
              leftIcon={icons.email}
              disabled
            />
            <Input
              label={language[this.props.defaultLanguage].date_of_birth}
              required
              value={this.state.dateOfBirth}
              onChange={(e) => this.onChangeText("dateOfBirth", e)}
              type="date"
              disabled
            />
            {this.state.centerRole && (
              <Input
                label={language[this.props.defaultLanguage].center}
                required
                value={this.state.centerRole}
                disabled
              />
            )}

            {this.state.centerSiteRole && (
              <Input
                label={language[this.props.defaultLanguage].center_site}
                required
                value={this.state.centerSiteRole}
                disabled
              />
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(MyProfile);
