import React from "react";
import { DropdownButton } from "../Button";

import { Loading } from "../Loader";
import icons from "../../constants/icons";
import { CardHeader } from "../Header";
import { connect } from "react-redux";
import language from "../../language";
import { Checkbox } from "../Input";
import { onAddFilter } from "../../actions/Filters";
import { donorCategories } from "../../constants/donorCargetories";

let copyData = [];

class DonorCategoryFilter extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 50,
  };

  componentDidMount = async () => {
    const data = [
      {
        label: "New Donors",
        value: "new",
      },
      {
        label: "Regular Donors",
        value: "regular",
      },
      {
        label: "Irregular Donors",
        value: "irregular",
      },
      {
        label: "Dropped Donors",
        value: "dropped",
      },
    ];

    this.setState({
      data: donorCategories(this.props.defaultLanguage),
    });

    if (!this.props.filters.donorCategory) {
      // this.props.dispatch(
      //   onAddFilter({
      //     donorCategory: data[0].value,
      //   })
      // );
      this.props.handleApplyFilters &&
        this.props.handleApplyFilters(true, { donorCategory: data[0].value });
    }
  };

  onCheck = async (el) => {
    // let { filters } = this.props;

    // await this.props.dispatch(
    //   onAddFilter({
    //     donorCategory: el.value,
    //   })
    // );

    this.props.handleApplyFilters &&
      this.props.handleApplyFilters({ donorCategory: el.value });
  };

  handleSearch(e) {
    const searchValue = e?.target?.value;
    this.setState({ searchValue });

    let results = [];
    copyData.forEach((el) => {
      if (
        JSON.stringify(el).toLowerCase().indexOf(searchValue.toLowerCase()) !==
        -1
      ) {
        results.push(el);
      }
    });

    this.setState({ data: results });
  }

  render() {
    return (
      <DropdownButton
        leftIcon={icons.center}
        text={language[this.props.defaultLanguage].filter_donor_category}
        className="btn-transparent btn-bordered w-sm-100 btn-sm"
        selectedData={this.props?.filters?.donorCategory}
        clickOutside
      >
        <CardHeader
          title={language[this.props.defaultLanguage].filter_centers}
          borderBottom
          closeButton
          isSearch
        />
        <div className="dropdown-content">
          {this.state.isLoading ? (
            <center>
              <Loading />
            </center>
          ) : (
            this.state?.data?.map((el, i) => {
              return (
                <Checkbox
                  label={el.label}
                  key={i}
                  onCheck={() => this.onCheck(el)}
                  checked={this.props?.filters?.donorCategory === el.value}
                />
              );
            })
          )}
        </div>
      </DropdownButton>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(DonorCategoryFilter);
