import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../../Input";
import { Button } from "../../Button";
import language from "../../../language";
import axios from "axios";
import toastMessage from "../../../utils/toastMessage";
import bloodDonationQuery from "../../../utils/queries/bloodDonationQuery";
import { getStorage } from "../../../utils/storage";
import availableOptions from "../../../constants/availableOptions";
import { fetchComponentsSeparationProfiles } from "../../../utils/queries/production/profiles/separation";
import icons from "../../../constants/icons";
import { Modal } from "../../Modal";
import Separations from "./Separations";
import { CardHeader } from "../../Header";
import { fetchEquipments } from "../../../utils/queries/equipmentQuery";
import _ from "lodash";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

let copyComponents = {};

class NewSeparation extends React.Component {
  state = {
    username: "",
    error: {},
    isSubmitting: false,
    donation: {},
    isLoading: true,
    available: availableOptions[0],
    profiles: [],
    bagStatuses: [],
    volume: "",
    donationNumber: "",
    donations: [],
    equipments: [],
  };

  validateSearch = () => {
    let { error, donationNumber, profile } = this.state;

    if (donationNumber === "") {
      error.donationNumber =
        language[this.props.defaultLanguage].donation_number_required;
    }

    if (!profile) {
      error.profile =
        language[this.props.defaultLanguage].component_profile_required;
    }

    this.setState({
      error,
    });
  };

  getDonation = async (searchDonation) => {
    try {
      let { error } = this.state;
      await this.validateSearch();
      if (Object.keys(error).length > 0) return;

      this.setState({
        searchDonation,
      });

      const data = await bloodDonationQuery(this.props.defaultLanguage, {
        donationNumber: this.state.donationNumber?.replace(/\s+/g, ""),
      });

      let { category, donations } = this.state;

      let donation = {};

      if (data.length > 0) {
        donation = data[0];

        if (category === "bulkDonation") {
          donations.push(donation);
          donation = {};
        }
      }

      this.setState(
        {
          donation,
          searchDonation: false,
          donations,
        },
        () => {
          this.getComponentProfiles(true);
        }
      );
    } catch (error) {
      this.setState({ searchDonation: false });
    }
  };

  getEquipments = async (isFetchingEquipment, q) => {
    try {
      this.setState({
        isFetchingEquipment,
      });

      const data = await fetchEquipments(this.props.defaultLanguage, {
        type: "dropdown",
        q,
        department: "separation",
      });

      this.setState({
        isFetchingEquipment: false,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingEquipment: false });
    }
  };

  getComponentProfiles = async (isFetchingProfile, code) => {
    try {
      this.setState({
        isFetchingProfile,
      });

      const data = await fetchComponentsSeparationProfiles(
        this.props.defaultLanguage,
        {
          type: "separation",
          inputType: "dropdown",
          code,
        }
      );

      this.setState({
        isFetchingProfile: false,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingProfile: false });
    }
  };

  onChangeText = async (field, e, isFirstTimeSelectProfile) => {
    let { error } = this.state;
    let inputValue = e.target ? e?.target?.value : e;

    delete error[field];
    delete error["errorMessage"];

    if (isFirstTimeSelectProfile) {
      copyComponents = _.cloneDeep(e?.components || []);
    }

    await this.setState({
      [field]: inputValue,
      error,
    });
  };

  handleChangeComponent = (idex, field, e) => {
    let { profile = {}, error } = this.state;
    let { components = [] } = profile;

    let inputValue = e.target ? e?.target?.value : e;

    if (copyComponents.length === 0 || !copyComponents?.[idex]) {
      toastMessage(
        "error",
        language[this.props.defaultLanguage].unable_copy_component
      );
      return;
    } else if (inputValue < copyComponents[idex].volume) {
      error[`${copyComponents[idex].code}_volume_${idex}`] = `${
        language[this.props.defaultLanguage].minimum_value_must_be
      } ${copyComponents[idex].volume}`;
    } else if (
      copyComponents[idex] &&
      inputValue >= copyComponents[idex].volume &&
      inputValue >= copyComponents[idex].maxVolume
    ) {
      error[`${components[idex].code}_volume_${idex}`] = `${
        language[this.props.defaultLanguage].maximum_value_must_be
      } ${copyComponents[idex].maxVolume}`;
    } else {
      delete error[`${components[idex].code}_volume_${idex}`];
    }

    console.log(error);

    components[idex][field] = inputValue;

    this.setState({ profile, error });
  };

  validateForm() {
    let { profile, error, equipment } = this.state;

    if (!profile) {
      error.profile =
        language[this.props.defaultLanguage].component_profile_required;
    }

    if (!equipment) {
      error.equipment = language[this.props.defaultLanguage].equipment_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      const { donation } = this.state;
      this.setState({
        isSubmitting: true,
      });
      //   if (category === "bulkDonation") {
      //     for (let el of donations) {
      //       await this.handleSeparationData(el);
      //     }
      //   } else {

      //   }

      //   this.setState({
      //     isSubmitting: false,
      //     profile: null,
      //     donationNumber: "",
      //   });

      await this.handleSeparationData(donation);
    }
  };

  handleSeparationData = async (donation) => {
    let { _id } = this.props;
    let { profile, available, equipment } = this.state,
      url = `${API_URL}/bloodSeparation`,
      method = "POST",
      user = await getStorage();

    this.setState({ isSubmitting: true });

    try {
      let requestedBody = {
        donation: donation._id,
        components: profile?.components,
        available: available.value,
        profile: profile._id,
        componentProfile: profile._id,
        addedBy: user.id,
        productType: "ComponentSeparation",
        equipment: equipment.value,
      };

      if (method === "PUT") {
        requestedBody.modifiedBy = user.id;
      } else {
        requestedBody.addedBy = user.id;
      }

      if (_id && _id !== "") {
        method = "PUT";
        requestedBody.id = _id;

        delete requestedBody.addedBy;
      }

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
        data: requestedBody,
      };

      await axios(options);

      this.setState({
        isSubmitting: false,
        donationNumber: "",
        isRefreshing: true,
      });

      toastMessage(
        "success",
        `${
          language[this.props.defaultLanguage][
            method === "PUT" ? "update_data_success" : "add_data_success"
          ]
        }`
      );

      this.setState({ isSubmitting: true });
    } catch (error) {
      this.setState({ isSubmitting: false });
      toastMessage("error", error);
    }
  };

  handleCheckBox(type) {
    this.setState({
      [type]: !this.state[type],
    });
  }

  onReset = () => {
    this.setState({
      donation: {},
      donationNumber: "",
      error: {},
      profile: this.state.profile
        ? {
            ...this.state.profile,
            components: _.cloneDeep(copyComponents), // Deep clone to avoid reference issues
          }
        : null,
    });
  };

  handleCheck(field, value) {
    let { error } = this.state;
    delete error[field];
    this.setState({
      [field]: value,
      error,
    });
  }

  renderDonationTableHeader = () => {
    const headers = [
      {
        title: language[this.props.defaultLanguage].donation_number,
        key: "donationNumber",
      },
      {
        title: "",
        key: "multiple-actions",
        actions: [
          {
            icon: icons.delete,
            className: "btn-danger",
            onPress: (item) => this.handleDeleteDonation(item),
          },
        ],
      },
    ];
    return headers;
  };

  handleDeleteDonation(item) {
    this.setState((prevState) => ({
      donations: prevState.donations.filter(
        (donation) => donation._id !== item._id
      ),
    }));
  }

  handleShowModal(modal) {
    this.setState({
      [modal]: true,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  render() {
    console.log(this.state.error);
    return (
      <div className="card" style={{ marginTop: "1rem", marginBottom: "1rem" }}>
        <div className="card-body">
          <div className="col-md-6">
            <form>
              <div>
                <div className="row">
                  <div className="col-md-6">
                    <Select
                      loadOptions={(v) => this.getComponentProfiles(true, v)}
                      placeholder={language[this.props.defaultLanguage].select}
                      label={
                        language[this.props.defaultLanguage].component_profiles
                      }
                      required
                      value={this.state.profile}
                      onChange={(e) => this.onChangeText("profile", e, true)}
                      error={this.state.error.profile}
                      isLoading={this.state.isFetchingProfile}
                    />
                  </div>
                  <div className="col-md-6">
                    <Select
                      loadOptions={(v) => this.getEquipments(true, v)}
                      placeholder={language[this.props.defaultLanguage].select}
                      label={language[this.props.defaultLanguage].equipment}
                      required
                      value={this.state.equipment}
                      onChange={(e) => this.onChangeText("equipment", e)}
                      error={this.state.error.equipment}
                      isLoading={this.state.isFetchingEquipment}
                    />
                  </div>
                  <div className="col-md-12">
                    <Input
                      placeholder={
                        language[this.props.defaultLanguage].search + "..."
                      }
                      label={
                        language[this.props.defaultLanguage].donation_number
                      }
                      required
                      value={this.state.donationNumber}
                      error={this.state.error.donationNumber}
                      onChange={(e) => this.onChangeText("donationNumber", e)}
                    />
                  </div>
                </div>
                <Button
                  text={language[this.props.defaultLanguage].search}
                  isSubmitting={this.state.searchDonation}
                  onPress={(e) => {
                    e.preventDefault();
                    this.getDonation(true);
                  }}
                />
              </div>
            </form>

            {this.state.donation._id && this.state.donationNumber !== "" && (
              <>
                {this.state?.profile?.components?.map((component, index) => {
                  return (
                    <div key={index} className="row">
                      <div className="col-md-6">
                        <Input
                          label={
                            language[this.props.defaultLanguage].component_name
                          }
                          required
                          value={component.code + " - " + component.name}
                          onChange={(e) =>
                            this.onChangeText(
                              `${component.code}_name_${index}`,
                              e
                            )
                          }
                          error={
                            this.state.error[`${component.code}_name_${index}`]
                          }
                          disabled
                        />
                      </div>
                      <div className="col-md-4">
                        <Input
                          label={language[this.props.defaultLanguage].volume}
                          required
                          value={component.volume}
                          error={
                            this.state.error[
                              `${component.code}_volume_${index}`
                            ]
                          }
                          type="number"
                          onChange={(e) =>
                            this.handleChangeComponent(index, "volume", e)
                          }
                          min={component.volume}
                          max={component.maxVolume}
                        />
                      </div>
                    </div>
                  );
                })}
              </>
            )}
            <form>
              <div className="col-md-12">
                {this.state.donation._id && this.state.donationNumber !== "" ? (
                  <div className="d-flex gap-2">
                    <Button
                      text={language[this.props.defaultLanguage].reset}
                      onPress={(e) => {
                        e.preventDefault();
                        this.onReset();
                      }}
                      className="btn-default"
                      type="button"
                    />
                    <Button
                      text={language[this.props.defaultLanguage].submit}
                      onPress={(e) => {
                        e.preventDefault();
                        this.onSubmit();
                      }}
                      isSubmitting={this.state.isSubmitting}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </form>
          </div>
        </div>

        {this.state?.donation?._id && (
          <div className="card" style={{ marginTop: "1rem" }}>
            <CardHeader
              title={language[this.props.defaultLanguage].component_separation}
            />
            <div className="card-body">
              <Separations
                donationId={this.state?.donation?._id}
                isRefreshing={this.state.isRefreshing}
                type="separation"
                componentProfile={this.state?.componentProfile?.value}
                component={this.state?.component?.value}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewSeparation);
