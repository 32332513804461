import React from "react";
import { connect } from "react-redux";
import { Input } from "../../Input/index.js";
import language from "../../../language/index.js";
import icons from "../../../constants/icons.js";
import { Button } from "../../Button/index.js";
import toastMessage from "../../../utils/toastMessage.js";
import { fetchShippingInQueueData } from "../../../utils/queries/request/shipping-queue.js";
import List from "./List.js";

class SearchShipmentQueue extends React.Component {
  state = {
    error: {},
    data: [],
    donationNumber: "",
  };

  getData = async () => {
    try {
      const { shipmentNumber } = this.state;

      if (!shipmentNumber)
        return toastMessage("error", "shipmentNumber is required");

      this.setState({
        isSearching: true,
      });

      const { data } = await fetchShippingInQueueData(
        this.props.defaultLanguage,
        {
          page: 1,
          limit: 1000,
          shipmentNumber,
        }
      );
      this.setState({
        isSearching: false,
        data,
      });

      if (data.length === 0) {
        toastMessage("error", language[this.props.defaultLanguage].no_data);
      }
    } catch (error) {
      this.setState({ isSearching: false });
      toastMessage("error", error);
    }
  };

  onChangeText(field, e) {
    this.setState({
      [field]: e.target.value,
    });
  }

  onReset() {
    this.setState({
      shipmentNumber: "",
      data: [],
    });
  }

  render() {
    return (
      <div className="card mb-3">
        <div className="card-body">
          <form>
            <Input
              placeholder={
                language[this.props.defaultLanguage].shipment_number_placeholder
              }
              label={language[this.props.defaultLanguage].shipment_number}
              value={this.state.shipmentNumber}
              onChange={(e) => this.onChangeText("shipmentNumber", e)}
              error={this.state.error.shipmentNumber}
              leftIcon={icons.user}
            />
            <div className="d-flex gap-2">
              {this.state.data.length > 0 && (
                <Button
                  text={language[this.props.defaultLanguage].reset}
                  onPress={(e) => {
                    e.preventDefault();
                    this.onReset();
                  }}
                  type="button"
                  className="btn-default"
                />
              )}
              <Button
                text={language[this.props.defaultLanguage].search}
                isSubmitting={this.state.isSearching}
                onPress={(e) => {
                  e.preventDefault();
                  this.getData();
                }}
              />
            </div>
          </form>
        </div>

        {this.state.data.length > 0 && (
          <div className="card">
            <div className="card-body">
              <List
                data={this.state.data}
                page={this.state.page}
                limit={this.state.limit}
                search_text={this.state.search_text}
                showAdd
                addButtonText={language[this.props.defaultLanguage].create}
                handleAddPressed={() => this.handleShowModal("showPrint")}
                totalPageCount={this.state.totalPageCount}
                isLoading={this.state.isSearching}
                getData={this.getData.bind(this, true)}
                selectedData={
                  this.state.data?.length > 0 ? this.state.data[0] : {}
                }
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(SearchShipmentQueue);
