import React from "react";
import { connect } from "react-redux";
import language from "../../../language";
import CardCount from "../../CardCount/CardCount";

class CountDonors extends React.Component {
  render() {
    if (!this.props.counts) return;
    return (
      <div className="row mb-3">
        <div className="col-md-3">
          <CardCount
            count={this.props.counts.totalDonors}
            title={language[this.props.defaultLanguage].total_donors}
            color="orange"
            icon="groups"
            isLoading={this.props.isLoadingCounts}
            // onPress={() => (window.location.href = "/dashboard/donors")}
          />
        </div>
        <div className="col-md-3">
          <CardCount
            count={this.props.counts.newDonor}
            title={language[this.props.defaultLanguage].new_donors}
            color="#078ECE"
            icon="group"
            isLoading={this.props.isLoadingCounts}
            // onPress={() => (window.location.href = "/dashboard/newDonor")}
          />
        </div>
        <div className="col-md-3">
          <CardCount
            count={this.props.counts.repeatDonor}
            title={language[this.props.defaultLanguage].repeat_donors}
            color="gray"
            icon="undo"
            isLoading={this.props.isLoadingCounts}
            // onPress={() => (window.location.href = "/dashboard/repeat_donors")}
          />
        </div>
        <div className="col-md-3">
          <CardCount
            count={this.props.counts.noDocument}
            title={language[this.props.defaultLanguage].donors_with_no_document}
            color="#800080"
            icon="label_off"
            isLoading={this.props.isLoadingCounts}
            // onPress={() => (window.location.href = "/dashboard/noDocument")}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(CountDonors);
