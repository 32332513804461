import React from "react";
import "./styles.css";
import { Link } from "react-router-dom";
import { Loading } from "../Loader";
import { Button } from "../Button";
import icons from "../../constants/icons";

class Input extends React.Component {
  state = {
    showPassword: false,
  };

  handleShowPassword() {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }

  render() {
    const inputProps = {
      type:
        this.props.type === "password"
          ? !this.state.showPassword
            ? "password"
            : "text"
          : this.props.type,
      value: this.props.value,
      onChange: this.props.onChange,
      placeholder: this.props.placeholder,
      disabled: this.props.disabled,
      className: `form-control ${this.props.className}`,
      maxLength: this.props.maxLength,
      minLength: this.props.minLength,
      onInput: (e) => {
        e.target.value = e?.target?.value.slice(0, this.props.maxLength);

        if (!this.props.textarea) return;
        e.target.style.height = "auto";
        e.target.style.height = e.target.scrollHeight + "px";
      },
      multiline: this.props.multiline,
      onFocus: this.props.onFocus,
      min: this.props.min || 1,
      max: this.props.max,
      step: this.props.step,
      ref: this.props.inputRef,
    };
    return (
      <div
        className={`input-container ${this.props.className}
        ${this.props.disabled && "disabled-input"} ${
          this.props.error && "errored-input"
        } ${this.props.success && "success-input"}`}
        style={this.props.styles}
      >
        {this.props.label && (
          <label className="form-label">
            {this.props.label}{" "}
            {this.props.required && <span className="error-text">*</span>}
          </label>
        )}

        <div className={`input-content ${this.props.inputClassName}`}>
          {this.props.leftIcon && (
            <div className="left-container">
              <span className={process.env.REACT_APP_ICONS_TYPE}>
                {this.props.leftIcon}
              </span>
            </div>
          )}

          <div className="input-input">
            {this.props.textarea ? (
              <textarea {...inputProps} className="form-control" />
            ) : (
              <input {...inputProps} style={this.props.inputStyles} />
            )}
          </div>
          {this.props.rightText && this.props.rightText !== "" && (
            <div className="right-container">
              <Link className="text-bold" to="#">
                {this.props.rightText}
              </Link>
            </div>
          )}
          {this.props.rightIcon && (
            <div className="right-container">
              <span className={process.env.REACT_APP_ICONS_TYPE}>
                {this.props.rightIcon}
              </span>
            </div>
          )}

          {Object.keys(this?.props?.profileData || {})?.length > 0 && (
            <div className="right-container">
              <span className="material-symbols-outlined icon-primary-color">
                done
              </span>
            </div>
          )}
          {this.props.type === "password" && (
            <div
              className="right-container"
              onClick={this.handleShowPassword.bind(this)}
            >
              <span className={process.env.REACT_APP_ICONS_TYPE}>
                {this.state.showPassword ? icons.eye_on : icons.eye_off}
              </span>
            </div>
          )}
          {this.props.rightButtonIcon && (
            <Button
              // className="btn-xsm btn-radius-50"
              icon={
                !this.props.rightButtonSubmitting
                  ? this.props.rightButtonIcon
                  : null
              }
              isSubmitting={this.props.rightButtonSubmitting}
              onPress={this.props.rightButtonPressed}
            />
          )}
          {this.props.rightButtonText && (
            <Button
              className={`btn-xsm ${this.props.rightButtonClassName}`}
              text={this.props.rightButtonText}
              isSubmitting={this.props.rightButtonSubmitting}
              onPress={this.props.rightButtonPressed}
            />
          )}
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {this.props.isLoading && <Loading className="primary" />}
          <div>
            {" "}
            {this.props.helperText && (
              <div className="helper-section">
                <p className="helper-text text-disabled">
                  {this.props.helperText}
                </p>
              </div>
            )}
            {this.props.error && (
              <div className="helper-section">
                <p className="error-text">{this.props.error}</p>
              </div>
            )}
            {this.props.success && (
              <div className="helper-section">
                <p className="success-text">{this.props.success}</p>
              </div>
            )}
            {Object.keys(this.props.profileData || {}).length > 0 && (
              <div className="input-profile-data">
                {Object.keys(this.props.profileData).map((key, i) => {
                  if (key === "id" || key === "_id") return <></>;
                  return (
                    <div key={i}>
                      {this.props?.profileData[key] && (
                        <span>
                          <span style={{ textTransform: "capitalize" }}>
                            {key}
                          </span>
                          : <b>{this.props?.profileData[key]}</b>
                        </span>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
            {this.props.helperLink && (
              <div
                className="helper-link"
                onClick={this.props.onPressHelperLink}
              >
                <Link to="#">{this.props.helperLink}</Link>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Input;
