import React from "react";
import { connect } from "react-redux";
import { Tabs } from "../../components/Tabs";
import { Equipments } from "../../components/Equipments";
import language from "../../language";
import CollectionTypes from "../../components/CollectionType/CollectionTypes";
import AccessRoles from "../../components/AccessRole/AccessRoles";
import { Measures } from "../../components/VitalSignMeasures";
import BloodTestQuestionnaire from "../../components/BloodTesting/Questionnaire/BloodTestQuestionnaire";
import { Components } from "../../components/Components";
import { ComponentSeparationProfiles } from "../../components/ComponentProfiles/Separation";
import { ComponentTransformationProfiles } from "../../components/ComponentProfiles/Transformation";
import { TransportationTemperatures } from "../../components/TransportationTemperature";
import { DashboardHeader } from "../../components/Header";
import { BagStatus } from "../../components/BagStatus";
import accessMenu from "../../utils/accessMenu";
import RejectionStatus from "../../components/RejectionStatus/RejectionStatus";
class ControlPanelScreen extends React.Component {
  render() {
    const {
      canPanelCreateAccessRoles,
      canPanelCreateBloodTestQuestionnaire,
      canPanelCreateCollectionType,
      canPanelCreateEquipment,
      canPanelGeneralAccess,
      canPanelCreateComponentProfile,
      canPanelCreateMeasures,
    } = accessMenu();

    let tabOptions = [];

    if (canPanelCreateEquipment) {
      tabOptions.push({
        title: language[this.props.defaultLanguage].equipments,
        data: <Equipments />,
      });
    }

    if (canPanelCreateCollectionType) {
      tabOptions.push({
        title: language[this.props.defaultLanguage].collection_type,
        data: <CollectionTypes />,
      });
    }

    if (canPanelCreateMeasures) {
      tabOptions.push({
        title: language[this.props.defaultLanguage].vital_sign_measurements,
        data: <Measures />,
      });
    }

    if (canPanelCreateAccessRoles) {
      tabOptions.push({
        title: language[this.props.defaultLanguage].access_roles,
        data: <AccessRoles />,
      });
    }

    if (canPanelCreateBloodTestQuestionnaire) {
      tabOptions.push({
        title: language[this.props.defaultLanguage].blood_test_questionnaire,
        data: <BloodTestQuestionnaire />,
      });
    }

    if (canPanelCreateComponentProfile) {
      tabOptions.push({
        title: language[this.props.defaultLanguage].components_and_profiles,
        data: (
          <>
            <DashboardHeader
              title={
                language[this.props.defaultLanguage].components_and_profiles
              }
            />
            <Tabs
              options={[
                {
                  title: language[this.props.defaultLanguage].components,
                  data: <Components />,
                },
                {
                  title: language[this.props.defaultLanguage].profiles,
                  data: (
                    <>
                      <DashboardHeader
                        title={language[this.props.defaultLanguage].profiles}
                      />
                      <Tabs
                        options={[
                          {
                            title:
                              language[this.props.defaultLanguage]
                                .component_separation_profiles,
                            data: <ComponentSeparationProfiles />,
                          },
                          {
                            title:
                              language[this.props.defaultLanguage]
                                .component_transformation_profiles,
                            data: <ComponentTransformationProfiles />,
                          },
                        ]}
                      />
                    </>
                  ),
                },
                {
                  title:
                    language[this.props.defaultLanguage]
                      .transportation_temperature,
                  data: <TransportationTemperatures />,
                },
              ]}
            />
          </>
        ),
      });
    }

    if (canPanelGeneralAccess) {
      tabOptions.push({
        title: language[this.props.defaultLanguage].general,
        data: (
          <>
            <DashboardHeader
              title={language[this.props.defaultLanguage].general}
            />
            <Tabs
              options={[
                {
                  title: language[this.props.defaultLanguage].bag_status,
                  data: <BagStatus />,
                },
                {
                  title: language[this.props.defaultLanguage].reason_rejection,
                  data: <RejectionStatus />,
                },
              ]}
            />
          </>
        ),
      });
    }

    return (
      <div>
        <div className="card-dashboard-content">
          <div className="card-body">
            <DashboardHeader
              title={language[this.props.defaultLanguage].control_panel}
            />
            <Tabs options={tabOptions} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(ControlPanelScreen);
