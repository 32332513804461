import toastMessage from "../../toastMessage";
import { getStorage } from "../../storage";
import api from "../../api";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

export const fetchDonationWithoutDonor = async (lng = "english", params) => {
  try {
    const user = await getStorage();

    const options = {
      method: "GET",
      url: `${API_URL}/bloodDonation/no-donor`,
      params,
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await api(options);

    return queryRes.data;
  } catch (error) {
    toastMessage("error", error);
  }
};
