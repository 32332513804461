import React from "react";
import { connect } from "react-redux";
import language from "../../../../language/index.js";
import requestQuery from "../../../../utils/queries/requestQuery.js";
import icons from "../../../../constants/icons.js";
import { Modal } from "../../../Modal/index.js";
import AddDistributor from "./Add.js";
import { ROLE_REQUEST } from "../../../../constants/ROLES.js";
import { defineRole } from "../../../../utils/handleAccessRoles.js";
import { getStorage } from "../../../../utils/storage.js";
import Table from "../../../Table/Table.js";

class RequestInfo extends React.Component {
  state = {
    error: {},
    distributors: [],
    data: [],
    csvData: [],
    user: {},
    status: "",
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });

    this.getRequestInfo(true);
  };

  returnFilters() {
    const { requestId } = this.props;
    return {
      id: requestId,
    };
  }

  getRequestInfo = async (isLoading) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const { data } = await requestQuery(
        defaultLanguage,
        this.returnFilters()
      );

      let status = "";
      if (data.length > 0) {
        status = data[0].status;
      }

      this.setState({
        data,
        isLoading: false,
        status,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleShowModal(modal, modalTitle, selected_data = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selected_data,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].distributor,
        key: "distributor.name",
      },
      {
        title: language[this.props.defaultLanguage].status,
        key: "status",
        type: "status",
      },
      {
        title: language[this.props.defaultLanguage].actions,
        key: "action",
      },
    ];

    return headers;
  }

  render() {
    let actions = [];

    if (
      this.state.status !== "delivered" ||
      this.state.status !== "cancelled"
    ) {
      actions.push({
        canAccess: defineRole({
          roles: this.state.user.accessRole,
          menu: ROLE_REQUEST,
          operation: "update",
        }),
        name: language[this.props.defaultLanguage].update,
        onPress: (item) =>
          this.handleShowModal(
            "showDistributorFormModal",
            language[this.props.defaultLanguage].distributor,
            item
          ),
        icon: icons.edit,
      });
    }

    let showAdd = false;

    this.state.data.map((el) => {
      if (
        el.status !== "delivered" ||
        (el.status !== "cancelled" && !el?.distributor?._id)
      ) {
        showAdd = true;
      }
    });
    return (
      <div>
        <Table
          data={this.state.data}
          isLoading={this.state.isLoading}
          headers={this.returnTableHeaders()}
          showAdd={showAdd}
          addButtonText={language[this.props.defaultLanguage].add}
          handleAddPressed={() =>
            this.handleShowModal(
              "showDistributorFormModal",
              language[this.props.defaultLanguage].distributor
            )
          }
          actions={actions}
        />

        <Modal
          handleClose={this.handleCloseModal.bind(
            this,
            "showDistributorFormModal"
          )}
          show={this.state.showDistributorFormModal}
          title={this.state.modalTitle}
          showHeaderBottomBorder={false}
          size="lg"
        >
          <AddDistributor
            requestId={this.props.requestId}
            handleRefresh={this.props.handleRefresh}
            getRequestInfo={() => this.getRequestInfo(true)}
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showDistributorFormModal"
            )}
            bloodBank={this.props.bloodBank}
            requestNo={this.props.requestNo}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(RequestInfo);
