import React, { createRef } from "react";
import { connect } from "react-redux";
import language from "../../../../language";
import { Loading } from "../../../Loader";
import { Empty } from "../../../Empty";
import { fetchInventories } from "../../../../utils/queries/inventories/center";
import toastMessage from "../../../../utils/toastMessage";
import moment from "moment-timezone";
import { PrintComponent } from "../../../Print";
import Barcode from "react-barcode";
import { Checkbox } from "../../../Input";
import { Button } from "../../../Button";
import axios from "axios";
import { getStorage } from "../../../../utils/storage";
import BarcodeScannerInput from "../../../Barcode/BarcodeScannerInput";
import rbcLogo from "../../../../assets/rbc-logo.png";
import "./styles.css";
import DescriptionComponent from "../../ComponentSeparation/DescriptionComponent";
import { fetchBloodTestHistory } from "../../../../utils/queries/bloodTestHistory";
import debounce from "lodash.debounce";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;
let countScan = 0;

class PrintableLabel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      data: [],
      donation: {},
      isVerifying: false,
      isFocused: false,
      countScan: 0,
    };

    this.scannerInputRef = createRef();
    this.handleScan = debounce(this.handleScan.bind(this), 1000);
  }

  componentDidMount() {
    this.getData(true);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.productId !== this.props.productId) {
      this.getData(true);
    }
  }

  getData = async (isLoading) => {
    try {
      this.setState({ isLoading });

      if (!this.props.productId)
        return toastMessage("error", "Product ID is missing");

      const data = await fetchInventories(this.props.defaultLanguage, {
        id: this.props.productId,
      });

      this.setState({ data, isLoading: false });

      if (data.isLabeled) {
        this.props.handleCloseModal();
      }
    } catch (error) {
      this.setState({ isLoading: false });

      toastMessage(error);
    }
  };

  renderInfo = ({
    title,
    description,
    barCode,
    isBlocked,
    type,
    displayValue = true,
    reason,
    isTestResult,
    showValue,
  }) => {
    return (
      <div className="d-flex align-items-center">
        <div
          style={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            position: "relative",
          }}
        >
          <span
            style={{
              fontSize: type === "checkComponent" ? 18 : 14,
              marginRight: 8,
              fontWeight: type === "checkComponent" ? "900" : "300",
            }}
          >
            {title}
          </span>
          {isBlocked && <span className="text-danger">(Blocked)</span>}
          {type === "checkBloodGroup" && (
            <div style={{ position: "relative" }}>
              <div
                style={{
                  fontSize: "4rem",
                  marginLeft: ".5rem",
                  fontWeight: 600,
                  marginRight: 8,
                  left: 0,
                  // position: "absolute",
                  // top: -50,
                }}
              >
                <span className={!isTestResult ? "noPrint" : ""}>
                  <div
                    style={{
                      border: barCode?.includes("+")
                        ? "1px solid black"
                        : "none",
                      backgroundColor: barCode?.includes("-")
                        ? "black"
                        : "white",
                      color: barCode?.includes("-") ? "white" : "black",
                      padding: ".3rem",
                    }}
                  >
                    {barCode}
                  </div>
                </span>
              </div>
              {reason && <span className="text-danger"> {`(${reason})`}</span>}
            </div>
          )}
        </div>
        {description && (
          <div>
            <span style={{ fontSize: 14 }} className="text-bold">
              {description
                .replaceAll(":Positive", "")
                .replaceAll(":Negative", "")
                .replaceAll(":Du+", "")
                .replaceAll(":d-", "")
                .replaceAll(" - Notification status", "")}
            </span>
          </div>
        )}
        {barCode && (
          <div>
            <Barcode
              displayValue={false}
              // value={barCode}
              value={`${barCode}`}
              width={1.5}
              height={20}
              options={{ width: 0.7 }}
            />
            {barCode && showValue && (
              <center>
                <h3>{barCode}</h3>
              </center>
            )}
          </div>
        )}
      </div>
    );
  };

  handleCheck(field) {
    this.setState({
      [field]: !this.state[field],
    });
  }

  handleUpdate = async () => {
    try {
      this.setState({ isUpdating: true });

      await this.updateProductInfo();

      this.getData(true);

      this.setState({ isUpdating: false });
    } catch (error) {
      this.setState({ isUpdating: false });

      toastMessage("error", error);
    }
  };

  updateProductInfo = async () => {
    const { data } = this.state;

    if (data.length === 0)
      return toastMessage(
        "error",
        language[this.props.defaultLanguage].no_data
      );

    this.setState({
      isUpdating: true,
    });

    const { donation, component, _id, center, centerSite } = data[0];
    const user = await getStorage();

    let requestedBody = {
      id: _id,
      donation: donation._id,
      addedBy: user.id,
      component: component?._id,
      name: component.name,
      code: component.code,
      // volume: component.volume,
      center: center?._id,
      centerSite: centerSite?._id,
      status: "pending",
      bloodGroup: donation.bloodGroup,
    };

    const options = {
      method: "PUT",
      url: `${process.env.REACT_APP_BBMS_BASE_API}/inventory`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.token,
      },
      data: requestedBody,
    };

    await axios(options);
  };

  handleScan = async (value) => {
    try {
      console.log("====================================");
      console.log(value);
      console.log("====================================");

      // Check if a scan is already in progress
      if (this.state.isScanning) return;

      // Clear the input value immediately to prepare for new scans
      this.setState({ isScanning: true });

      // Validation checks
      if (!value) {
        this.setState({ isScanning: false });
        return toastMessage(
          "error",
          language[this.props.defaultLanguage].invalid_barcode
        );
      }

      // Set scanned value and prepare key for further processing
      this.setState({ scannedValue: value });

      // let key =
      //   this.state.countScan === 0
      //     ? "checkComponent"
      //     : this.state.countScan === 2
      //     ? "checkDonation"
      //     : this.state.countScan === 4
      //     ? "checkBloodGroup"
      //     : "";

      // if (key === "") {
      //   this.setState({ isScanning: false });
      //   return toastMessage(
      //     "error",
      //     language[this.props.defaultLanguage].wrong_product
      //   );
      // }

      const { data } = this.state;

      if (data.length === 0) {
        this.setState({ isScanning: false });
        return toastMessage("error", "MISSING LABEL");
      }

      this.setState({ isVerifying: true });

      const user = await getStorage();

      const requestedBody = {
        value: typeof value === "string" ? value?.toUpperCase() : value,
        id: data[0]._id,
        verifiedBy: user.id,
      };

      const options = {
        method: "POST",
        url: `${API_URL}/inventory/verify-label`,
        data: requestedBody,
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };

      await axios(options);

      // Fetch data after scan
      await this.getData(false);
    } catch (error) {
      console.log(error);
      toastMessage("error", error);
    } finally {
      this.setState((prevState) => ({
        countScan: prevState.countScan + 1,
        scannedValue: "", // Clear scanned value
        value: "", // Clear input value for the next scan
      }));

      // Reset scanning state and refocus input

      this.setState((prevState) => ({
        countScan: prevState.countScan + 1,
        isScanning: false,
        isVerifying: false,
        scannedValue: "",
      }));
      this.scannerInputRef.current.focus(); // Refocus the input element
    }
  };

  // handlePrint = async (verifyEnabled) => {
  //   try {
  //     if (!this.state.data?.length === 0)
  //       return toastMessage(
  //         "error",
  //         language[this.props.defaultLanguage].no_data
  //       );

  //     if (!this.state.data[0].isTestResult || this.state.data[0].isLabeled)
  //       return;

  //     const user = await getStorage();

  //     const isCheck =
  //       this.state.data[0].checkBloodGroup &&
  //       this.state.data[0].checkComponent &&
  //       this.state.data[0].checkDonation;

  //     if (!isCheck) return;

  //     this.setState({ isPrinting: true });

  //     const options = {
  //       url: `${API_URL}/inventory`,
  //       method: "PUT",
  //       data: {
  //         // status:
  //         //   this.state.data[0].checkBloodGroup &&
  //         //   this.state.data[0].checkComponent &&
  //         //   this.state.data[0].checkDonation
  //         //     ? "labelled"
  //         //     : undefined,
  //         id: this.state.data[0]._id,
  //         // isLabeled: true,
  //         // labelledBy: user.id,
  //         // verifiedBy: verifyEnabled ? user.id : undefined,
  //       },
  //       headers: {
  //         Authorization: `Bearer ${user.token}`,
  //       },
  //     };

  //     await axios(options);

  //     this.setState({ isPrinting: false });

  //     this.getData(true);
  //   } catch (error) {
  //     this.setState({ isPrinting: false });
  //     console.log(error);

  //     toastMessage("error", error);
  //   }
  // };

  renderLabelContent = () => {
    const isReactive = this.checkIsReactive();

    return (
      <div style={{ paddingRight: ".5rem" }}>
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={rbcLogo}
            style={{
              aspectRatio: 2 / 2,
              objectFit: "contain",
              width: "30%",
              opacity: 0.4,
              marginTop: "-10rem",
              // transform: "rotate(-30deg)",
              marginRight: "4rem",
            }}
          />
        </div>
        <div style={{ zIndex: 100 }}>
          {this.state.data.map((el, i) => {
            return (
              <div
                key={i}
                style={{
                  height: "106.8mm",
                }}
              >
                <div
                  style={{
                    // paddingTop: "1rem",
                    display: "flex",
                    // alignItems: "flex-start",
                    paddingRight: "1.8rem",
                  }}
                >
                  <div style={{ flex: 1 }} />
                  <div>
                    <span className="text-bold">BTD RWANDA KIGALI</span>
                    {/* {el?.center && (
                      <>
                        <br />
                        <span className="text-bold">{el?.center?.name}</span>
                      </>
                    )} */}
                  </div>
                </div>
                <div className="card-body">
                  {this.renderInfo({
                    title: el.name,
                    barCode: el.code,
                    type: "checkComponent",
                    displayValue: false,
                    showValue: true,
                  })}
                  {this.renderInfo({
                    title: "Volume",
                    description: el.volume + " ML",
                  })}
                  {this.renderInfo({
                    title: "Phlebotomy Date",
                    description:
                      moment(el?.donation?.collectionDate).format(
                        "DD-MM-YYYY"
                      ) +
                      " " +
                      el?.donation?.endTime,
                  })}
                  {this.renderInfo({
                    title: "Expiry Date",
                    description: el.expiredDate,
                  })}
                  {this.renderInfo({
                    title: "Donation Number",
                    barCode: el.donation?.donationNumber,
                    isBlocked: !el?.donation?.isActive,
                    type: "checkDonation",
                    displayValue: false,
                    showValue: true,
                  })}
                  {this.renderInfo({
                    title: "Blood Group",
                    barCode:
                      !el?.donation?.isActive ||
                      el?.donation?.blockInfo?.blocked
                        ? "N/A"
                        : el.bloodGroup === "unspecified"
                        ? ""
                        : el.bloodGroup,
                    type: "checkBloodGroup",
                    displayValue: false,
                    reason: !isReactive
                      ? el?.donation?.blockInfo?.blockReason
                      : "",
                    isTestResult: el.isTestResult,
                  })}
                  {this.renderInfo({
                    title: "Storage Temperature",
                    description: el?.component?.storageTemperature?.name,
                  })}
                  {this.renderInfo({
                    title: "",
                    description: el.serologyResult,
                  })}
                  {this.renderInfo({
                    title: "",
                    description: el.bloodGroupingResult,
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  checkIsReactive() {
    const isReactive = this.state?.data?.some((el) => {
      // Split by colon to separate test name and result
      const results = el.serologyResult?.split(","); // Split by comma for multiple tests

      return results?.some((res) => {
        const [test, result] = res.split(":").map((str) => str.trim()); // Split test and result, trim whitespace
        return (
          result?.toUpperCase() === "REACTIVE" ||
          result?.toUpperCase() === "GRAY ZONE"
        );
      });
    });

    return isReactive;
  }

  renderPrintComponent() {
    const isReactive = this.checkIsReactive();
    const isBlockMainInventory = this.state.data?.[0]?.blockInfo?.blockReason;
    return (
      <>
        {this.state.data?.length > 0 && this.state.data[0].isLabeled && (
          <>
            <center className="noPrint card-body">
              <div
                className="badge badge-success badge-lg"
                style={{
                  fontSize: 14,
                  display: "flex",
                  alignItems: "center",
                  height: 20,
                  justifyContent: "center",
                }}
              >
                Labelled
              </div>
            </center>
          </>
        )}
        <div style={{ position: "relative", overflow: "hidden" }}>
          {this.renderLabelContent(isReactive)}
          {isReactive ? (
            <div className="reactive-to-test">
              {Array.from({ length: 10 }).map((_, index) => (
                <span key={index}>REACTIVE</span>
              ))}
            </div>
          ) : (
            isBlockMainInventory && (
              <div className="reactive-to-test">
                {Array.from({ length: 10 }).map((_, index) => (
                  <span key={index}>{isBlockMainInventory}</span>
                ))}
              </div>
            )
          )}
        </div>
      </>
    );
  }

  render() {
    if (this.state.isLoading) {
      return (
        <center>
          <Loading className="fullscreen" />
        </center>
      );
    }

    if (this.state.data.length === 0) {
      return <Empty title={language[this.props.defaultLanguage].no_data} />;
    }

    return (
      <div style={{ paddingTop: "1rem", position: "relative" }}>
        {this.state.isVerifying && (
          <div className="verifying-label">
            <Loading />
            <center style={{ marginLeft: ".5rem" }}>
              <span>Verifying...</span>
            </center>
          </div>
        )}
        {this.state.data.length > 0 &&
        this.state.data[0].status === "transformed" ? (
          <>{this.renderPrintComponent()}</>
        ) : (
          <PrintComponent
            // handlePrint={this.handlePrint.bind(this)}
            // isPrinting={this.state.isPrinting}
            buttonText={
              this.state.data?.length > 0 && this.state.data[0].isLabeled
                ? "Reprint Label"
                : "Print Label"
            }
          >
            {this.renderPrintComponent()}
          </PrintComponent>
        )}

        {this.state.data.map((el, i) => {
          return (
            <div key={i}>
              <div className="card-body">
                {[
                  {
                    name: language[this.props.defaultLanguage].check_component,
                    type: "checkComponent",
                  },
                  {
                    name: language[this.props.defaultLanguage]
                      .check_blood_group,
                    type: "checkBloodGroup",
                  },
                  {
                    name: language[this.props.defaultLanguage]
                      .check_donation_number,
                    type: "checkDonation",
                  },
                ].map((checkEl, i) => {
                  return (
                    <Checkbox
                      key={i}
                      label={checkEl.name}
                      // onCheck={() => this.handleCheck(checkEl.type)}
                      checked={el[checkEl.type]}
                    />
                  );
                })}
              </div>
              <div className="modal-footer">
                <Button
                  text={language[this.props.defaultLanguage].cancel}
                  onPress={this.props.handleCloseModal}
                  className="btn-default"
                />
                <Button
                  text={language[this.props.defaultLanguage].update}
                  isSubmitting={this.state.isUpdating}
                  onPress={this.handleUpdate.bind(this, el)}
                />
              </div>
            </div>
          );
        })}
        <input
          onChange={(e) => this.setState({ scannedValue: e.target.value })}
          autoFocus
          value={this.state.scannedValue} // Bind value to state
          // style={{ position: "absolute", left: "-9999px" }}
          ref={this.scannerInputRef}
          // onKeyPress={(e) => this.handleScan(e.target.value)}
          onKeyDown={(e) => {
            console.log("Key pressed", e.key);
            if (e.key === "Enter") {
              this.handleScan(e.target.value);
            }
          }} // Attach the ref here
          style={{ textTransform: "uppercase" }}
        />
        <BarcodeScannerInput
          onScan={(txt) => this.handleScan(txt)}
          // targetInputRef={this.scannerInputRef.current}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(PrintableLabel);
