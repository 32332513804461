import React from "react";
import { Button, DropdownButton } from "../Button";

import bloodCenterQuery from "../../utils/queries/bloodCenterQuery";
import { Loading } from "../Loader";
import icons from "../../constants/icons";
import { CardHeader } from "../Header";
import { connect } from "react-redux";
import language from "../../language";
import { Checkbox } from "../Input";
import { fetchBloodBanks } from "../../utils/queries/bloodBank";

let copyData = [];

class DestinationInventoryFilters extends React.Component {
  state = {
    isLoading: true,
    data: [],
    page: 1,
    limit: 50,
    to: [],
  };

  componentDidMount = async () => {
    await this.getData(true);
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.categoryType !== this.props.categoryType) {
      await this.getData(true);
    }
  };

  getData = async (isLoading) => {
    try {
      const { defaultLanguage } = this.props;
      const { page, limit } = this.state;

      this.setState({ isLoading });

      const data = await fetchBloodBanks(defaultLanguage, {
        page,
        limit,
        type: "dropdown",
      });

      this.setState({
        data: data,
        isLoading: false,
      });

      copyData = data.slice(0);
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  onCheck = async (el) => {
    let { to = [] } = this.state;

    const index = to.findIndex((sEl) => sEl.value === el._id);

    if (index === -1) {
      to.push({
        value: el._id,
        label: el.name,
      });
    } else {
      to.splice(index, 1);
    }
    this.setState({ to });

    this.props.handleApplyFilters &&
      this.props.handleApplyFilters(true, { to });
  };

  handleSearch(e) {
    const searchValue = e?.target?.value;
    this.setState({ searchValue });

    let results = [];
    copyData.forEach((el) => {
      if (
        JSON.stringify(el).toLowerCase().indexOf(searchValue.toLowerCase()) !==
        -1
      ) {
        results.push(el);
      }
    });

    this.setState({ data: results });
  }

  onReset() {
    this.setState({ to: [] });
    this.props.handleApplyFilters &&
      this.props.handleApplyFilters(true, { to: [] });
  }

  render() {
    return (
      <DropdownButton
        leftIcon={icons.center}
        text={language[this.props.defaultLanguage].filter_destination_inventory}
        className="btn-transparent btn-bordered w-sm-100 btn-sm"
        selectedData={this.state?.to}
        clickOutside
      >
        <CardHeader
          title={
            language[this.props.defaultLanguage].filter_destination_inventory
          }
          borderBottom
          closeButton
          isSearch
        />
        <Button
          text={language[this.props.defaultLanguage].reset}
          onPress={this.onReset.bind(this)}
        />
        <div className="dropdown-content">
          {this.state.isLoading ? (
            <center>
              <Loading />
            </center>
          ) : (
            this.state?.data?.map((el, i) => {
              return (
                <Checkbox
                  label={el.name}
                  key={i}
                  onCheck={() => this.onCheck(el)}
                  checked={this.state?.to?.find((sEl) => sEl._id === el._id)}
                />
              );
            })
          )}
        </div>
      </DropdownButton>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(DestinationInventoryFilters);
