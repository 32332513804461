import toastMessage from "../toastMessage";
import { getStorage } from "../storage";
import axios from "axios";
import language from "../../language";
import { defineRoleCenterSite } from "../handleAccessRoles";
import formatSelectData from "../formatSelectData";
import api from "../api";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

const fetchData = async (lng = "english", params = {}) => {
  let { page, limit, active = undefined, type, dataTypes, search, q } = params;
  try {
    const user = await getStorage();
    const { centerRole, centerSiteRole, hospitalRole } =
      await defineRoleCenterSite();

    const options = {
      method: "GET",
      url: `${API_URL}/account`,
      params: {
        page: page,
        limit: limit,
        active,
        center: centerRole,
        centerSite: centerSiteRole,
        hospital: hospitalRole,
        search: search !== "" ? search : undefined,
        q,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await api(options);

    let { data, count } = queryRes.data;

    if (!data) {
      data = queryRes.data;
    }

    let responseData = [];

    data.forEach((el) => {
      if (
        dataTypes &&
        dataTypes instanceof Array &&
        dataTypes.includes("institutions")
      ) {
        if (!responseData.find((fEl) => fEl.value !== el._id)) {
          responseData.push({
            _id: el._id,
            name: el.institution,
            label: el.username + " - " + el.name,
            value: el._id,
          });
        }
      } else {
        responseData.push({
          ...el,
          label: el.username + " - " + el.name,
          value: el._id,
          department:
            el.department.length === 1
              ? el.department[0]
              : {
                  name: el.department.length + " " + language[lng].departments,
                },
          position:
            el.position.length === 1
              ? el.position[0]
              : { name: el.position.length + " " + language[lng].positions },
        });
      }
    });

    if (type === "dropdown") {
      return responseData;
    }

    return {
      data: responseData,
      count,
    };
  } catch (error) {
    toastMessage("error", language[lng].unable_fetch_accounts);
  }
};

export default fetchData;

export const deleteAccount = async (lng = "english", id) => {
  try {
    const user = await getStorage();

    const options = {
      method: "DELETE",
      url: `${API_URL}/account`,
      data: {
        id,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    await api(options);

    toastMessage("success", language[lng].success_delete_account);
  } catch (error) {
    toastMessage("error", language[lng].error_delete_account);
  }
};
