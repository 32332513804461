import React from "react";
import { connect } from "react-redux";
import language from "../../../language";
import { Table } from "../../Table";
import { Modal, DeleteModal } from "../../Modal";
import { CSVLink } from "react-csv";
import handleSearch from "../../../utils/handleSearch";
import { defineRole } from "../../../utils/handleAccessRoles";
import { getStorage } from "../../../utils/storage";
import { menus } from "../../../constants/accessRolesList";
import { ROLE_MESSAGE_TEMPLATE } from "../../../constants/ROLES";
import icons from "../../../constants/icons";
import {
  deleteMessageTemplate,
  fetchMessageTemplateData,
} from "../../../utils/queries/messageTemplate/messageTemplateQuery";
import NewMessageTemplate from "./NewMessageTemplate";

let copyData = [];

class ListMessage extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selectedData: {},
    totalPageCount: 10,
    delete_password: "",
    error: {},
    csvData: [],
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });

    await this.getData(true);
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({ error, [field]: e?.target?.value });
  }

  getData = async (isLoading) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage, isActive } = this.props;

      this.setState({ isLoading });

      const { data, count } = await fetchMessageTemplateData(defaultLanguage, {
        page,
        limit,
      });

      this.setState({
        data,
        totalPageCount: count,
        isLoading: false,
      });

      copyData = data.slice(0);
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleShowModal(modal, modalTitle, selectedData = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selectedData,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].name,
        key: "name",
      },
      {
        title: language[this.props.defaultLanguage].subject,
        key: "subject",
      },
      {
        title: language[this.props.defaultLanguage].body,
        key: "body",
      },
      {
        title: language[this.props.defaultLanguage].created_at,
        key: "createdAt",
        isMoment: true,
        formatTime: "lll",
      },
      {
        title: language[this.props.defaultLanguage].actions,
        key: "action",
      },
    ];

    return headers;
  }

  onDelete = async () => {
    try {
      this.setState({ isDeleting: true });

      await deleteMessageTemplate(
        this.props.defaultLanguage,
        this.state.selectedData._id
      );

      this.setState({ isDeleting: false });

      this.getData(true);
      this.handleCloseModal("showDeleteModal");
    } catch (error) {
      this.setState({
        isDeleting: false,
      });
    }
  };

  render() {
    const { add, account, edit, delete_, view, message_template } =
      language[this.props.defaultLanguage];

    let selectedMenus = [];
    for (let menu of menus(this.props.defaultLanguage)) {
      if (this.state?.selectedData?.permissions?.menus?.includes(menu.value)) {
        selectedMenus.push(menu);
      }
    }

    return (
      <>
        <Table
          data={this.state.data}
          page={this.state.page}
          limit={this.state.limit}
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
          showAdd
          addButtonText={add}
          handleAddPressed={this.handleShowModal.bind(
            this,
            "showModal",
            message_template
          )}
          rowPress={(item) =>
            this.handleShowModal("showModal", item.name, item)
          }
          actions={[
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_MESSAGE_TEMPLATE,
                operation: "read",
              }),
              name: view,
              icon: icons.eye_on,
              onPress: (item) =>
                this.handleShowModal("showModal", account, item),
            },
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_MESSAGE_TEMPLATE,
                operation: "update",
              }),
              icon: icons.edit,
              name: edit,
              onPress: (item) => this.handleShowModal("showModal", edit, item),
            },
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_MESSAGE_TEMPLATE,
                operation: "delete",
              }),
              icon: icons.delete,
              name: delete_,
              onPress: this.handleShowModal.bind(
                this,
                "showDeleteModal",
                delete_
              ),
            },
          ]}
        />

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showModal")}
          show={this.state.showModal}
          title={this.state.modalTitle}
          showHeaderBottomBorder={false}
        >
          <NewMessageTemplate
            handleCloseModal={this.handleCloseModal.bind(this, "showModal")}
            getData={this.getData.bind(this)}
            {...this.state.selectedData}
          />
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showDeleteModal")}
          show={this.state.showDeleteModal}
          title={this.state.modalTitle}
        >
          <DeleteModal
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showDeleteModal"
            )}
            error={this.state.error.delete_password}
            value={this.state.delete_value}
            onDelete={this.onDelete.bind(this)}
            onChange={(e) => this.onChangeText("delete_value", e)}
            isDeleting={this.state.isDeleting}
            name={this.state.selectedData.name}
            getData={this.getData.bind(this)}
          />
        </Modal>
        <CSVLink
          ref="csvDownload"
          filename={"Institution" + new Date().getTime()}
          data={this.state.csvData}
        ></CSVLink>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(ListMessage);
