import React from "react";
import { connect } from "react-redux";
import { Tabs } from "../../../components/Tabs";
import language from "../../../language";
import { withRouter } from "../../../utils/withRouter";
import { getStorage } from "../../../utils/storage";
import Requests from "../../../components/Requests/Requests";
import { DashboardHeader } from "../../../components/Header";
import accessMenu from "../../../utils/accessMenu";
import SearchRequest from "../../../components/Requests/SearchRequest";
import DirectTransfer from "../../../components/Requests/shipping";
import Reception from "../../../components/Requests/shipping/reception";
import SearchShipmentQueue from "../../../components/Requests/shipping/Search";
import ShipmentReport from "../../../components/Requests/shipping/report";
import BulkRequest from "../../../components/Requests/requestForm/BulkRequest";
import NominativeRequest from "../../../components/Requests/requestForm/NominativeRequest";

class RequestManagementScreen extends React.Component {
  state = {
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });
  };
  render() {
    let tabOptions = [];
    let { canReadBloodRequestAccess, canCreateBloodRequestAccess } =
      accessMenu();

    if (canCreateBloodRequestAccess) {
      tabOptions.push(
        {
          title: language[this.props.defaultLanguage].bulk_request,
          data: (
            <div className="card">
              <div className="card-body">
                <div className="col-md-6">
                  <BulkRequest />
                </div>
              </div>
            </div>
          ),
        },

        {
          title: language[this.props.defaultLanguage].nominative_request,
          data: (
            <div className="card">
              <div className="card-body">
                <div className="col-md-6">
                  <NominativeRequest />
                </div>
              </div>
            </div>
          ),
        }
      );
    }

    if (canReadBloodRequestAccess) {
      tabOptions.push({
        title: language[this.props.defaultLanguage].historical_data,
        data: (
          <Requests
            showAdd
            status={[
              "pending",
              "processing",
              "delivering",
              "dispatched",
              "pending-pickup",
              "pickup",
            ]}
          />
        ),
      });
    }

    if (canReadBloodRequestAccess) {
      tabOptions.push({
        title: language[this.props.defaultLanguage].search,
        data: (
          <div className="card">
            <div className="card-body">
              <div className="col-md-6">
                <SearchRequest />
              </div>
            </div>
          </div>
        ),
      });
    }

    return (
      <div>
        <div className="card-dashboard-content">
          <div className="card-body">
            <DashboardHeader
              title={language[this.props.defaultLanguage].requests_and_dispatch}
            />
            <Tabs options={tabOptions} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(withRouter(RequestManagementScreen));
