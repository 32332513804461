import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../../Input";
import { Button } from "../../Button";
import language from "../../../language";
import axios from "axios";
import toastMessage from "../../../utils/toastMessage";
import { getStorage } from "../../../utils/storage";
import bloodDonationQuery from "../../../utils/queries/bloodDonationQuery";
import bloodTestQuery from "../../../utils/queries/bloodTest/bloodTestQuery";
import { Loading } from "../../Loader";
import { Empty } from "../../Empty";
import moment from "moment";
import { aboGroups } from "../../../constants/bloodGroups";
import { defineRole } from "../../../utils/handleAccessRoles";
import { ROLE_BLOOD_TEST_BLOOD_GROUPING } from "../../../constants/ROLES";
import { fetchBloodTestQuestionnaire } from "../../../utils/queries/bloodTest/questionnaireQuery";
import TESTForm from "../TESTForm";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

class BloodGrouping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      currentTime: moment().format("lll"),
      donation: {},
      questionnaire: [],
      forms: {},
      error: {},
      disabled: true,
      showForm: true,
      confirmationModal: false,
      activateDonation: true,
    };
  }

  componentDidMount = async () => {
    const user = await getStorage();

    await this.getQuestionnaires(true);

    const canAccessCreateBloodTest = defineRole({
      roles: user.accessRole,
      menu: ROLE_BLOOD_TEST_BLOOD_GROUPING,
      operation: "create",
    });

    const canAccessTestResult = defineRole({
      roles: user.accessRole,
      menu: ROLE_BLOOD_TEST_BLOOD_GROUPING,
      operation: "testResult",
    });

    if (canAccessCreateBloodTest) {
      this.setState({
        disabled: false,
      });
    } else if (canAccessTestResult && !canAccessCreateBloodTest) {
      this.setState({
        disabled: true,
      });
    } else {
      return this.setState({
        showForm: false,
      });
    }

    if (this.props.donationId) {
      this.getDonation(true);
      this.getTest(true);
    }
  };

  getQuestionnaires = async (isLoading) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const { data } = await fetchBloodTestQuestionnaire(defaultLanguage, {
        page: 1,
        limit: 100,
        type: "bloodGrouping",
      });

      this.setState({
        questionnaire: data,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  getTest = async (isLoading) => {
    const user = await getStorage();
    try {
      this.setState({
        isLoading,
      });

      const data = await bloodTestQuery(this.props.defaultLanguage, {
        donation: this.props.donationId,
      });

      let res = {},
        { questionnaire } = this.state;

      if (data.length > 0) {
        res = data[0];
      }

      let bloodGroup;

      if (res.bloodGroup) {
        bloodGroup = {
          label: res.bloodGroup,
          value: res.bloodGroup,
        };
      }

      if (res._id && res.bloodGroupingHealthQuestionnaire.length > 0) {
        const modifiedQuestions = questionnaire.map((el) => {
          const q = res.bloodGroupingHealthQuestionnaire.find(
            (qEl) => qEl.questionText === el.questionText
          );
          if (q) {
            el["answer"] = q.answer;
          }

          return el;
        });
        this.setState({ questionnaire: modifiedQuestions });
      }

      this.setState({
        isLoading: false,
        testId: res._id,
        currentTime: moment(res.createdAt).format("lll"),
        testedBy: res.testedBy?.name || user.displayName,
        bloodGroup,
        activateDonation: res.activateDonation,
      });
    } catch (error) {
      this.setState({ isLoading: false });
      console.log(error);
    }
  };

  getDonation = async (isLoading) => {
    try {
      this.setState({
        isLoading,
      });

      const data = await bloodDonationQuery(this.props.defaultLanguage, {
        id: this.props.donationId,
      });

      let donation = data.length > 0 ? data[0] : {};

      this.setState({
        donation,
        isLoading: false,
      });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  onChangeQuestionnaire = async ({ value, index }) => {
    let { questionnaire } = this.state;

    let question = questionnaire[index];

    let answerValue = value.target ? value?.target?.value : value;

    questionnaire[index]["answer"] = answerValue;

    delete questionnaire[index]["error"];

    this.setState({
      questionnaire,
    });

    const answerValueValue = answerValue?.value || answerValue;

    if (
      question?.settings &&
      question?.settings?.[answerValueValue] &&
      Object.keys(question?.settings?.[answerValueValue])?.includes(
        "activateDonation"
      )
    ) {
      this.setState({
        activateDonation:
          question?.settings?.[answerValueValue]?.activateDonation,
      });
    }
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e.target ? e?.target?.value : e,
      error,
    });
  }

  validateForm() {
    let { questionnaire, error, bloodGroup } = this.state;

    questionnaire.forEach((el) => {
      if (el.required && (!el.answer || el.answer === "")) {
        el.error = `${el.questionText} ${
          language[this.props.defaultLanguage].is_required
        }`;
      }
    });

    if (!bloodGroup) {
      error.bloodGroup =
        language[this.props.defaultLanguage].blood_group_required;
    }

    this.setState({ questionnaire, error });
  }

  onSubmit = async (status) => {
    const {
      donation,
      questionnaire,
      bloodGroup,
      testId,
      error,
      activateDonation,
    } = this.state;

    await this.validateForm();

    if (Object.keys(error).length > 0) return;

    this.setState({
      isSubmitting: true,
    });

    let url = `${API_URL}/bloodtest`,
      method = "POST",
      user = await getStorage();
    let { _id } = this.props;

    let requestedBody = {
      donation: donation._id,
      center: donation.center._id,
      centerSite: donation.centerSite._id,
      bloodGroup: bloodGroup.value,
      testedBy: user.id,
      donor: donation.donor._id,
      bloodGroupingHealthQuestionnaire: questionnaire,
      activateDonation,
      confirmedBy: {
        userId: user.id,
        name: user.firstName + " " + user.lastName,
      },
    };

    if ((_id && _id !== "") || testId) {
      method = "PUT";
      requestedBody.id = _id || testId;
    }

    if (status) {
      requestedBody.status = status;
    }

    if (status === "tested") {
      requestedBody.tested = true;
    }

    const options = {
      method,
      url,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.token,
      },
      data: requestedBody,
    };

    axios(options)
      .then((data) => {
        this.setState({
          isSubmitting: false,
        });

        toastMessage(
          "success",
          language[this.props.defaultLanguage][
            method === "PUT"
              ? "update_bloodtest_success"
              : "add_bloodtest_success"
          ]
        );

        window.location.reload();
      })
      .catch((error) => {
        this.setState({ isSubmitting: false });
        toastMessage("error", error);
      });
  };

  handleOpenModal(modal) {
    this.setState({
      [modal]: true,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  handleActivateDonation() {
    this.setState({
      activateDonation: !this.state.activateDonation,
    });
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-8">
            <div className="mb-3">
              {this.state.isLoading ? (
                <Loading />
              ) : Object.keys(this.state.donation).length > 0 ? (
                <div className="card mb-2">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-3">
                        <Input
                          label={
                            language[this.props.defaultLanguage].donor_number
                          }
                          placeholder={
                            language[this.props.defaultLanguage]
                              .donor_number_placeholder
                          }
                          value={this.state.donation.donor.donorNumber}
                          disabled
                        />
                      </div>
                      {this.state.donation.center && (
                        <div className="col-md-3">
                          <Input
                            label={language[this.props.defaultLanguage].center}
                            value={this.state.donation.center.name}
                            disabled
                          />
                        </div>
                      )}
                      {this.state.donation.centerSite && (
                        <div className="col-md-3">
                          <Input
                            label={
                              language[this.props.defaultLanguage].center_site
                            }
                            value={this.state.donation.centerSite.name}
                            disabled
                          />
                        </div>
                      )}
                      <div className="col-md-3">
                        <Select
                          options={aboGroups}
                          label={
                            language[this.props.defaultLanguage].blood_group
                          }
                          value={this.state?.bloodGroup}
                          error={this.state.error.bloodGroup}
                          onChange={(e) => this.onChangeText("bloodGroup", e)}
                          disabled={this.state.disabled}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {/* <Empty
                  title={language[this.props.defaultLanguage].no_donation_info}
                /> */}
                </>
              )}
            </div>
            <div className="card" style={{ marginBottom: "1rem" }}>
              <div className="card-body">
                <div className="row">
                  {this.state.questionnaire.map((form, index) => {
                    return (
                      <div className="col-md-4" key={index}>
                        <TESTForm
                          label={form.questionText}
                          error={form.error}
                          {...form}
                          options={form?.options?.map((el) => {
                            return {
                              label: el,
                              value: el,
                            };
                          })}
                          onChange={(e) =>
                            this.onChangeQuestionnaire({
                              index,
                              form,
                              value: e,
                            })
                          }
                          disabled={this.state.disabled}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            {!this.state.disabled && (
              <div className="card">
                <div className="card-body">
                  <div style={{ display: "flex" }}>
                    <div style={{ flex: 1 }}></div>
                    <Button
                      text={language[this.props.defaultLanguage].submit}
                      onPress={() => this.onSubmit()}
                      isSubmitting={this.state.isSubmitting}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(BloodGrouping);
