import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../Input";
import { Button } from "../Button";
import language from "../../language";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import icons from "../../constants/icons";
import { getStorage } from "../../utils/storage";
import accountQuery from "../../utils/queries/accountQuery";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;
class NewDepartment extends React.Component {
  state = {
    name: "",
    error: {},
    isSubmitting: false,
    accounts: [],
  };

  componentDidMount = async () => {
    await this.getAccounts(true);

    this.setState({ ...this.props });
  };

  getAccounts = async (isFetchingAccount) => {
    try {
      this.setState({
        isFetchingAccount,
      });

      const data = await accountQuery(this.props.defaultLanguage, {
        type: "dropdown",
      });

      this.setSelectedAccount(data);

      this.setState({
        accounts: data.data,
        isFetchingAccount: false,
      });
    } catch (error) {
      this.setState({ isFetchingAccount: false });
    }
  };

  setSelectedAccount(accounts) {
    const { manager } = this.props;
    if (manager) {
      const selectedAccount = accounts.find((el) => el._id === manager._id);

      if (selectedAccount._id) {
        this.setState({
          account: {
            label: selectedAccount.username,
            value: selectedAccount._id,
          },
        });
      }
    }
  }

  onChangeText(field, e) {
    let { error } = this.state;
    delete error[field];

    this.setState({
      [field]: e.target ? e?.target?.value : e,
      error,
    });
  }

  validateForm() {
    let { name, error } = this.state;

    if (name === "") {
      error.name =
        language[this.props.defaultLanguage].department_name_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    const {
      error_add_department,
      error_update_department,
      add_department_success,
      update_department_success,
    } = language[this.props.defaultLanguage];

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let { name, _id, account } = this.state,
        url = `${API_URL}/department`,
        method = "POST",
        user = await getStorage();

      let requestBody = {
        name,
        externalID: new Date().getTime(),
        manager: account?.value,
      };

      if (_id && _id !== "") {
        method = "PUT";
        requestBody.id = _id;
      }

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        data: requestBody,
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            method === "PUT"
              ? update_department_success
              : add_department_success
          );

          this.props.getData(true);
          this.props.handleCloseModal();
        })
        .catch((error) => {
          toastMessage(
            "error",
            method === "PUT" ? error_update_department : error_add_department
          );

          this.setState({
            isSubmitting: false,
          });
        });
    }
  };

  render() {
    console.log(this.state.accounts);
    return (
      <div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <Input
                leftIcon={icons.department}
                placeholder={
                  language[this.props.defaultLanguage]
                    .department_name_placeholder
                }
                label={language[this.props.defaultLanguage].department_name}
                required
                value={this.state.name}
                onChange={(e) => this.onChangeText("name", e)}
                error={this.state.error.name}
              />
            </div>
            <div className="col-md-12">
              <Select
                options={this.state.accounts || []}
                leftIcon={icons.user}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].manager}
                value={this.state.account}
                onChange={(e) => this.onChangeText("account", e)}
                error={this.state.error.account}
                isLoading={this.state.isFetchingAccount}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          <Button
            text={language[this.props.defaultLanguage].submit}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewDepartment);
