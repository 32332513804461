import React from "react";
import { connect } from "react-redux";
import language from "../../../language";
import { CSVLink } from "react-csv";
import { getStorage } from "../../../utils/storage";
import { fetchBloodTestHistory } from "../../../utils/queries/bloodTestHistory";
import { Loading } from "../../Loader";
import { Empty } from "../../Empty";
import { Table } from "../../Table";
import capitalizeText from "../../../utils/capitalizeText";
import { CardHeader } from "../../Header";
import { defineRole } from "../../../utils/handleAccessRoles";
import { ROLE_BLOOD_TEST_SEROLOGY } from "../../../constants/ROLES";

class BloodGrouping extends React.Component {
  state = {
    isLoading: false,
    data: {},
    page: 1,
    limit: 10,
    search_text: "",
    selectedData: {},
    totalPageCount: 10,
    delete_password: "",
    error: {},
    csvData: [],
    user: {},
    serology: [],
    bloodGrouping: [],
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });

    await this.getData(true);
  };

  componentDidUpdate(prevProps) {
    if (this.props.testId !== prevProps.testId) {
      this.getData(true);
    }
  }

  getData = async (isLoading) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage, testId } = this.props;

      this.setState({
        isLoading,
      });

      const { data, count } = await fetchBloodTestHistory(defaultLanguage, {
        page,
        limit,
        bloodTest: testId,
      });

      let res = {};

      data.map((el) => {
        if (!res[el.questionnaireType]) {
          res[el.questionnaireType] = [];
        }

        res[el.questionnaireType] = {
          ...el,
          questionnaire: el?.questionnaire?.map((elData) => {
            return {
              ...elData,
              answer: elData?.answer?.value || elData.answer,
            };
          }),
        };
      });

      console.log(res);

      this.setState({
        data: res,
        totalPageCount: count,
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
    }
  };

  renderTableHeader = () => {
    let headers = [
      {
        title: "Test",
        key: "questionText",
        styles: {
          textTransform: "upperCase",
        },
      },
      {
        title: "Result",
        key: "answer",
        styles: {
          textTransform: "upperCase",
          fontWeight: 900,
        },
      },
    ];
    return headers;
  };

  render() {
    const canReadSerologyResult = defineRole({
      roles: this.state.user.accessRole,
      menu: ROLE_BLOOD_TEST_SEROLOGY,
      operation: "read",
    });

    if (this.state.isLoading)
      return (
        <center>
          <Loading />
        </center>
      );
    if (Object.keys(this.state.data).length === 0)
      return (
        <center>
          <Empty title={language[this.props.defaultLanguage].no_data} />
        </center>
      );

    if (!this.props.testId)
      return (
        <center>
          <Empty title="Missing blood test ID" />
        </center>
      );
    return (
      <div
        style={{ maxHeight: "90vh", overflowY: "auto" }}
        className="col-md-6 mx-auto"
      >
        {this.state.data?.bloodGrouping &&
          this.state?.data?.bloodGrouping?.bloodGroup &&
          this.state?.data?.bloodGrouping?.bloodGroup !== "" && (
            <center style={{ height: 80 }}>
              <h1 style={{ fontSize: "4rem", color: "var(--primary-color)" }}>
                {this.state.data.bloodGrouping.bloodGroup}
              </h1>
            </center>
          )}
        {Object.keys(this.state.data).map((key, k) => {
          return (
            <div key={k}>
              <CardHeader
                title={capitalizeText(key)}
                styles={{
                  backgroundColor: "var(--primary-color)",
                  color: "#fff",
                }}
              />

              <div className="card-body">
                {key === "serology" ? (
                  !canReadSerologyResult ? (
                    <center>No access to read serology result</center>
                  ) : (
                    <Table
                      data={this.state.data[key].questionnaire}
                      headers={this.renderTableHeader()}
                    />
                  )
                ) : (
                  <Table
                    data={this.state.data[key].questionnaire}
                    headers={this.renderTableHeader()}
                  />
                )}
              </div>
            </div>
          );
        })}
        <CSVLink
          ref="csvDownload"
          filename={"BloodGrouping" + new Date().getTime()}
          data={this.state.csvData}
        ></CSVLink>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(BloodGrouping);
