import toastMessage from "../../toastMessage";
import { getStorage } from "../../storage";
import language from "../../../language";
import api from "../../api";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

export const fetchComponentSeparationData = async (
  lng = "english",
  {
    page,
    limit,
    donation,
    donor,
    id,
    inventory,
    code,
    component,
    componentProfile,
    addedBy,
    modifiedBy,
  }
) => {
  try {
    const user = await getStorage();

    const options = {
      method: "GET",
      url: `${API_URL}/bloodSeparation`,
      params: {
        page: page,
        limit: limit,
        donation,
        donor,
        id,
        inventory,
        code,
        component,
        componentProfile,
        addedBy,
        modifiedBy,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await api(options);

    let { data, count } = queryRes.data;

    return !page && !limit ? queryRes.data : { data, count };
  } catch (error) {
    toastMessage("error", error);
  }
};

export const deleteBloodSeparation = async (lng = "english", id) => {
  try {
    const user = await getStorage();

    const options = {
      method: "DELETE",
      url: `${API_URL}/bloodSeparation`,
      data: {
        id,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    await api(options);

    toastMessage("success", language[lng].delete_data_success);
  } catch (error) {
    toastMessage("error", error);
  }
};
