import toastMessage from "../toastMessage";
import formatSelectData from "../formatSelectData";
import { getStorage } from "../storage";
import axios from "axios";
import language from "../../language";
import { defineRole } from "../handleAccessRoles";
import { ROLE_CLUB } from "../../constants/ROLES";
import api from "../api";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

export const fetchClubs = async (
  lng = "english",
  { page, limit, id, type }
) => {
  try {
    const user = await getStorage();

    const canAccess = defineRole({
      roles: user.accessRole,
      menu: ROLE_CLUB,
      operation: "read",
    });

    if (!canAccess) return;

    const options = {
      method: "GET",
      url: `${API_URL}/club`,
      params: {
        page: page,
        limit: limit,
        id,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await api(options);

    let { data, count } = queryRes.data;

    if (type === "dropdown") {
      return formatSelectData(queryRes.data, "name", "_id");
    }

    return !page && !limit ? queryRes.data : { data, count };
  } catch (error) {
    toastMessage("error", language[lng].unable_fetch_clubs);
  }
};

export const deleteClub = async (lng = "english", id) => {
  try {
    const user = await getStorage();

    const canAccess = defineRole({
      roles: user.accessRole,
      menu: ROLE_CLUB,
      operation: "delete",
    });

    if (!canAccess) return;

    const options = {
      method: "DELETE",
      url: `${API_URL}/club`,
      data: {
        id,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    await api(options);

    toastMessage("success", language[lng].success_delete_club);
  } catch (error) {
    toastMessage("error", error);
  }
};

export const fetchClubMembers = async (
  lng = "english",
  { page, limit, id, type }
) => {
  try {
    const user = await getStorage();

    const options = {
      method: "GET",
      url: `${API_URL}/club/members`,
      params: {
        page: page,
        limit: limit,
        id,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await axios(options);

    let { data, count } = queryRes.data;

    if (type === "dropdown") {
      return formatSelectData(queryRes.data, "name", "_id");
    }

    let members = [];

    data.forEach((el) => {
      members = members.concat(el.members);
    });

    return !page && !limit ? queryRes.data : { data, count };
  } catch (error) {
    toastMessage("error", error);
  }
};

export const deleteClubMember = async (lng = "english", id) => {
  try {
    const user = await getStorage();

    const canAccess = defineRole({
      roles: user.accessRole,
      menu: ROLE_CLUB,
      operation: "delete",
    });

    if (!canAccess) return;

    const options = {
      method: "DELETE",
      url: `${API_URL}/club/addMember`,
      data: {
        id,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    await axios(options);

    toastMessage("success", language[lng].success_delete_club_member);
  } catch (error) {
    toastMessage("error", error);
  }
};
