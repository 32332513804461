import { combineReducers } from "redux";
import Language from "./Language";
import Filters from "./Filters";
import Assessment from "./Assessment";
import Notifications from "./Notifications";
import ShipmentNumber from "./ShipmentNumber";

const appReducer = combineReducers({
  Language,
  Filters,
  Assessment,
  Notifications,
  ShipmentNumber,
});

const rootReducer = (state, action) => {
  if (action.type === "ON_USER_LOGOUT") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
