import React from "react";
import { connect } from "react-redux";
import language from "../../language";
import { Table } from "../Table";
import { Modal, DeleteModal } from "../Modal";
import { CSVLink } from "react-csv";
import exportPDF from "../../utils/exportPDF";
import handleSearch from "../../utils/handleSearch";
import NewClubMember from "./NewClubMember";
import {
  fetchClubMembers,
  deleteClubMember,
} from "../../utils/queries/clubsQuery";
import { SearchDonor } from "../Donors";

let copyData = [];

class ClubMembers extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selectedData: {},
    delete_value: "",
    error: {},
    csvData: [],
  };

  componentDidMount = async () => {
    await this.getData(true);
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e?.target?.value,
      error,
    });
  }

  returnFilters() {
    const { page, limit, clubId } = this.state;
    return {
      page,
      limit,
      id: clubId,
    };
  }

  getData = async (isLoading) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const { data, count } = await fetchClubMembers(
        defaultLanguage,
        this.returnFilters()
      );

      this.setState({
        data: data,
        totalPageCount: count,
        isLoading: false,
      });

      copyData = data.slice(0);
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleShowModal(modal, modalTitle, selectedData = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selectedData,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].donor_number,
        key: "donorNumber",
        type: "link",
        onPress: (item) =>
          (window.location.href = `/dashboard/donors/${item._id}/${item.donor.firstName} ${item.lastName}`),
      },
      {
        title: language[this.props.defaultLanguage].firstName,
        key: "firstName",
      },
      {
        title: language[this.props.defaultLanguage].firstName,
        key: "lastName",
      },
      {
        title: language[this.props.defaultLanguage].active_donor,
        key: "available",
      },
      {
        title: language[this.props.defaultLanguage].created_at,
        key: "createdAt",
        isMoment: true,
        formatTime: "lll",
      },
      {
        title: language[this.props.defaultLanguage].actions,
        key: "action",
      },
    ];

    return headers;
  }

  onDelete = async () => {
    try {
      this.setState({ isDeleting: true });

      await deleteClubMember(
        this.props.defaultLanguage,
        this.state.selectedData._id
      );

      this.setState({ isDeleting: false });

      this.getData(true);
      this.handleCloseModal("showDeleteModal");
    } catch (error) {
      this.setState({
        isDeleting: false,
      });
    }
  };

  downloadExcel = async () => {
    try {
      this.setState({
        isLoading: true,
      });

      const { defaultLanguage } = this.props;

      const { data } = await fetchClubMembers(
        defaultLanguage,
        this.returnFilters()
      );

      this.setState({
        isLoading: false,
        csvData: data,
      });

      this.refs.csvDownload?.link.click();
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  downloadPDF = async () => {
    this.setState({
      isLoading: true,
    });

    const { page, limit } = this.state;
    const { defaultLanguage, clubId } = this.props;

    const headers = this.returnTableHeaders();

    const { data } = await fetchClubMembers(
      defaultLanguage,
      this.returnFilters()
    );

    this.setState({
      isLoading: false,
    });

    exportPDF(
      this.props.clubName + " " + language[this.props.defaultLanguage].members,
      headers,
      data
    );
  };

  render() {
    return (
      <>
        <Table
          data={this.state.data}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          handleSearch={(e) =>
            handleSearch(this, {
              copyData,
              value: e?.target?.value,
            })
          }
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
          showAdd={true}
          addButtonText={language[this.props.defaultLanguage].add}
          handleAddPressed={this.handleShowModal.bind(
            this,
            "newMemberModal",
            language[this.props.defaultLanguage].search_donor
          )}
          rowPress={(item) =>
            this.handleShowModal("showModal", item.name, item)
          }
          // filters={[
          //   {
          //     type: "export",
          //     title: language[this.props.defaultLanguage].export_,
          //     button_type: "dropdown",
          //     icon: "bx bxs-download",
          //     options: [
          //       {
          //         name: "PDF",
          //         onPress: this.downloadPDF.bind(this),
          //       },
          //       {
          //         name: "CSV",
          //         onPress: this.downloadExcel.bind(this),
          //       },
          //     ],
          //   },
          // ]}
          actions={[
            {
              name: language[this.props.defaultLanguage].edit,
              onPress: (item) =>
                this.handleShowModal(
                  "newMemberModal",
                  language[this.props.defaultLanguage].edit,
                  item
                ),
            },
            {
              name: language[this.props.defaultLanguage].delete,
              onPress: this.handleShowModal.bind(
                this,
                "showDeleteModal",
                language[this.props.defaultLanguage].delete
              ),
            },
          ]}
        />
        {/* <Modal
          handleClose={this.handleCloseModal.bind(this, "searchDonorModal")}
          show={this.state.searchDonorModal}
          title={this.state.modalTitle}
          showHeaderBottomBorder={false}
        >
          <SearchDonor
            handleNext={(donor) =>
              this.handleShowModal(
                "newMemberModal",
                language[this.props.defaultLanguage].search_donor,
                donor
              )
            }
          />
        </Modal> */}

        <Modal
          handleClose={this.handleCloseModal.bind(this, "newMemberModal")}
          show={this.state.newMemberModal}
          title={language[this.props.defaultLanguage].add}
          showHeaderBottomBorder={false}
        >
          <NewClubMember
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "newMemberModal"
            )}
            getData={this.getData.bind(this)}
            {...this.state.selectedData}
            handleChangeDonor={() => {
              this.handleCloseModal("newMemberModal");
              this.handleShowModal(
                "searchDonorModal",
                language[this.props.defaultLanguage].search_donor
              );
            }}
            clubId={this.props.clubId}
          />
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showDeleteModal")}
          show={this.state.showDeleteModal}
          title={this.state.modalTitle}
        >
          <DeleteModal
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showDeleteModal"
            )}
            error={this.state.error.delete_password}
            value={this.state.delete_value}
            onDelete={this.onDelete.bind(this)}
            onChange={(e) => this.onChangeText("delete_value", e)}
            isDeleting={this.state.isDeleting}
            name={this.state.selectedData.name}
            getData={this.getData.bind(this)}
          />
        </Modal>
        <CSVLink
          ref="csvDownload"
          filename={
            this.props.clubName +
            " " +
            language[this.props.defaultLanguage].members
          }
          data={this.state.csvData}
        ></CSVLink>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(ClubMembers);
