import toast, { Toaster } from "react-hot-toast";

export default function toastMessage(type = "error", message) {
  let new_message = message;

  if (
    message &&
    message.response &&
    message.response.data &&
    message.response.data.error
  ) {
    new_message = message.response.data.error;
  }
  if (
    message &&
    message.response &&
    message.response.data &&
    message.response.data.error &&
    message.response.data.error.error
  ) {
    new_message = message.response.data.error.error;
  } else if (
    message &&
    message.response &&
    message.response.data &&
    message.response.data.error
  ) {
    new_message = message.response.data.error;
  } else if (
    message &&
    message.response &&
    message.response.data &&
    message.response.data.message
  ) {
    new_message = message.response.data.message;
  }

  if (!new_message || new_message === "") return;

  // Check for duplicate key error and modify the message accordingly
  if (
    typeof new_message === "string" &&
    new_message.includes("E11000 duplicate key error")
  ) {
    new_message = "Data already exists";
  }

  const config = {
    position: "top-right",
    duration: 5000,
  };

  const modifiedMessage =
    typeof new_message === "string" ? new_message : JSON.stringify(new_message);

  type && type === "error"
    ? toast.error(modifiedMessage, {
        ...config,
        style: {
          background: "#ffcfcf",
          color: "#f50707",
        },
      })
    : type === "success"
    ? toast.success(modifiedMessage, {
        ...config,
        style: {
          background: "#d5f7d6",
          color: "green",
        },
      })
    : toast.error(modifiedMessage, {
        ...config,
        style: {
          background: "#a6d7ff",
          color: "#078ece",
        },
      });
}
