import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../../../Input/index.js";
import language from "../../../../language/index.js";
import { Button } from "../../../Button/index.js";
import toastMessage from "../../../../utils/toastMessage.js";
import { getStorage } from "../../../../utils/storage.js";
import axios from "axios";
import { fetchShippingInQueueData } from "../../../../utils/queries/request/shipping-queue.js";
import { Modal } from "../../../Modal/index.js";
import { fetchBloodBanks } from "../../../../utils/queries/bloodBank.js";
import { Table } from "../../../Table/index.js";
import HistoricalData from "./HistoricalData.js";
import { Tabs } from "../../../Tabs/index.js";

const receptionStatus = [
  {
    label: "Received",
    value: "received",
  },
  {
    label: "Returned",
    value: "returned",
  },
];

class Reception extends React.Component {
  state = {
    error: {},
    data: [],
    isDoneSearching: false,
    shipmentNumber: "",
    // status: receptionStatus[0],
  };

  onChangeText = async (field, e) => {
    let { error, data } = this.state;
    let inputValue = e.target ? e?.target?.value : e;

    delete error[field];

    this.setState({
      [field]: inputValue,
      isSearching: false,
    });
  };

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].product,
        key: "product",
      },
      {
        title: language[this.props.defaultLanguage].donation,
        key: "donation.donationNumber",
      },
      {
        title: language[this.props.defaultLanguage].source,
        key: "from.name",
      },
      {
        title: language[this.props.defaultLanguage].destination,
        key: "to.name",
      },
      {
        title: language[this.props.defaultLanguage].added_by,
        key: "addedBy.pin",
      },
      {
        title: language[this.props.defaultLanguage].date,
        key: "createdAt",
        isMoment: true,
        formatTime: "lll",
      },
    ];

    return headers;
  }

  validateForm = () => {
    const { error, shipmentNumber, status, bloodBank, source } = this.state;

    if (shipmentNumber === "") {
      error.shipmentNumber = "Shipment number is required";
    }

    if (!status) {
      error.status = "Status is required";
    } else if (
      status &&
      status.value === "returned" &&
      (!source || !bloodBank)
    ) {
      error.status = "Source and destination are mandatory";
    }

    this.setState({
      error,
    });
  };

  onSubmit = async () => {
    try {
      const { error, shipmentNumber, status, source, bloodBank } = this.state;
      const user = await getStorage();

      await this.validateForm();

      if (Object.keys(error).length > 0) return;

      try {
        this.setState({ isSubmitting: true });

        let method = "PUT",
          requestBody = {
            shipmentNumber: shipmentNumber.toUpperCase(),
            status: status.value,
            source: source?.value,
            bloodBank: bloodBank?.value,
          };

        const options = {
          method,
          url: `${process.env.REACT_APP_BBMS_BASE_API}/shipping-queue/reception`,
          data: requestBody,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + user.token,
          },
        };

        await axios(options);

        toastMessage("success", `Stock has been ${status.value} successful`);

        this.setState({
          isSubmitting: false,
          shipmentNumber: "",
        });
        this.handleCloseModal("showInfoModal");
      } catch (error) {
        toastMessage("error", error);

        this.setState({
          isSubmitting: false,
        });
      }

      this.setState({ isSubmitting: false });
    } catch (error) {
      toastMessage("error", error);
    }
  };

  getData = async (isLoading) => {
    try {
      await this.validateForm();

      if (Object.keys(this.state.error).length > 0) return;

      const { page, limit } = this.state;
      const { defaultLanguage, shipmentNumber, product } = this.state;

      this.setState({ isLoading });

      const requestBody = {
        shipmentNumber: shipmentNumber?.toUpperCase(),
        product,
      };

      const data = await fetchShippingInQueueData(defaultLanguage, requestBody);

      if (data.length > 0) {
        this.setState({
          data,
        });

        // this.onSubmit();

        this.handleOpenModal("showInfoModal");
      } else {
        toastMessage("error", language[this.props.defaultLanguage].no_data);
      }

      this.setState({
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });

      console.log(error);

      toastMessage("error", error);
    }
  };

  handleOpenModal = (modal) => {
    this.setState({ [modal]: true });
  };

  handleCloseModal = (modal) => {
    this.setState({ [modal]: false });
  };

  getBloodBanks = async (isFetchingBloodBanks, search = {}) => {
    try {
      this.setState({
        isFetchingBloodBanks,
      });

      const data = await fetchBloodBanks(this.props.defaultLanguage, {
        page: 1,
        limit: 50,
        ...search,
        type: "dropdown",
      });

      let destination,
        res = [];

      data.forEach((el) => {
        if (el.isDefault) {
          destination = el;
        } else {
          res.push(el);
        }
      });

      this.setState({
        isFetchingBloodBanks: false,
        sourceStocks: res,
        bloodBank: destination,
      });

      return res;
    } catch (error) {
      this.setState({ isFetchingBloodBanks: false });
      toastMessage("error", error);
      console.log(error);
    }
  };

  render() {
    return (
      <>
        <Tabs
          options={[
            {
              title: language[this.props.defaultLanguage].new_record,
              data: (
                <div className="card mb-3">
                  <div className="card-body">
                    <form>
                      <Input
                        placeholder={
                          language[this.props.defaultLanguage]
                            .shipment_number_placeholder
                        }
                        label={
                          language[this.props.defaultLanguage].shipment_number
                        }
                        value={this.state.shipmentNumber}
                        onChange={(e) => this.onChangeText("shipmentNumber", e)}
                        error={this.state.error.shipmentNumber}
                        inputStyles={{
                          textTransform: "uppercase",
                        }}
                      />
                      <Select
                        options={receptionStatus}
                        placeholder={
                          language[this.props.defaultLanguage].select
                        }
                        label={language[this.props.defaultLanguage].status}
                        value={this.state.status}
                        onChange={(e) => this.onChangeText("status", e)}
                        error={this.state.error.status}
                      />
                      {this.state?.status?.value === "returned" && (
                        <div className="row mb-3">
                          <div className="col-md-6">
                            <Select
                              options={this.state.sourceStocks}
                              placeholder={
                                language[this.props.defaultLanguage].select
                              }
                              label={
                                language[this.props.defaultLanguage].source
                              }
                              value={this.state.source}
                              onChange={(e) => this.onChangeText("source", e)}
                              error={this.state.error.bloodBank}
                            />
                          </div>
                          <div className="col-md-6">
                            <Select
                              loadOptions={(e) =>
                                this.getBloodBanks(true, {
                                  q: e,
                                })
                              }
                              placeholder={
                                language[this.props.defaultLanguage].select
                              }
                              label={
                                language[this.props.defaultLanguage].destination
                              }
                              value={this.state.bloodBank}
                              onChange={(e) =>
                                this.onChangeText("bloodBank", e)
                              }
                              error={this.state.error.bloodBank}
                            />
                          </div>
                        </div>
                      )}
                      <Button
                        text={language[this.props.defaultLanguage].submit}
                        isSubmitting={this.state.isLoading}
                        onPress={(e) => {
                          e.preventDefault();
                          this.getData(true);
                        }}
                      />
                    </form>
                  </div>
                </div>
              ),
            },
            {
              title: language[this.props.defaultLanguage].historical_data,
              data: <HistoricalData />,
            },
          ]}
        />
        <Modal
          show={this.state.showInfoModal}
          handleClose={() => this.handleCloseModal("showInfoModal")}
          title={this.state?.status?.label}
        >
          <div
            className="card-body"
            style={{ maxHeight: "60vh", overflowY: "auto" }}
          >
            {this.state.data.length > 0 && (
              <Table
                data={this.state.data}
                headers={this.returnTableHeaders()}
              />
            )}
          </div>
          <div className="modal-footer">
            <Button
              text={language[this.props.defaultLanguage].cancel}
              className="btn-default"
            />
            <Button
              text={language[this.props.defaultLanguage].confirm}
              onPress={() => this.onSubmit()}
              isSubmitting={this.state.isSubmitting}
              disabled={this.state.data.length === 0}
            />
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Reception);
