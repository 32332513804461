import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../../Input/index.js";
import language from "../../../language/index.js";
import icons from "../../../constants/icons.js";
import { Button } from "../../Button/index.js";
import toastMessage from "../../../utils/toastMessage.js";
import { fetchBloodBanks } from "../../../utils/queries/bloodBank.js";
import { getStorage } from "../../../utils/storage.js";
import axios from "axios";
import ShippingQueue from "./ShippingQueue.js";
import {
  fetchShipmentNumber,
  fetchShippingInQueueData,
} from "../../../utils/queries/request/shipping-queue.js";
import { generateShipmentNumber } from "../../../utils/random.js";
import { defineRoleCenterSite } from "../../../utils/handleAccessRoles.js";

class DirectTransfer extends React.Component {
  state = {
    error: {},
    data: [],
    isDoneSearching: false,
    donationNumber: "",
    donation: {},
    inventories: [],
    sourceStocks: [],
    inventory: "",
    product: "",
    user: {},
    isLoadingShipmentNumber: true,
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });

    this.getShipmentNumber(true);
  };

  componentDidUpdate(prevProps) {
    console.log(
      " this.props.refreshShipmentNumber",
      this.props.refreshShipmentNumber
    );
    if (
      prevProps.refreshShipmentNumber !== this.props.refreshShipmentNumber &&
      this.props.refreshShipmentNumber
    ) {
      this.getShipmentNumber(true);
    }
  }

  getBloodBanks = async (isFetchingBloodBanks, search = {}) => {
    try {
      this.setState({
        isFetchingBloodBanks,
      });

      const { stock } = await defineRoleCenterSite();

      const data = await fetchBloodBanks(this.props.defaultLanguage, {
        page: 1,
        limit: 50,
        ...search,
        type: "dropdown",
      });

      let source,
        res = [];

      data.forEach((el) => {
        if (stock?.includes(el._id)) {
          source = el;
          res.push(el);
          // Use 'return' to exit this iteration as we found a matching element
          return;
        }

        // If the element is marked as default and source is not set
        if (el.isDefault && !source) {
          source = el;
        }

        // Push the element to 'res' if it's not the source already added
        if (source !== el) {
          res.push(el);
        }
      });

      this.setState({
        isFetchingBloodBanks: false,
        sourceStocks: res,
        source,
      });

      return res;
    } catch (error) {
      this.setState({ isFetchingBloodBanks: false });
      toastMessage("error", error);
      this.onReset();
      console.log(error);
    }
  };

  getShipmentNumber = async (isLoadingShipmentNumber) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoadingShipmentNumber });

      const data = await fetchShipmentNumber(defaultLanguage);

      this.setState({
        shipmentNumber: generateShipmentNumber(data.data),
        isLoadingShipmentNumber: false,
        isSubmitting: false,
        isLoadingShipmentNumber: false,
      });
    } catch (error) {
      this.setState({
        isLoadingShipmentNumber: false,
        isSubmitting: false,
        isLoadingShipmentNumber: false,
      });

      toastMessage("error", error);
    }
  };

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e.target ? e?.target?.value : e;

    delete error[field];

    this.setState({
      [field]: inputValue,
      isSearching: false,
    });
  };

  handleShowModal(modal, title, item = {}) {
    this.setState({
      [modal]: true,
      modalTitle: title,
      ...item,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  onReset() {
    this.getShipmentNumber(true);

    this.setState({
      donationNumber: "",
      donation: {},
      product: "",
    });
  }

  validateTransfer = () => {
    const { error, bloodBank, product, shipmentNumber, inventory } = this.state;

    if (!bloodBank) {
      error.bloodBank = "Destination stock not found";
    }

    if (!product) {
      error.product = "Product is required";
    }

    if (shipmentNumber === "") {
      error.shipmentNumber = "Shipment number is required";
    }

    this.setState({
      error,
    });
  };

  onSubmit = async () => {
    const {
      error,
      bloodBank,
      product,
      source,
      shipmentNumber,
      donationNumber,
    } = this.state;
    const user = await getStorage();

    const { _id } = this.props;

    if (!this.state.donation) return;

    await this.validateTransfer();

    if (Object.keys(error).length > 0) return;

    try {
      this.setState({ isSubmitting: true, isRefreshing: false });

      let method = "POST",
        requestBody = {
          shipmentNumber,
          product,
          to: bloodBank.value,
          from: source.value,
          donationNumber,
        };

      if (_id && _id == "") {
        method = "PUT";
        requestBody.id = _id;
      }

      const options = {
        method,
        url: `${process.env.REACT_APP_BBMS_BASE_API}/shipping-queue`,
        data: requestBody,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
      };

      await axios(options);

      toastMessage(
        "success",
        language[this.props.defaultLanguage].add_data_success
      );

      this.setState({
        isSubmitting: false,
        isRefreshing: true,
        donationNumber: "",
        product: "",
      });
    } catch (error) {
      toastMessage("error", error);

      this.setState({
        isSubmitting: false,
        isRefreshing: false,
      });
    }
  };

  render() {
    return (
      <div className="card mb-3">
        <div className="card-body">
          <form>
            <div className="row mb-3">
              <div className="col-md-6">
                <Select
                  options={this.state.sourceStocks}
                  placeholder={language[this.props.defaultLanguage].select}
                  label={language[this.props.defaultLanguage].source}
                  value={this.state.source}
                  onChange={(e) => this.onChangeText("source", e)}
                  error={this.state.error.source}
                  leftIcon={icons.source}
                  disabled={this.state.user.stock}
                />
              </div>
              <div className="col-md-6">
                <Select
                  loadOptions={(e) =>
                    this.getBloodBanks(true, {
                      q: e,
                    })
                  }
                  placeholder={language[this.props.defaultLanguage].select}
                  label={language[this.props.defaultLanguage].destination}
                  value={this.state.bloodBank}
                  onChange={(e) => this.onChangeText("bloodBank", e)}
                  error={this.state.error.bloodBank}
                  leftIcon={icons.bloodBank}
                />
              </div>
            </div>
            <Input
              placeholder={
                language[this.props.defaultLanguage].shipment_number_placeholder
              }
              label={language[this.props.defaultLanguage].shipment_number}
              value={this.state.shipmentNumber}
              onChange={(e) => this.onChangeText("shipmentNumber", e)}
              error={this.state.error.shipmentNumber}
              disabled
            />
            <Input
              placeholder={
                language[this.props.defaultLanguage].donation_number_placeholder
              }
              label={language[this.props.defaultLanguage].donation_number}
              value={this.state.donationNumber}
              onChange={(e) => this.onChangeText("donationNumber", e)}
              error={this.state.error.donationNumber}
              leftIcon={icons.user}
            />
            <div className="col-md-12">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].product_placeholder
                }
                label={language[this.props.defaultLanguage].product}
                value={this.state.product}
                onChange={(e) => this.onChangeText("product", e)}
                error={this.state.error.product}
                leftIcon={icons.product}
              />
            </div>
            {/* {!this.state?.donation?._id && (
              <Button
                text={language[this.props.defaultLanguage].search}
                isSubmitting={this.state.isSearching}
                onPress={(e) => {
                  e.preventDefault();
                  this.handleSearch();
                }}
              />
            )} */}
            <div className="d-flex gap-2">
              <Button
                text={language[this.props.defaultLanguage].reset}
                onPress={(e) => {
                  e.preventDefault();
                  this.onReset();
                }}
                className="btn-default"
                type="button"
              />
              <Button
                text={language[this.props.defaultLanguage].add}
                isSubmitting={this.state.isSubmitting}
                onPress={(e) => {
                  e.preventDefault();
                  this.onSubmit();
                }}
              />
            </div>
          </form>
        </div>

        <div className="card">
          <div className="card-body">
            <ShippingQueue
              shipmentNumber={this.state.shipmentNumber}
              isRefreshing={this.state.isRefreshing}
              addedBy={this.state?.user?._id}
              status="pending"
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { refreshShipmentNumber } = state.ShipmentNumber;
  return {
    defaultLanguage,
    refreshShipmentNumber,
  };
};

export default connect(mapStateToProps)(DirectTransfer);
