import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../../Input";
import { Button } from "../../Button";
import language from "../../../language";
import axios from "axios";
import toastMessage from "../../../utils/toastMessage";
import { getStorage } from "../../../utils/storage";
import moment from "moment";
import bloodCenterQuery from "../../../utils/queries/bloodCenterQuery";
import bloodCenterSiteQuery from "../../../utils/queries/bloodCenterSiteQuery";
import { Empty } from "../../Empty";
import { donorNumber } from "../../../utils/uniqueID";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

const statuses = [
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "Transferred",
    value: "transferred",
  },
  {
    label: "Labelled",
    value: "labelled",
  },
  {
    label: "Rejected",
    value: "rejected",
  },
  {
    label: "Expired",
    value: "expired",
  },
  {
    label: "Blocked",
    value: "blocked",
  },
];

class NewInventory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: {},
      isSubmitting: false,
      donation: [],
      isLoading: false,
      centers: [],
      centerSites: [],
      reason: "",
    };
  }

  componentDidMount = async () => {
    console.log("inventory props", this.props);
    let { error } = this.state;

    this.setState({
      code: donorNumber(4),
    });

    this.getCenters(true);

    if (this.props._id) {
      await this.setState({
        center: this.props.center._id
          ? {
              label: this.props.center.name,
              value: this.props.center._id,
            }
          : null,
        centerSite: this.props.centerSite._id
          ? {
              label: this.props.centerSite.name,
              value: this.props.centerSite._id,
            }
          : null,
        status: this.props.status
          ? {
              label: this.props.status,
              value: this.props.status,
            }
          : null,
        volume: this.props.volume,
        expiredDate: this.props.expiredDate,
        available: {
          label: this.props.available + "",
          value: this.props.available + "",
        },
        reason: this.props.blockInfo
          ? {
              label: this.props.blockInfo.blockReason,
              value: this.props.blockInfo.blockReason,
            }
          : null,
        status: this.props.status
          ? {
              label: this.props.status,
              value: this.props.status,
            }
          : null,
      });
    }

    // if (!this.state.donationId) {
    //   error.errorMessage =
    //     language[this.props.defaultLanguage].donation_required;
    // }
  };

  // getTemperatures = async () => {
  //   try {
  //     let { status } = this.state;
  //     this.setState({
  //       isFetchingTemperatures: true,
  //     });

  //     const { data } = await fetchTransportationTemperatures(
  //       this.props.defaultLanguage,
  //       { page: 1, limit: 100 }
  //     );

  //     const temperatureRange = findTemperatureRange(
  //       this.state.transportationTemperature,
  //       data
  //     );

  //     let inventoryStatuses = temperatureRange.map((el) => {
  //       return {
  //         label: el.decision,
  //         value: el.decision,
  //       };
  //     });

  //     if (inventoryStatuses && inventoryStatuses.length === 1) {
  //       status = inventoryStatuses[0];
  //     }

  //     this.setState({
  //       temperatures: data,
  //       isFetchingTemperatures: false,
  //       inventoryStatuses,
  //       status,
  //     });
  //   } catch (error) {
  //     toastMessage("error", error);

  //     this.setState({
  //       isFetchingTemperatures: false,
  //     });
  //   }
  // };

  getCenters = async (isFetchingCenter, search = {}) => {
    try {
      this.setState({
        isFetchingCenter,
      });

      const data = await bloodCenterQuery(this.props.defaultLanguage, {
        type: "dropdown",
        ...search,
      });

      this.setState({
        centers: data,
        isFetchingCenter: false,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingCenter: false });
    }
  };

  getSites = async (isFetchingSite, center) => {
    if (!center) return;
    try {
      this.setState({
        isFetchingSite,
      });

      const data = await bloodCenterSiteQuery(this.props.defaultLanguage, {
        center,
        type: "dropdown",
      });

      this.setState({
        centerSites: data,
        isFetchingSite: false,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingSite: false });
    }
  };

  onChangeText = async (field, e) => {
    let { error, label } = this.state;
    let inputValue = e.target ? e?.target?.value : e;

    delete error[field];

    await this.setState({
      [field]: inputValue,
      error,
    });

    if (field === "center") {
      this.setState({ site: null });
      this.getSites(true, inputValue.value);
    }

    if (this.state.weight === "") {
      this.setState({ label: null });

      return;
    }

    let { status } = this.state;

    if (field === "reason") {
      if (inputValue?.status) {
        for (let el of statuses) {
          if (inputValue?.status === el.value) {
            status = el;
          }
        }
      } else if (this.props.status) {
        status = {
          label: this.props.status,
          value: this.props.status,
        };
      }

      this.setState({
        status,
      });
    }
  };

  validateForm() {
    let { expiredDate, error, centerSite, center, volume, status, reason } =
      this.state;

    if (!expiredDate) {
      error.expiredDate =
        language[this.props.defaultLanguage].expiration_date_required;
    }

    if (!volume) {
      error.volume = language[this.props.defaultLanguage].volume_required;
    }

    if (!center) {
      error.center = language[this.props.defaultLanguage].center_required;
    }

    if (!centerSite) {
      error.centerSite = language[this.props.defaultLanguage].site_required;
    }

    if (!status) {
      error.status = language[this.props.defaultLanguage].status_required;
    }

    if (!reason || reason === "") {
      error.reason = language[this.props.defaultLanguage].reason_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let { _id, blockReason } = this.props;
      let { volume, expiredDate, center, centerSite, status, reason } =
          this.state,
        url = `${API_URL}/inventory`,
        method = "PUT",
        user = await getStorage();

      blockReason.blockReason = reason.value;

      let requestedBody = {
        expiredDate,
        volume,
        volume: volume,
        center: center.value,
        centerSite: centerSite.value,
        status: status.value,
        id: _id,
        component: this.props?.component?._id,
        blockReason,
      };

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
        data: requestedBody,
      };
      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            language[this.props.defaultLanguage][
              method === "PUT" ? "update_data_success" : "add_data_success"
            ]
          );

          this.props.getData(true);
          this.props.handleCloseModal();
        })
        .catch((error) => {
          this.setState({ isSubmitting: false });
          toastMessage("error", error);
        });
    }
  };

  render() {
    console.log(this.state.reason);
    return (
      <>
        <div>
          {!this.props._id ? (
            <center>
              <Empty title={language[this.props.defaultLanguage].no_data} />
            </center>
          ) : Object.keys(this.props.donation).length > 0 ? (
            <>
              <div className="card-body">
                <div className="row">
                  <>
                    <div className="col-md-4">
                      <Input
                        label={
                          language[this.props.defaultLanguage].donation_number
                        }
                        value={this.props.donation.donationNumber}
                        disabled
                      />
                    </div>
                    <div className="col-md-4">
                      <Input
                        label={language[this.props.defaultLanguage].product}
                        value={this.props.code}
                        disabled
                      />
                    </div>
                    <div className="col-md-4">
                      <Input
                        label={language[this.props.defaultLanguage].component}
                        value={this.props.name}
                        disabled
                      />
                    </div>
                    <div className="col-md-4">
                      <Input
                        label={language[this.props.defaultLanguage].volume}
                        value={this.props.volume}
                        disabled
                      />
                    </div>

                    <div className="col-md-4">
                      <Input
                        label={language[this.props.defaultLanguage].blood_group}
                        value={this.props.bloodGroup}
                        disabled
                      />
                    </div>
                    <div className="col-md-6">
                      <Input
                        label={
                          language[this.props.defaultLanguage]
                            .blood_grouping_result
                        }
                        value={this.props.bloodGroupingResult}
                        disabled
                      />
                    </div>
                    <div className="col-md-6">
                      <Input
                        label={
                          language[this.props.defaultLanguage].serology_result
                        }
                        value={this.props.serologyResult}
                        disabled
                      />
                    </div>
                    <div className="col-md-6">
                      <Select
                        loadOptions={(e) => this.getCenters(true, { q: e })}
                        placeholder={
                          language[this.props.defaultLanguage].select
                        }
                        label={language[this.props.defaultLanguage].center}
                        value={this.state?.center}
                        error={this.state.error.center}
                        onChange={(e) => this.onChangeText("center", e)}
                      />
                    </div>
                    <div className="col-md-6">
                      <Select
                        options={this.state.centerSites}
                        label={language[this.props.defaultLanguage].site}
                        value={this.state.centerSite}
                        error={this.state.error.centerSite}
                        onChange={(e) => this.onChangeText("centerSite", e)}
                      />
                    </div>
                    <div className="col-md-6">
                      <Input
                        label={language[this.props.defaultLanguage].volume}
                        value={this.state.volume}
                        error={this.state.error.volume}
                        onChange={(e) => this.onChangeText("volume", e)}
                        type="number"
                      />
                    </div>
                    <div className="col-md-6">
                      <Input
                        label={
                          language[this.props.defaultLanguage]
                            .transportation_temperature
                        }
                        placeholder={
                          language[this.props.defaultLanguage]
                            .transportation_temperature_placeholder
                        }
                        value={this.props.component.storageTemperature?.name}
                        disabled
                      />
                    </div>

                    <div className="col-md-12">
                      <Input
                        label={
                          language[this.props.defaultLanguage].expired_date
                        }
                        value={this.state.expiredDate}
                        error={this.state.error.status}
                        onChange={(e) => this.onChangeText("status", e)}
                        type="datetime-local"
                      />
                    </div>

                    {this.props?.blockInfo?.blocked && (
                      <div className="col-md-12">
                        <Input
                          label={
                            language[this.props.defaultLanguage].block_reason
                          }
                          placeholder={
                            language[this.props.defaultLanguage]
                              .reason_placeholder
                          }
                          value={this.props.blockInfo.blockReason}
                          disabled
                        />
                      </div>
                    )}
                    {this.props?.rejectionInfo?.rejected && (
                      <div className="col-md-12">
                        <Input
                          label={
                            language[this.props.defaultLanguage].reject_reason
                          }
                          placeholder={
                            language[this.props.defaultLanguage]
                              .reason_placeholder
                          }
                          value={this.props.rejectionInfo.reason}
                          disabled
                        />
                      </div>
                    )}
                    {this.props?.reverseInfo?.reversed && (
                      <div className="col-md-12">
                        <Input
                          label={
                            language[this.props.defaultLanguage].reverse_reason
                          }
                          placeholder={
                            language[this.props.defaultLanguage]
                              .reason_placeholder
                          }
                          value={this.props.reverseInfo.reason}
                          disabled
                        />
                      </div>
                    )}
                  </>
                </div>

                <div className="col-md-12">
                  <Select
                    options={statuses}
                    label={language[this.props.defaultLanguage].status}
                    value={this.state.status}
                    error={this.state.error.status}
                    onChange={(e) => this.onChangeText("status", e)}
                    disabled
                  />
                </div>
              </div>
              {/* {!this.props.isDisabled && (
                <div className="modal-footer">
                  <Button
                    text={language[this.props.defaultLanguage].cancel}
                    onPress={this.props.handleCloseModal}
                    className="btn-default"
                  />
                  <Button
                    text={language[this.props.defaultLanguage].submit}
                    onPress={this.onSubmit.bind(this)}
                    isSubmitting={this.state.isSubmitting}
                  />
                </div>
              )} */}
            </>
          ) : (
            <Empty title={language[this.props.defaultLanguage].not_found} />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewInventory);
