import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../Input";
import { Button } from "../Button";
import language from "../../language";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import bloodCenterSiteQuery from "../../utils/queries/bloodCenterSiteQuery";
import { getStorage } from "../../utils/storage";
import availableOptions from "../../constants/availableOptions";
import bloodCenterQuery from "../../utils/queries/bloodCenterQuery";
import { defineRoleCenterSite } from "../../utils/handleAccessRoles";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;
class NewEquipment extends React.Component {
  state = {
    name: "",
    error: {},
    isSubmitting: false,
    description: "",
    available: availableOptions[0],
    centers: [],
    centerSites: [],
  };

  componentDidMount = async () => {
    this.setState({ ...this.props });

    if (this.props._id && this.props._id !== "") {
      this.setState({
        code: this.props.code,
        name: this.props.name,
        description: this.props.description,
        available: this.props?.available
          ? {
              label: this.props?.available + "",
              value: this.props?.available + "",
            }
          : null,
        department: this.props?.department
          ? {
              label: this.props?.department,
              value: this.props?.department,
            }
          : null,
        center:
          this.props.center && this.formatFormData(this.props.center, "center"),
        centerSite:
          this.props.centerSite &&
          this.formatFormData(this.props.centerSite, "centerSite"),
      });
    }
  };

  formatFormData(data, field) {
    return Array.isArray(data)
      ? data.map((el) => {
          return {
            ...el,
            label: el?.name,
            value: el?._id,
          };
        })
      : [
          {
            ...data,
            label: data?.[field]?.name,
            value: data?.[field]?._id,
          },
        ];
  }

  onChangeText(field, e) {
    let { error } = this.state;
    delete error[field];

    const value = e.target ? e?.target?.value : e;

    this.setState({
      [field]: value,
      error,
    });

    if (field === "center") {
      this.getCenterSites({ center: value?.value });
    }
  }

  getCenters = async (q) => {
    try {
      this.setState({
        isFetchingCenter: true,
      });

      const data = await bloodCenterQuery(this.props.defaultLanguage, {
        type: "dropdown",
        page: 1,
        limit: 50,
        q: q !== "" ? q : undefined,
      });

      let center = null;
      const { centerRole } = await defineRoleCenterSite();
      if (!this.props._id && centerRole) {
        data.forEach((el) => {
          if (centerRole.includes(el._id)) {
            center = el;
          }
        });

        this.setState({ center }, () => {
          this.getCenterSites({ center: center?.value });
        });
      }

      this.setState({
        centers: data,
        isFetchingCenter: false,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingCenter: false });

      return [];
    }
  };

  getCenterSites = async (search = {}) => {
    try {
      this.setState({
        isFetchingCenterSite: true,
        centerSites: [],
        centerSite: null,
      });

      const data = await bloodCenterSiteQuery(this.props.defaultLanguage, {
        type: "dropdown",
        page: 1,
        limit: 50,
        ...search,
      });

      const { centerSiteRole } = await defineRoleCenterSite();
      let centerSite = null;

      if (!this.props._id && centerSiteRole) {
        data.forEach((el) => {
          if (centerSiteRole.includes(el._id)) {
            centerSite = el;
          }
        });

        this.setState({ centerSite });
      }

      this.setState({
        centerSites: data,
        isFetchingCenterSite: false,
      });

      return data;
    } catch (error) {
      console.log(error);
      this.setState({ isFetchingCenterSite: false });

      return [];
    }
  };

  validateForm() {
    let { name, error, code } = this.state;

    if (name === "") {
      error.name = language[this.props.defaultLanguage].name_required;
    }

    if (code === "") {
      error.code = language[this.props.defaultLanguage].code_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let {
          _id,
          name,
          description,
          available,
          code,
          center,
          department,
          centerSite,
        } = this.state,
        url = `${API_URL}/equipment`,
        method = "POST",
        user = await getStorage();

      let requestBody = {
        name,
        description,
        available: available.value,
        code,
        center: center?.map((el) => el.value),
        centerSite: centerSite?.map((el) => el.value),
        department: department?.value,
      };

      if (_id && _id !== "") {
        method = "PUT";
        requestBody.id = _id;
      }

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        data: requestBody,
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            method === "PUT"
              ? language[this.props.defaultLanguage].update_equipment_success
              : language[this.props.defaultLanguage].add_equipment_success
          );

          this.props.getData(true);
          this.props.handleCloseModal();
        })
        .catch((error) => {
          toastMessage("error", error);

          this.setState({
            isSubmitting: false,
          });
        });
    }
  };

  render() {
    return (
      <div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].code_placeholder
                }
                label={language[this.props.defaultLanguage].code}
                required
                value={this.state.code}
                onChange={(e) => this.onChangeText("code", e)}
                error={this.state.error.code}
              />
            </div>
            <div className="col-md-12">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].name_placeholder
                }
                label={language[this.props.defaultLanguage].name}
                required
                value={this.state.name}
                onChange={(e) => this.onChangeText("name", e)}
                error={this.state.error.name}
              />
            </div>
            <div className="col-md-12">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].description_placeholder
                }
                label={language[this.props.defaultLanguage].description}
                value={this.state.description}
                onChange={(e) => this.onChangeText("description", e)}
                error={this.state.error.description}
              />
            </div>
            <div className="col-md-12">
              <Select
                loadOptions={(e) => this.getCenters(e)}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].center}
                value={this.state.center}
                onChange={(e) => this.onChangeText("center", e)}
                error={this.state.error.center}
                isLoading={this.state.isFetchingCenter}
                isMulti
              />
            </div>
            <div className="col-md-12">
              <Select
                options={this.state.centerSites}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].center_site}
                value={this.state.centerSite}
                onChange={(e) => this.onChangeText("centerSite", e)}
                error={this.state.error.centerSite}
                isLoading={this.state.isFetchingCenterSite}
                isMulti
              />
            </div>
            <div className="col-md-12">
              <Select
                options={[
                  {
                    label: "Vital sign",
                    value: "donation",
                  },
                  {
                    label: "Collection",
                    value: "collection",
                  },
                  {
                    label: "Production",
                    value: "production",
                  },
                  {
                    label: "Laboratory",
                    value: "laboratory",
                  },
                  {
                    label: "Separation",
                    value: "separation",
                  },
                  {
                    label: "Transformation",
                    value: "transformation",
                  },
                ]}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].department}
                value={this.state.department}
                onChange={(e) => this.onChangeText("department", e)}
                error={this.state.error.department}
                isCreatable
              />
            </div>
            <div className="col-md-12">
              <Select
                options={availableOptions}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].available}
                required
                value={this.state.available}
                onChange={(e) => this.onChangeText("available", e)}
                error={this.state.error.available}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          <Button
            text={language[this.props.defaultLanguage].submit}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewEquipment);
