import React from "react";
import { connect } from "react-redux";
import language from "../../../language";
import { withRouter } from "../../../utils/withRouter";
import { getStorage } from "../../../utils/storage";
import { BloodTesting } from "../../../components/BloodTesting";
import { Empty } from "../../../components/Empty";
import { Breadcrumb } from "react-bootstrap";
import capitalizeText from "../../../utils/capitalizeText";

class TestingByStatusScreen extends React.Component {
  state = {
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user: user });
  };

  render() {
    if (this.props.routeParams.status) {
      return (
        <>
          <div className="card-body">
            <Breadcrumb
              homeUrl="/dashboard/blood testing"
              data={[
                {
                  name: capitalizeText(this.props.routeParams.status),
                  active: true,
                },
              ]}
            />
            <BloodTesting status={this.props.routeParams.status} />
          </div>
        </>
      );
    }

    return <Empty title={language[this.props.defaultLanguage].no_data} />;
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(withRouter(TestingByStatusScreen));
