import toastMessage from "../toastMessage";
import formatSelectData from "../formatSelectData";
import { getStorage } from "../storage";
import axios from "axios";
import language from "../../language";
import { defineRole } from "../handleAccessRoles";
import { ROLE_JOB_POSITION } from "../../constants/ROLES";
import api from "../api";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

const fetchData = async (
  lng = "english",
  { page, limit, type, department, positionName }
) => {
  try {
    const user = await getStorage();

    const canAccess = defineRole({
      roles: user.accessRole,
      menu: ROLE_JOB_POSITION,
      operation: "read",
    });

    if (!canAccess) return;

    let params = {
      page,
      limit,
      department,
      name: positionName,
    };

    const options = {
      method: "GET",
      url: `${API_URL}/position`,
      params,
      headers: {
        authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await api(options);

    let { data, count } = queryRes.data;

    if (type === "dropdown") {
      return formatSelectData(queryRes.data, "name", "_id");
    }

    return { data, count };
  } catch (error) {
    toastMessage("error", error);
  }
};

export default fetchData;

export const deletePosition = async (lng = "english", id) => {
  try {
    const user = await getStorage();

    const canAccess = defineRole({
      roles: user.accessRole,
      menu: ROLE_JOB_POSITION,
      operation: "delete",
    });

    if (!canAccess) return;

    const options = {
      method: "DELETE",
      url: `${API_URL}/position`,
      data: {
        id,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    await api(options);

    toastMessage("success", language[lng].success_delete_position);
  } catch (error) {
    toastMessage("error", error);
  }
};
