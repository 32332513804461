import toastMessage from "../toastMessage";
import formatSelectData from "../formatSelectData";
import { getStorage } from "../storage";
import axios from "axios";
import language from "../../language";
import api from "../api";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

const fetchData = async (lng = "english", { page, limit, type }) => {
  try {
    const user = await getStorage();

    const options = {
      method: "GET",
      url: `${API_URL}/feedback`,
      params: {
        page: page,
        limit: limit,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await api(options);

    let { data, count } = queryRes.data;

    if (type === "dropdown") {
      return formatSelectData(queryRes.data, "name", "_id");
    }

    return !page && !limit ? queryRes.data : { data, count };
  } catch (error) {
    toastMessage("error", language[lng].unable_fetch_feedbacks);
  }
};

export default fetchData;

export const deleteFeedback = async (lng = "english", id) => {
  try {
    const user = await getStorage();

    const options = {
      method: "DELETE",
      url: `${API_URL}/feedback`,
      data: {
        id,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    await axios(options);

    toastMessage("success", language[lng].success_delete_feedback);
  } catch (error) {
    toastMessage("error", language[lng].error_delete_feedback);
  }
};
