export const handleValidateMatching = ({
  that,
  field,
  value,
  questionnaireIndex,
}) => {
  let { error, misMatchFields, previousTestResults } = that.state;

  previousTestResults?.forEach((elv, i) => {
    if (elv.status !== "cancelled") {
      Object.keys(elv).forEach((key) => {
        const fv = value?.value || value;
        if (field === "bloodGroup" && elv.bloodGroup !== fv) {
          error[field] = "Mismatch";
          if (!misMatchFields.includes(field)) {
            misMatchFields.push(field); // Add to mismatch
          }
        } else if (
          questionnaireIndex !== undefined &&
          key === "questionnaire"
        ) {
          elv.questionnaire.forEach((qEl) => {
            if (
              qEl.questionText?.toUpperCase() === field?.toUpperCase() &&
              (qEl.answer?.value?.toUpperCase() ||
                qEl.answer?.toUpperCase()) !== value?.toUpperCase()
            ) {
              // questionnaire[questionnaireIndex]["error"] = "Mismatch";
              if (!misMatchFields.includes(qEl.questionText)) {
                misMatchFields.push(qEl.questionText);
              }
              console.log(misMatchFields);
            } else {
              if (elv.bloodGroup === that.state.aboBloodGroup?.value) {
                misMatchFields.splice(misMatchFields.indexOf("bloodGroup"), 1);
                delete error["bloodGroup"];
              }
            }
          });
        }
      });
    }
  });

  that.setState({
    error,
    misMatchFields,
  });
};
