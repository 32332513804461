import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../../Input";
import { Button } from "../../Button";
import language from "../../../language";
import axios from "axios";
import toastMessage from "../../../utils/toastMessage";
import { fetchBloodBanks } from "../../../utils/queries/bloodBank";
import hospitalQuery from "../../../utils/queries/hospitalQuery";
import { getStorage } from "../../../utils/storage";
import availableOptions from "../../../constants/availableOptions";
import Packages from "../Packages";
import icons from "../../../constants/icons";
import {
  defineRole,
  defineRoleCenterSite,
} from "../../../utils/handleAccessRoles";
import {
  generateRandomNumber,
  generateRandomString,
} from "../../../utils/random";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

const statusOptions = [
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "Dispatched",
    value: "dispatched",
  },
  {
    label: "Delivered",
    value: "delivered",
  },
];

class BulkRequest extends React.Component {
  state = {
    error: {},
    isSubmitting: false,
    donors: [],
    distributors: [],
    hospitals: [],
    numberOfPackages: "",
    isActive: availableOptions[0],
    description: "",
    approvals: [],
    packages: [],
    centers: [],
    centerSites: [],
    status: statusOptions[0],
    bloodBanks: [],
    requestNo: "",
  };

  componentDidMount = async () => {
    this.setState({
      requestNo:
        generateRandomString(4).toUpperCase() +
        "-" +
        generateRandomNumber(0, 1000000),
    });

    this.getHospitals(true);

    await this.setState({ ...this.props });

    if (this.props._id) {
      this.setState({
        bloodBank:
          this.props.bloodBank && this.props.bloodBank._id
            ? {
                label: this.props.bloodBank?.center?.name,
                value: this.props.bloodBank._id,
              }
            : null,
        distributor:
          this.props.distributor && this.props.distributor._id
            ? {
                label: this.props.distributor.name,
                value: this.props.distributor._id,
              }
            : null,
        hospital:
          this.props.hospital && this.props.hospital._id
            ? {
                label: this.props.hospital.name,
                value: this.props.hospital._id,
              }
            : null,
        packages: this.props.packages || [],
        approvals: this.props.approvals || [],
        isActive: {
          label: this.props.isActive + "",
          value: this.props.isActive + "",
        },
        status: statusOptions.find((el) => el.value === this.props.status),
      });
    }
  };

  getHospitals = async (isFetchingHospital) => {
    try {
      this.setState({
        isFetchingHospital,
      });

      const { hospitalRole } = defineRoleCenterSite();

      const data = await hospitalQuery(this.props.defaultLanguage, {
        type: "dropdown",
        id: hospitalRole,
      });

      this.setState({
        hospitals: data,
        isFetchingHospital: false,
        hospital: data[0],
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingHospital: false });
    }
  };

  getBloodBanks = async (isFetchingBloodBank, search = {}) => {
    try {
      const { hospital } = this.state;
      this.setState({
        isFetchingBloodBank,
      });

      const data = await fetchBloodBanks(this.props.defaultLanguage, {
        type: "dropdown",
        // hospital: hospital?.value,
        ...search,
      });

      this.setState({
        bloodBanks: data,
        isFetchingBloodBank: false,
      });

      return data;
    } catch (error) {
      console.log(error);
      this.setState({ isFetchingBloodBank: false });
    }
  };

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e.target ? e?.target?.value : e;

    delete error[field];

    await this.setState({
      [field]: inputValue,
      error,
    });

    if (field === "hospital") {
      this.getBloodBanks(true);
    }
  };

  onSavePackages(packages) {
    this.setState({
      packages,
    });
  }

  validateForm() {
    let { error, hospital, packages, description, requestNo } = this.state;

    if (description === "") {
      error.description =
        language[this.props.defaultLanguage].description_required;
    }

    if (!hospital) {
      error.hospital = language[this.props.defaultLanguage].hospital_required;
    }

    if (packages.length === 0) {
      error.packages = language[this.props.defaultLanguage].packages_required;
    }

    if (requestNo === "") {
      error.requestNo =
        language[this.props.defaultLanguage].request_number_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let {
          hospital,
          _id,
          description,
          status,
          packages,
          bloodBank,
          requestNo,
        } = this.state,
        url = `${API_URL}/bloodrequest`,
        method = "POST",
        user = await getStorage();

      let requestedBody = {
        requestNo,
        hospital: hospital.value,
        description: description,
        packages: packages,
        bloodBank: bloodBank.value,
        account: user.id,
        type: "bulk",
      };

      if (status?.value) {
        requestedBody.status = status.value;
      }

      if (_id && _id !== "") {
        method = "PUT";
        requestedBody.id = _id;
      }

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
        data: requestedBody,
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            language[this.props.defaultLanguage][
              method === "PUT"
                ? "update_bloodrequest_success"
                : "add_bloodrequest_success"
            ]
          );

          this.props.getData && this.props.getData(true);
          this.props.handleCloseModal && this.props.handleCloseModal();

          this.onReset();
        })
        .catch((error) => {
          toastMessage("error", error);
          this.setState({
            isSubmitting: false,
          });
        });
    }
  };

  onReset() {
    this.setState({
      isSubmitting: false,
      distributors: [],
      hospitals: [],
      numberOfPackages: "",
      isActive: availableOptions[0],
      description: "",
      approvals: [],
      packages: [],
      centers: [],
      centerSites: [],
      status: statusOptions[0],
      bloodBank: null,
      packages: [],
      requestNo:
        generateRandomString(4).toUpperCase() +
        "-" +
        generateRandomNumber(0, 1000000),
    });
  }

  render() {
    return (
      <div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <Input
                placeholder={
                  language[this.props.defaultLanguage]
                    .request_number_placeholder
                }
                label={language[this.props.defaultLanguage].request_number}
                required
                value={this.state.requestNo}
                onChange={(e) => this.onChangeText("requestNo", e)}
                error={this.state.error.requestNo}
              />
            </div>
            <div className="col-md-12">
              <Select
                loadOptions={this.getHospitals}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].requested_by}
                required
                value={this.state.hospital}
                onChange={(e) => this.onChangeText("hospital", e)}
                error={this.state.error.hospital}
                isLoading={this.state.isFetchingHospital}
              />
            </div>
            <div className="col-md-12">
              <Select
                loadOptions={(e) => this.getBloodBanks(true, e)}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].blood_bank}
                required
                value={this.state.bloodBank}
                onChange={(e) => this.onChangeText("bloodBank", e)}
                error={this.state.error.bloodBank}
                isLoading={this.state.isFetchingBloodBank}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].description_placeholder
                }
                label={language[this.props.defaultLanguage].description}
                required
                value={this.state.description}
                onChange={(e) => this.onChangeText("description", e)}
                error={this.state.error.description}
                textarea
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card bordered">
                <div
                  className="card-header"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <span className="material-symbols-outlined">
                    {icons.package}
                  </span>
                  <span className="text-bold">
                    {language[this.props.defaultLanguage].request_packages}
                  </span>
                </div>
                <div className="card-body">
                  <Packages
                    data={this.state.packages}
                    onSavePackages={(packages) => this.onSavePackages(packages)}
                  />
                </div>
              </div>
            </div>
            {this.state.error.packages && (
              <span className="text-error">{this.state.error.packages}</span>
            )}
          </div>
          <div className="col-md-12">
            <Select
              options={statusOptions}
              placeholder={language[this.props.defaultLanguage].select}
              label={language[this.props.defaultLanguage].status}
              required
              value={this.state.status}
              onChange={(e) => this.onChangeText("status", e)}
              error={this.state.error.status}
            />
          </div>
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].reset}
            onPress={this.onReset.bind(this)}
            className="btn-default"
          />
          <Button
            text={language[this.props.defaultLanguage].submit}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(BulkRequest);
