import React from "react";

import { connect } from "react-redux";
import AuthFormLayout from "../../Layout/AuthFormLayout";
import { Input } from "../Input";
import { Button } from "../Button";
import language from "../../language";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import { getStorage, setStorage } from "../../utils/storage";
import icons from "../../constants/icons";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

class PINLogin extends React.Component {
  state = {
    pin: "",
    isSubmitting: false,
    error: {},
    password: "",
  };

  onChangeText = (field, e) => {
    let { error } = this.state;

    delete error[field];

    this.setState({ [field]: e?.target?.value });
  };

  validateForm = () => {
    let { error, pin } = this.state;

    if (pin === "") {
      error.pin = language[this.props.defaultLanguage].pin_required;
    }

    this.setState({ error });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({ isSubmitting: true });

      const { pin } = this.state;
      const user = await getStorage();

      const options = {
        method: "POST",
        url: `${API_URL}/authentication/admin`,
        data: {
          email: user.email,
          passcode: pin?.toString()?.toUpperCase(),
        },
      };

      axios(options)
        .then((data) => {
          this.setState({ isSubmitting: false });

          if (data.data.status === "error")
            return toastMessage("error", data.data.message);

          this.onSuccess(data.data);
        })
        .catch((error) => {
          this.setState({ isSubmitting: false });

          if (error?.response?.data?.message) {
            return toastMessage("error", error.response.data.message);
          }

          toastMessage(
            "error",
            language[this.props.defaultLanguage].wrong_email_password
          );
        });
    }
  };

  onSuccess = async (data) => {
    this.setState({ isSubmitting: false });

    if (data.id) {
      await setStorage(
        {
          isValid: true,
          route: this.props.newRoute,
        },
        "PIN"
      );
      this.props.handleClose && this.props.handleClose();

      return;
    }
  };

  render() {
    return (
      <div className="card-body">
        <div className="title">
          <h3 className="text-primary" style={{ textTransform: "uppercase" }}>
            {language[this.props.defaultLanguage].pin_title}
          </h3>
          <p className="text-disabled">
            {language[this.props.defaultLanguage].pin_description}
          </p>
        </div>
        <form>
          <Input
            label={language[this.props.defaultLanguage].pin}
            placeholder={language[this.props.defaultLanguage].pin_placeholder}
            required
            leftIcon={icons.password}
            value={this.state.pin}
            error={this.state.error.pin}
            onChange={(e) => this.onChangeText("pin", e)}
            type="password"
            inputStyles={{ textTransform: "uppercase" }}
          />
          <Button
            text={language[this.props.defaultLanguage].login_text}
            className="w-100"
            isSubmitting={this.state.isSubmitting}
            onPress={this.onSubmit.bind(this)}
          />
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(PINLogin);
