import toastMessage from "../toastMessage";
import { getStorage } from "../storage";
import axios from "axios";
import language from "../../language";
import { defineRole } from "../handleAccessRoles";
import { ROLE_APPOINTMENT, ROLE_ASSESSMENT } from "../../constants/ROLES";
import moment from "moment";
import api from "../api";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

export const fetchPreAssessmentInformation = async (
  lng = "english",
  {
    page,
    limit,
    type,
    donor,
    id,
    center,
    centerSite,
    status,
    appointmentNumber,
    isEligible,
  }
) => {
  try {
    const user = await getStorage();

    const canAccess = defineRole({
      roles: user.accessRole,
      menu: ROLE_ASSESSMENT,
      operation: "read",
      pass: user.role === "donor" || user.account_type === "donor",
    });

    const canAccess2 = defineRole({
      roles: user.accessRole,
      menu: ROLE_APPOINTMENT,
      operation: "read",
      pass: user.role === "donor" || user.account_type === "donor",
    });

    if (!canAccess && !canAccess2) return;

    const params = {
      page: page,
      limit: limit,
      donor,
      id,
      center,
      centerSite,
      status,
      appointmentNumber:
        appointmentNumber !== "" ? appointmentNumber : undefined,
      isEligible,
    };

    if (donor) {
      delete params.center;
      delete params.centerSite;
    }

    const options = {
      method: "GET",
      url: `${API_URL}/preDonationAssessment`,
      params,
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await api(options);

    if (type === "dropdown") {
      return queryRes.data.data.map((el) => {
        return {
          ...el,
          label:
            el.appointmentNumber +
            " - " +
            moment(el.requestedDate).format("ll"),
          value: el._id,
        };
      });
    }

    let { data, count } = queryRes.data;

    if (type === "dropdown") {
      return queryRes.data.map((el) => {
        return {
          label: el.appointmentNumber,
          value: el._id,
        };
      });
    }

    return !page && !limit ? queryRes.data : { data, count };
  } catch (error) {
    toastMessage("error", error);
  }
};

export const deletePreAssessmentDonorsInfo = async (lng = "english", id) => {
  try {
    const user = await getStorage();

    const canAccess = defineRole({
      roles: user.accessRole,
      menu: ROLE_ASSESSMENT,
      operation: "delete",
    });

    if (!canAccess) return;

    const options = {
      method: "DELETE",
      url: `${API_URL}/preDonationAssessment`,
      data: {
        id,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    await api(options);

    toastMessage("success", language[lng].success_delete_preassessment);
  } catch (error) {
    toastMessage("error", error);
  }
};
