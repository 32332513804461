import React from "react";
import { connect } from "react-redux";
import language from "../../language";
import { Table } from "../Table";
import { bloodTestStatistic } from "../../utils/queries/bloodTest/bloodTestQuery";
import { defineRoleCenterSite } from "../../utils/handleAccessRoles";
import { getStorage } from "../../utils/storage";
import icons from "../../constants/icons";
import moment from "moment";

class BloodTestStatistics extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selectedData: {},
    delete_value: "",
    error: {},
    csvData: [],
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });

    await this.getData(true);
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e?.target?.value,
      error,
    });
  }

  getData = async (isLoading) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage, type, filters } = this.props;

      this.setState({ isLoading });

      const { centerRole, centerSiteRole } = await defineRoleCenterSite();

      const data = await bloodTestStatistic(defaultLanguage, {
        page,
        limit,
        // center: centerRole,
        centerSite: centerSiteRole,
        type,
        startCollectionDate: moment(filters.startDate)
          .startOf("D")
          .format("YYYY-MM-DD HH:mm"),
        endCollectionDate: moment(filters.endDate)
          .endOf("D")
          .format("YYYY-MM-DD HH:mm"),
      });

      this.setState({
        data: data,
        // totalPageCount: count,
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].collection_date,
        key: "_id.donationDay",
      },
      {
        title: language[this.props.defaultLanguage].center,
        key: "center.name",
      },
      {
        title: language[this.props.defaultLanguage].center_site,
        key: "centerSite.name",
      },
      {
        title: language[this.props.defaultLanguage].total_collected,
        key: "totalRecords",
      },
      {
        title: language[this.props.defaultLanguage].total_tested,
        key: "totalTested",
      },
      {
        title: language[this.props.defaultLanguage].total_pending,
        key: "totalPending",
      },
      {
        title: language[this.props.defaultLanguage].first_entry,
        key: "totalWithFirstEntry",
      },
      {
        title: language[this.props.defaultLanguage].first_entry,
        key: "totalWithSecondEntry",
      },
    ];

    return headers.filter(Boolean);
  }

  handleShowModal(modal, modalTitle, selectedData = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selectedData,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  render() {
    return (
      <>
        <Table
          data={this.state.data}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          // handleSearch={(e) => this.onChangeText("search_text", e)}
          searching={this.state.searching}
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
          filters={[
            {
              type: "button",
              title: language[this.props.defaultLanguage].refresh,
              button_type: "button",
              icon: icons.refresh,
              onPress: (item) => this.getData(true),
            },
            {
              type: "dropdown",
              filterName: "date_range",
              title: language[this.props.defaultLanguage].date_range,
              button_type: "dropdown",
              handleApplyFilters: () => this.getData(),
            },
            {
              type: "button",
              title: language[this.props.defaultLanguage].export_,
              button_type: "button",
              icon: icons.export,
              onPress: (item) =>
                this.handleShowModal(
                  "exportModal",
                  language[this.props.defaultLanguage].export_
                ),
            },
          ]}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(BloodTestStatistics);
