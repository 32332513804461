import React from "react";
import { connect } from "react-redux";
import language from "../../../../language";
import { Table } from "../../../Table";
import {
  deleteInventory,
  fetchInventories,
} from "../../../../utils/queries/production/inventoryQuery";
import bloodDonationQuery from "../../../../utils/queries/bloodDonationQuery";
import { DeleteModal, Modal } from "../../../Modal";
import {
  defineRole,
  defineRoleCenterSite,
} from "../../../../utils/handleAccessRoles";
import { getStorage } from "../../../../utils/storage";
import icons from "../../../../constants/icons";
import ViewInventory from "../ViewInventory";
import exportPDF from "../../../../utils/exportPDF";
import CardCount from "../../../CardCount/CardCount";
import { CentersFilter, DateFilter } from "../../../Filters";
import { CSVLink } from "react-csv";
import { ROLE_INVENTORY } from "../../../../constants/ROLES";
import moment from "moment";
import { generateColor } from "../../../../utils/color";

class RejectedInventories extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selectedData: {},
    delete_value: "",
    error: {},
    csvData: [],
    user: {},
    suspendReason: "",
    counts: {
      blocked: 0,
      rejected: 0,
      expired: 0,
      UNDER_WEIGHT: 0,
      OVER_WEIGHT: 0,
      LEAKAGE: 0,
      LOW_PLATELET_COUNT: 0,
      AGGREGATED_PLATELET: 0,
      PLATELET_WITH_RED_BLOOD_CELLS: 0,
    },
    isDisabled: true,
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });

    this.fetchData(true);
  };

  componentDidUpdate(prevProps) {
    if (this.props.filters !== prevProps.filters) {
      this.fetchData(true);
    }
  }

  fetchData(isLoading, search = {}) {
    this.getData(isLoading, { ...search });
    this.getCountsData(isLoading, { name: "blocked", status: "blocked" });
    this.getCountsData(isLoading, { name: "expired", status: "expired" });
    this.getCountsData(isLoading, { name: "rejected", status: "rejected" });
    this.getCountsData(isLoading, {
      name: "AGGREGATED_PLATELET",
      reason: "AGGREGATED_PLATELET",
    });
    this.getCountsData(isLoading, {
      name: "UNDER_WEIGHT",
      reason: "UNDER_WEIGHT",
    });
    this.getCountsData(isLoading, {
      name: "LOW_WEIGHT",
      reason: "LOW_WEIGHT",
    });
    this.getCountsData(isLoading, {
      name: "OVER_WEIGHT",
      reason: "OVER_WEIGHT",
    });
    this.getCountsData(isLoading, { name: "LEAKAGE", reason: "LEAKAGE" });
    this.getCountsData(isLoading, {
      name: "LOW_PLATELET_COUNT",
      reason: "LOW_PLATELET_COUNT",
    });
    this.getCountsData(isLoading, {
      name: "PLATELET_WITH_RED_BLOOD_CELLS",
      reason: "PLATELET_WITH_RED_BLOOD_CELLS",
    });
  }

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e.target ? e?.target?.value : e,
      error,
    });
  }

  returnFilters = async (search = {}) => {
    const { page, limit } = this.state;
    const { centerRole, centerSiteRole } = await defineRoleCenterSite();
    const { filters } = this.props;
    return {
      // ...filters,
      page,
      limit,
      donation: this.props.donationId,
      id: this.props.inventoryId,
      startDate: filters.startDate,
      endDate: filters.endDate,
      center: centerRole || filters?.centers?.map((el) => el.value),
      centerSite: centerSiteRole || filters?.centerSite?.map((el) => el.value),
      ...search,
      status: ["rejected", "blocked", "expired"],
    };
  };

  getData = async (isLoading, search = {}) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const filters = await this.returnFilters(search);

      if (search?.donationNumber) {
        const donation = await bloodDonationQuery(defaultLanguage, {
          ...search,
        });

        if (donation?.length > 0) {
          filters.donation = donation[0]?._id || undefined;
        } else {
          this.setState({ isLoading: false });
          return;
        }
      }

      const { data, count } = await fetchInventories(defaultLanguage, filters);

      this.setState({
        data: data,
        totalPageCount: count,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  getCountsData = async (isLoadingCounts, { reason, status, name }) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoadingCounts });

      const filters = await this.returnFilters();

      let params = {
        ...filters,
        isCount: true,
        reason,
        status,
      };

      const { data } = await fetchInventories(defaultLanguage, params);

      let { counts } = this.state;

      counts[name] = data;

      this.setState({
        counts,
        isLoadingCounts: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isLoadingCounts: false,
      });
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleShowModal(modal, modalTitle, selectedData = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selectedData,
      reason: selectedData.reason || "",
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].donation_number,
        key: "donation.donationNumber",
        // type: "link",
        // onPress: (item) =>
        //   (window.location.href = `/dashboard/production/center/${item?.center?._id}/${item?.center?.name}/${item?.centerSite?._id}/${item?.centerSite?.name}`),
      },
      {
        title: language[this.props.defaultLanguage].code,
        key: "code",
      },
      {
        title: language[this.props.defaultLanguage].product,
        key: "name",
      },
      {
        title: language[this.props.defaultLanguage].blood_group,
        key: "bloodGroup",
      },
      {
        title: language[this.props.defaultLanguage].volume,
        key: "volume",
      },
      {
        title: language[this.props.defaultLanguage].expired_date,
        key: "expiredDate",
        formatTime: "lll",
        isMoment: true,
      },

      {
        title: language[this.props.defaultLanguage].labelled_by,
        key: "verifiedBy.pin",
      },
      {
        title: language[this.props.defaultLanguage].status,
        key: "status",
        type: "status",
      },

      {
        title: language[this.props.defaultLanguage].reason,
        key: "reason",
      },

      {
        title: language[this.props.defaultLanguage].source,
        key: "sourceStock.name",
      },
      {
        title: language[this.props.defaultLanguage].current_stock,
        key: "stock.name",
      },

      {
        title: language[this.props.defaultLanguage].dispatched_date,
        key: "transferDate",
        formatTime: "lll",
        isMoment: true,
      },
      {
        title: language[this.props.defaultLanguage].actions,
        key: "action",
      },
    ];

    return headers;
  }

  onDelete = async () => {
    try {
      this.setState({ isDeleting: true });

      await deleteInventory(
        this.props.defaultLanguage,
        this.state.selectedData._id
      );

      this.setState({ isDeleting: false });

      this.getData(true);
      this.handleCloseModal("showDeleteModal");
    } catch (error) {
      this.setState({
        isDeleting: false,
      });
    }
  };

  downloadExcel = async () => {
    try {
      this.setState({
        isLoading: true,
      });

      const { defaultLanguage } = this.props;

      const filters = await this.returnFilters();

      const { data } = await fetchInventories(defaultLanguage, filters);

      this.setState({
        isLoading: false,
        csvData: data.map((el) => {
          return {
            ...el,
            center: el?.center?.name,
            centerSite: el?.centerSite?.name,
          };
        }),
      });

      this.refs.csvDownload?.link.click();
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  downloadPDF = async () => {
    this.setState({
      isLoading: true,
    });

    const { defaultLanguage } = this.props;

    const filters = await this.returnFilters();

    const { data } = await fetchInventories(defaultLanguage, filters);

    this.setState({
      isLoading: false,
    });

    exportPDF("Inventories", this.returnTableHeaders(), data);
  };

  handleRemoteSearch = async (search) => {
    this.setState({
      searching: true,
    });
    await this.fetchData(true, {
      donationNumber: search || search !== "" ? search : undefined,
    });

    this.setState({
      searching: false,
    });
  };

  handleSearch = async (e) => {
    const search = e?.target?.value;
    this.setState({ search_text: search });
  };

  render() {
    return (
      <>
        <div style={{ marginBottom: 16 }}>
          <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
            <CentersFilter />
            <DateFilter
              startDate={moment().startOf("d").format("YYYY-MM-DD")}
              endDate={moment().endOf("d").format("YYYY-MM-DD")}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-3 ">
            <CardCount
              count={this.state.counts.blocked}
              title="BLOCKED"
              color="orange"
              icon="block"
              isLoading={this.state.isLoadingCounts}
              onPress={() =>
                (window.location.href =
                  "/dashboard/production/inventory/byStatus/blocked")
              }
            />
          </div>

          <div className="col-md-3 ">
            <CardCount
              count={this.state.counts.expired}
              title="EXPIRED"
              color="red"
              icon="schedule"
              isLoading={this.state.isLoadingCounts}
              onPress={() =>
                (window.location.href =
                  "/dashboard/production/inventory/byStatus/expired")
              }
            />
          </div>

          <div className="col-md-3 ">
            <CardCount
              count={this.state.counts.rejected}
              title="REJECTED"
              color="red"
              icon="swipe_left"
              isLoading={this.state.isLoadingCounts}
              onPress={() =>
                (window.location.href =
                  "/dashboard/production/inventory/byStatus/rejected")
              }
            />
          </div>
          <div className="col-md-3 ">
            <CardCount
              count={this.state.counts.UNDER_WEIGHT}
              title="UNDER_WEIGHT"
              color="#078ECE"
              icon="arrow_downward"
              isLoading={this.state.isLoadingCounts}
              onPress={() =>
                (window.location.href =
                  "/dashboard/production/inventory/byStatus/labelled")
              }
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-3 ">
            <CardCount
              count={this.state.counts.LOW_WEIGHT}
              title="LOW_WEIGHT"
              color={generateColor(3)}
              icon="arrow_upward"
              isLoading={this.state.isLoadingCounts}
              onPress={() =>
                (window.location.href =
                  "/dashboard/production/inventory/byReason/LOW_WEIGHT")
              }
            />
          </div>
          <div className="col-md-3 ">
            <CardCount
              count={this.state.counts.OVER_WEIGHT}
              title="OVER_WEIGHT"
              color="gray"
              icon="arrow_upward"
              isLoading={this.state.isLoadingCounts}
              onPress={() =>
                (window.location.href =
                  "/dashboard/production/inventory/byReason/OVER_WEIGHT")
              }
            />
          </div>

          <div className="col-md-3 ">
            <CardCount
              count={this.state.counts.LEAKAGE}
              title="LEAKAGE"
              color="turquoise"
              icon="personal_bag"
              isLoading={this.state.isLoadingCounts}
              onPress={() =>
                (window.location.href =
                  "/dashboard/production/inventory/byReason/LEAKAGE")
              }
            />
          </div>

          <div className="col-md-3">
            <CardCount
              count={this.state.counts.AGGREGATED_PLATELET}
              title="AGGREGATED_PLATELET"
              color="purple"
              icon="menu"
              isLoading={this.state.isLoadingCounts}
              onPress={() =>
                (window.location.href =
                  "/dashboard/production/inventory/byReason/AGGREGATED_PLATELET")
              }
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-3">
            <CardCount
              count={this.state.counts.LOW_PLATELET_COUNT}
              title="LOW_PLATELET_COUNT"
              color="purple"
              icon="thermometer_loss"
              isLoading={this.state.isLoadingCounts}
              onPress={() =>
                (window.location.href =
                  "/dashboard/production/inventory/byReason/LOW_PLATELET_COUNT")
              }
            />
          </div>
          <div className="col-md-3">
            <CardCount
              count={this.state.counts.PLATELET_WITH_RED_BLOOD_CELLS}
              title="PLATELET_WITH_RED_BLOOD_CELLS"
              color="red"
              icon="bloodtype"
              isLoading={this.state.isLoadingCounts}
              onPress={() =>
                (window.location.href =
                  "/dashboard/production/inventory/byReason/PLATELET_WITH_RED_BLOOD_CELLS")
              }
            />
          </div>
        </div>

        <Table
          data={this.state.data}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          addSearchButton
          search_text={this.state.search_text}
          searching={this.state.searching}
          handleSearch={(e) => this.onChangeText("search_text", e)}
          handlePressSearch={() =>
            this.handleRemoteSearch(this.state.search_text)
          }
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
          filters={[
            {
              type: "button",
              title: language[this.props.defaultLanguage].refresh,
              button_type: "button",
              icon: icons.refresh,
              onPress: (item) => this.fetchData(true),
            },
            {
              type: "export",
              title: language[this.props.defaultLanguage].export_,
              button_type: "dropdown",
              icon: "bx bxs-download",
              options: [
                {
                  name: "PDF",
                  onPress: this.downloadPDF.bind(this),
                },
                {
                  name: "CSV",
                  onPress: this.downloadExcel.bind(this),
                },
              ],
            },
          ]}
          actions={[
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_INVENTORY,
                operation: "read",
              }),
              name: language[this.props.defaultLanguage].view,
              icon: icons.eye_on,
              onPress: (item) =>
                this.handleShowModal("showModal", item.code, item),
            },
          ]}
        />
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showModal")}
          show={this.state.showModal}
          title={this.state.modalTitle}
          showHeaderBottomBorder={false}
          size="lg"
        >
          <ViewInventory
            handleCloseModal={this.handleCloseModal.bind(this, "showModal")}
            getData={this.fetchData.bind(this)}
            {...this.state.selectedData}
            donationId={
              this.props.donationId || this.state?.selectedData?.donation?._id
            }
            disabled={this.state.isDisabled}
          />
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showDeleteModal")}
          show={this.state.showDeleteModal}
          title={this.state.modalTitle}
        >
          <DeleteModal
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showDeleteModal"
            )}
            error={this.state.error.delete_password}
            value={this.state.delete_value}
            onDelete={this.onDelete.bind(this)}
            onChange={(e) => this.onChangeText("delete_value", e)}
            isDeleting={this.state.isDeleting}
            name={this.state.selectedData._id}
            getData={this.getData.bind(this)}
          />
        </Modal>
        <CSVLink
          ref="csvDownload"
          filename={"Rejected Products" + new Date().getTime()}
          data={this.state.csvData}
        ></CSVLink>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(RejectedInventories);
