import React from "react";
import { connect } from "react-redux";
import language from "../../../../language";
import { Input, Select } from "../../../Input";
import { Button } from "../../../Button";
import axios from "axios";
import { getStorage } from "../../../../utils/storage";
import toastMessage from "../../../../utils/toastMessage";
import { fetchRejectionStatus } from "../../../../utils/queries/rejectionStatus";
import { fetchAllComponents } from "../../../../utils/queries/production/components";

class NewReturnProduct extends React.Component {
  state = {
    donation: {},
    error: {},
    donationNumber: "",
    components: [],
    reason: "",
    rejectionStatus: [],
  };

  getRejectionStatus = async (isLoading, search = {}) => {
    try {
      this.setState({ isLoading });
      const { data } = await fetchRejectionStatus("dropdown", {
        page: 0,
        limit: 50,
        ...search,
      });

      this.setState({
        isLoading: false,
      });

      return data;
    } catch (error) {
      toastMessage("error", error);
    }
  };

  getComponents = async (isLoading, search = {}) => {
    try {
      this.setState({ isLoading });
      const data = await fetchAllComponents("dropdown", {
        ...search,
        inputType: "dropdown",
      });

      this.setState({
        isLoading: false,
      });

      return data;
    } catch (error) {
      toastMessage("error", error);
    }
  };

  onChangeText(field, e) {
    let { error } = this.state;
    delete error[field];

    this.setState({
      [field]: e.target ? e.target.value : e,
      error,
    });
  }

  validateForm() {
    let { error, donationNumber, product, reason, status } = this.state;

    if (donationNumber === "") {
      error.donationNumber =
        language[this.props.defaultLanguage].donation_number_required;
    }

    if (!product) {
      error.product = language[this.props.defaultLanguage].product_required;
    }

    if (!reason) {
      error.reason = language[this.props.defaultLanguage].reason_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    const { error, donationNumber, product, reason } = this.state;

    await this.validateForm();
    const user = await getStorage();

    if (Object.keys(error).length > 0) return;

    this.setState({ isSubmitting: true });

    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_BBMS_BASE_API}/inventory/manual-return-product`,
      data: {
        donationNumber,
        product: product.code,
        reasonId: reason._id,
        reason: reason.value,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.token,
      },
    };
    axios(options)
      .then((data) => {
        this.setState({
          isSubmitting: false,
        });

        toastMessage(
          "success",
          language[this.props.defaultLanguage].product_reversed_success
        );

        this.resetForm();
      })
      .catch((error) => {
        this.setState({ isSubmitting: false });

        toastMessage("error", error || error.message);
      });
  };

  resetForm() {
    this.setState({
      donationNumber: "",
      product: "",
      status: null,
    });
  }

  render() {
    return (
      <>
        <div className="card">
          <div className="card-body">
            <div className="col-md-6">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].donation_placeholder
                }
                label={language[this.props.defaultLanguage].donation_number}
                required
                value={this.state.donationNumber}
                error={this.state.error.donationNumber}
                onChange={(e) => this.onChangeText("donationNumber", e)}
              />
            </div>
            <div className="col-md-6">
              <Select
                loadOptions={(e) => this.getComponents(true, e)}
                placeholder={
                  language[this.props.defaultLanguage].product_placeholder
                }
                label={language[this.props.defaultLanguage].product}
                required
                value={this.state.product}
                error={this.state.error.product}
                onChange={(e) => this.onChangeText("product", e)}
              />
            </div>
            <div className="col-md-6">
              <Select
                loadOptions={(e) =>
                  this.getRejectionStatus(true, { q: e, type: "RETURN" })
                }
                label={language[this.props.defaultLanguage].reason}
                placeholder={
                  language[this.props.defaultLanguage].reason_placeholder
                }
                value={this.state.reason}
                required
                onChange={(e) => this.onChangeText("reason", e)}
                error={this.state.error.reason}
              />
            </div>
            <div className="col-md-6">
              <Button
                text={language[this.props.defaultLanguage].submit}
                onPress={this.onSubmit.bind(this)}
                isSubmitting={this.state.isSubmitting}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewReturnProduct);
