import React from "react";
import { connect } from "react-redux";
import language from "../../../../language";
import { Table } from "../../../Table";
import {
  fetchInventoryData,
  deleteInventory,
  fetchInventories,
} from "../../../../utils/queries/production/inventoryQuery";
import bloodDonationQuery from "../../../../utils/queries/bloodDonationQuery";
import { DeleteModal, Modal } from "../../../Modal";
import {
  defineRole,
  defineRoleCenterSite,
} from "../../../../utils/handleAccessRoles";
import { getStorage } from "../../../../utils/storage";
import icons from "../../../../constants/icons";
import { BloodBagLabelling } from "../../../Labelling";
import { CSVLink } from "react-csv";
import {
  ROLE_COMPONENT_SEPARATION,
  ROLE_INVENTORY,
} from "../../../../constants/ROLES";
import exportPDF from "../../../../utils/exportPDF";

class InventoriesByReason extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selectedData: {},
    delete_value: "",
    error: {},
    csvData: [],
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });

    this.fetchData(true);
  };

  fetchData(isLoading) {
    this.getData(isLoading);
  }

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e.target ? e?.target?.value : e,
      error,
    });
  }

  returnFilters = async (search) => {
    const { page, limit } = this.state;
    const { reason } = this.props;
    const { centerRole, centerSiteRole } = await defineRoleCenterSite();
    return {
      page,
      limit,
      donor: this.props.donorId,
      donation: this.props.donationId,
      id: this.props.inventoryId,
      center: centerRole,
      centerSite: centerSiteRole,
      ...search,
      reason,
    };
  };

  getData = async (isLoading, search = {}) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const filters = await this.returnFilters(search);

      if (search?.donationNumber) {
        const donation = await bloodDonationQuery(defaultLanguage, {
          ...search,
        });

        if (donation?.length > 0) {
          filters.donation = donation[0]?._id || undefined;
        } else {
          return;
        }
      }

      const { data, count } = await fetchInventories(defaultLanguage, filters);

      this.setState({
        data: data,
        totalPageCount: count,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleShowModal(modal, modalTitle, selectedData = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selectedData,
      reason: selectedData.reason || "",
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].donation,
        key: "donation.donationNumber",
        // type: "link",
        // onPress: (item) =>
        //   (window.location.href = `/dashboard/donations/${item.donation._id}/${item.donation.donor._id}/${item.donation.donor.firstName} ${item.donation.donor.lastName}`),
      },
      {
        title: language[this.props.defaultLanguage].code,
        key: "code",
      },
      {
        title: language[this.props.defaultLanguage].name,
        key: "name",
      },
      {
        title: language[this.props.defaultLanguage].blood_group,
        key: "bloodGroup",
      },
      {
        title: language[this.props.defaultLanguage].volume,
        key: "volume",
      },
      {
        title: language[this.props.defaultLanguage].status,
        key: "status",
        type: "status",
      },
      {
        title: language[this.props.defaultLanguage].reason,
        key: "reason",
        type: "reason",
      },
      {
        title: language[this.props.defaultLanguage].source,
        key: "sourceStock.name",
      },
      {
        title: language[this.props.defaultLanguage].destination,
        key: "stock.name",
      },
    ];

    return headers;
  }

  onDelete = async () => {
    try {
      this.setState({ isDeleting: true });

      await deleteInventory(
        this.props.defaultLanguage,
        this.state.selectedData._id
      );

      this.setState({ isDeleting: false });

      this.getData(true);
      this.handleCloseModal("showDeleteModal");
    } catch (error) {
      this.setState({
        isDeleting: false,
      });
    }
  };

  downloadExcel = async () => {
    try {
      this.setState({
        isLoading: true,
      });

      const { defaultLanguage } = this.props;

      const filters = await this.returnFilters();

      const { data } = await fetchInventoryData(defaultLanguage, filters);

      this.setState({
        isLoading: false,
        csvData: data,
      });

      this.refs.csvDownload?.link.click();
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  downloadPDF = async () => {
    this.setState({
      isLoading: true,
    });

    const { defaultLanguage } = this.props;

    const filters = await this.returnFilters();

    const { data } = await fetchInventories(defaultLanguage, filters);

    this.setState({
      isLoading: false,
    });

    exportPDF(
      "Inventories-" + this.props.reason,
      this.returnTableHeaders(),
      data
    );
  };

  handleRemoteSearch = async (search) => {
    this.setState({
      searching: true,
    });
    await this.getData(true, {
      donationNumber: search || search !== "" ? search : undefined,
    });

    this.setState({
      searching: false,
    });
  };

  render() {
    return (
      <>
        <Table
          data={this.state.data}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          searching={this.state.searching}
          handleSearch={(e) => this.onChangeText("search_text", e)}
          handlePressSearch={() =>
            this.handleRemoteSearch(this.state.search_text)
          }
          addSearchButton
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
          actions={[
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: "ProductionInventory",
                operation: "update",
              }),
              name: language[this.props.defaultLanguage].reception,
              icon: icons.edit,
              onPress: (item) =>
                this.handleShowModal(
                  "showModal",
                  language[this.props.defaultLanguage].reception,
                  item
                ),
            },
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_INVENTORY + "," + ROLE_COMPONENT_SEPARATION,
                operation: "read",
              }),
              name: language[this.props.defaultLanguage].separation,
              icon: icons.separation,
              onPress: (item) =>
                (window.location.href = `/dashboard/production/view-inventory/${item._id}/${item.donation._id}`),
            },
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_INVENTORY + "," + ROLE_COMPONENT_SEPARATION,
                operation: "read",
              }),
              name: language[this.props.defaultLanguage].transformation,
              icon: icons.transformation,
              onPress: (item) =>
                (window.location.href = `/dashboard/production/view-inventory/${item._id}/${item.donation._id}`),
            },
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: "ProductionLabelling",
                operation: "read",
              }),
              name: language[this.props.defaultLanguage].labeling,
              icon: icons.labeling,
              onPress: (item) =>
                this.handleShowModal(
                  "showBarcodeModal",
                  language[this.props.defaultLanguage].labeling,
                  {
                    ...item,
                    isBarcode: true,
                  }
                ),
            },
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: "ProductionInventory",
                operation: "delete",
              }),
              name: language[this.props.defaultLanguage].delete,
              onPress: this.handleShowModal.bind(
                this,
                "showDeleteModal",
                language[this.props.defaultLanguage].delete
              ),
              icon: icons.delete,
            },
          ]}
        />

        <CSVLink
          ref="csvDownload"
          filename={"Inventories" + "-" + this.props.status}
          data={this.state.csvData}
        ></CSVLink>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(InventoriesByReason);
