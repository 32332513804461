import React from "react";
import { connect } from "react-redux";
import language from "../../../language";
import { withRouter } from "../../../utils/withRouter";
import { Breadcrumb } from "../../../components/Breadcrumb";
import { Tabs } from "../../../components/Tabs";
import { getStorage } from "../../../utils/storage";
import { defineRole } from "../../../utils/handleAccessRoles";
import {
  ROLE_BLOOD_TEST_BLOOD_GROUPING,
  ROLE_BLOOD_TEST_CONFIRMATION,
  ROLE_BLOOD_TEST_SEROLOGY,
} from "../../../constants/ROLES";
import Confirmation from "../../../components/BloodTesting/confirmation";
import BloodGrouping from "../../../components/BloodTesting/BloodGrouping/BloodGrouping";
import Serology from "../../../components/BloodTesting/Serology/Serology";

class TestingQuestionnaireScreen extends React.Component {
  state = {
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user: user });
  };

  render() {
    const canAccessReadBloodTest_grouping = defineRole({
      roles: this.state.user.accessRole,
      menu: ROLE_BLOOD_TEST_BLOOD_GROUPING,
      operation: "read",
    });

    const canAccessReadBloodTest_serology = defineRole({
      roles: this.state.user.accessRole,
      menu: ROLE_BLOOD_TEST_SEROLOGY,
      operation: "read",
    });

    let tabsOptions = [];

    if (canAccessReadBloodTest_grouping) {
      tabsOptions.push({
        title: language[this.props.defaultLanguage].blood_grouping,
        data: <BloodGrouping {...this.props.routeParams} />,
      });
    }

    if (canAccessReadBloodTest_serology) {
      tabsOptions.push({
        title: language[this.props.defaultLanguage].serology,
        data: <Serology {...this.props.routeParams} />,
      });
    }
    return (
      <div>
        <div className="card-dashboard-content">
          <div className="card-body">
            <Breadcrumb
              homeUrl="/dashboard/home"
              data={[
                {
                  name: language[this.props.defaultLanguage].donors,
                  url: "/dashboard/donors",
                },
                {
                  name: language[this.props.defaultLanguage].collections,
                  url: `/dashboard/phlebotomy`,
                },
                {
                  name: this.props.routeParams.donationNumber,
                  active: true,
                },
              ]}
            />

            <Tabs options={tabsOptions} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(withRouter(TestingQuestionnaireScreen));
