import React from "react";
import { connect } from "react-redux";
import language from "../../language";
import { Table } from "../Table";
import { Modal, DeleteModal } from "../Modal";
import { CSVLink } from "react-csv";
import hospitalQuery, {
  deleteHospital,
} from "../../utils/queries/hospitalQuery";
import exportPDF from "../../utils/exportPDF";
import handleSearch from "../../utils/handleSearch";
import NewHospital from "./NewHospital";
import { defineRole } from "../../utils/handleAccessRoles";
import { getStorage } from "../../utils/storage";
import { ROLE_HOSPITAL } from "../../constants/ROLES";

let copyData = [];

class Hospitals extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selected_data: {},
    delete_value: "",
    error: {},
    csvData: [],
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });

    await this.getData(true);
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e?.target?.value,
      error,
    });
  }

  getData = async (isLoading) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const { data, count } = await hospitalQuery(defaultLanguage, {
        page,
        limit,
      });

      this.setState({
        data: data,
        totalPageCount: count,
        isLoading: false,
      });

      copyData = data.slice(0);
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleShowModal(modal, modalTitle, selected_data = {}) {
    console.log("modal open object", {
      modal,
      modalTitle,
      selected_data,
    });
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selected_data,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].name,
        key: "name",
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].province,
        key: "address.province",
      },
      {
        title: language[this.props.defaultLanguage].district,
        key: "address.district",
      },
      {
        title: language[this.props.defaultLanguage].sector,
        key: "address.sector",
      },
      {
        title: language[this.props.defaultLanguage].cell,
        key: "address.cell",
      },
      {
        title: language[this.props.defaultLanguage].village,
        key: "address.village",
      },
      defineRole({
        roles: this.state.user.accessRole,
        menu: ROLE_HOSPITAL,
        operation: "update",
      }) && {
        title: language[this.props.defaultLanguage].actions,
        key: "action",
      },
    ].filter(Boolean);

    return headers;
  }

  onDelete = async () => {
    try {
      this.setState({ isDeleting: true });

      await deleteHospital(
        this.props.defaultLanguage,
        this.state.selected_data._id
      );

      this.setState({ isDeleting: false });

      this.getData(true);
      this.handleCloseModal("showDeleteModal");
    } catch (error) {
      this.setState({
        isDeleting: false,
      });
    }
  };

  downloadExcel = async () => {
    try {
      this.setState({
        isLoading: true,
      });

      const { page, limit } = this.state;
      const { defaultLanguage } = this.props;

      const { data } = await hospitalQuery(defaultLanguage, {
        page,
        limit,
      });

      this.setState({
        isLoading: false,
        csvData: data,
      });

      this.refs.csvDownload?.link.click();
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  downloadPDF = async () => {
    this.setState({
      isLoading: true,
    });

    const { page, limit } = this.state;
    const { defaultLanguage } = this.props;

    const headers = this.returnTableHeaders();

    const { data } = await hospitalQuery(defaultLanguage, {
      page,
      limit,
    });

    this.setState({
      isLoading: false,
    });

    exportPDF("Hospitals", headers, data);
  };

  render() {
    const {
      add_hospital,
      edit_hospital,
      delete_hospital,
      hospital,
      export_,
      edit,
      delete_,
    } = language[this.props.defaultLanguage];

    return (
      <>
        <Table
          data={this.state.data}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          handleSearch={(e) =>
            handleSearch(this, {
              copyData,
              value: e?.target?.value,
            })
          }
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
          showAdd={defineRole({
            roles: this.state.user.accessRole,
            menu: ROLE_HOSPITAL,
            operation: "create",
          })}
          addButtonText={language[this.props.defaultLanguage].add}
          handleAddPressed={this.handleShowModal.bind(
            this,
            "showModal",
            language[this.props.defaultLanguage].add
          )}
          rowPress={(item) =>
            this.handleShowModal("showModal", item.name, item)
          }
          actions={[
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_HOSPITAL,
                operation: "update",
              }),
              name: edit,
              onPress: (item) =>
                this.handleShowModal(
                  "showModal",
                  language[this.props.defaultLanguage].edit,
                  item
                ),
            },
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_HOSPITAL,
                operation: "delete",
              }),
              name: delete_,
              onPress: this.handleShowModal.bind(
                this,
                "showDeleteModal",
                delete_hospital
              ),
            },
          ]}
        />
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showModal")}
          show={this.state.showModal}
          title={this.state.modalTitle}
          showHeaderBottomBorder={false}
        >
          <NewHospital
            handleCloseModal={this.handleCloseModal.bind(this, "showModal")}
            getData={this.getData.bind(this)}
            {...this.state.selected_data}
          />
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showDeleteModal")}
          show={this.state.showDeleteModal}
          title={this.state.modalTitle}
        >
          <DeleteModal
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showDeleteModal"
            )}
            error={this.state.error.delete_password}
            value={this.state.delete_value}
            onDelete={this.onDelete.bind(this)}
            onChange={(e) => this.onChangeText("delete_value", e)}
            isDeleting={this.state.isDeleting}
            name={this.state.selected_data.name}
            getData={this.getData.bind(this)}
          />
        </Modal>
        <CSVLink
          ref="csvDownload"
          filename={"hospitals" + new Date().getTime()}
          data={this.state.csvData}
        ></CSVLink>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Hospitals);
