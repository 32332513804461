import React from "react";
import { connect } from "react-redux";
import { Button } from "../../Button";
import language from "../../../language";
import toastMessage from "../../../utils/toastMessage";
import { fetchComponentTransformationData } from "../../../utils/queries/production/componentTransformationQuery";

import { Loading } from "../../Loader";
import { Modal } from "../../Modal";
import PrintableLabel from "../ComponentLabeling/printableLabel";
import axios from "axios";
import { getStorage } from "../../../utils/storage";

class NewLabel extends React.Component {
  state = {
    code: "",
    error: {},
    isLoading: false,
    separation: [],
    transformation: [],
    donation: {},
    serology: {},
    bloodGrouping: {},
    loadingMessage: "",
    isGenerated: false,
    selectedItem: {},
  };

  componentDidMount = async () => {
    this.getComponentTransformation(true);
  };

  getComponentTransformation = async () => {
    try {
      if (!this.props.donationId)
        return toastMessage("error", "Please select a donation");

      this.setState({
        isLoading: true,
        loadingMessage: "Loading component transformation...",
      });

      console.log(this.props.donationId);

      const data = await fetchComponentTransformationData(
        this.props.defaultLanguage,
        {
          donation: this.props.donationId,
        }
      );

      if (data.length > 0) {
        this.setState({
          transformation: data,
        });
      }
      this.setState({
        isLoading: false,
      });
    } catch (error) {
      toastMessage("error", error);
      this.setState({
        isLoading: false,
      });
    }
  };

  validateForm = () => {
    let { error, donation } = this.state;

    if (!donation?._id) {
      error.donation = "Donation is required";
    }

    this.setState({ error });
  };

  onGenerateLabel = async (data) => {
    const user = await getStorage();

    this.setState({
      ["isSubmitting_" + data.code]: true,
    });

    const { component, donation, center, centerSite, name, volume, code } =
      data;

    let requestedBody = {
      donation: donation._id,
      addedBy: user.id,
      component: component?._id,
      name,
      code,
      volume: volume,
      center: center?._id,
      centerSite: centerSite?._id,
      status: "pending",
      bloodGroup: donation.bloodGroup,
      checkDonation: true,
      productId: data._id,
    };

    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_BBMS_BASE_API}/inventory`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.token,
      },
      data: requestedBody,
    };
    axios(options)
      .then((data) => {
        toastMessage(
          "success",
          `Label for ${data.data.code} was generated successfully`
        );

        this.handleShowModal("showLabelModal", data.data.code, data.data);

        this.setState({
          ["isSubmitting_" + data.data.code]: false,
        });
      })
      .catch((error) => {
        this.setState({ ["isSubmitting_" + data.code]: false });

        toastMessage("error", error);

        return;
      });
  };

  renderItem = (item, type) => {
    return (
      <div style={{ display: "flex", alignItems: "center", minHeight: 50 }}>
        <div style={{ flex: 1, marginRight: "1rem" }}>
          <span>{item.code}-</span>
          <span>{item.name}</span>
        </div>
        <Button
          text={language[this.props.defaultLanguage].generate_label}
          onPress={() => this.onGenerateLabel(item)}
          isSubmitting={this.state["isSubmitting_" + item.code]}
        />
      </div>
    );
  };

  handleShowModal(model, modalTitle, selectedItem) {
    this.setState({
      [model]: true,
      selectedItem,
      modalTitle,
    });
  }

  handleCloseModal(model) {
    this.setState({ [model]: false });
  }

  render() {
    console.log(this.state.selectedItem);
    return (
      <>
        <div>
          <>
            <div className="card-body">
              {this.state.isLoading ? (
                <center>
                  <Loading isFullScreen />
                  <p>{this.state.loadingMessage}</p>
                </center>
              ) : (
                <div>
                  {Object.keys(this.state.error).map((key, i) => {
                    return (
                      <div
                        className="alert alert-danger"
                        style={{ marginBottom: "1rem" }}
                      >
                        {this.state.error[key]}
                      </div>
                    );
                  })}

                  <div className="card-body">
                    <p className="text-bold">
                      {this.state.transformation.length > 0 &&
                        language[this.props.defaultLanguage]
                          .component_transformation}
                    </p>
                    <br />
                    {this.state.transformation.map((el, i) => {
                      return (
                        <div key={i}>
                          {this.renderItem(el, "transformation")}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
            <Modal
              handleClose={this.handleCloseModal.bind(this, "showLabelModal")}
              show={this.state.showLabelModal}
              showHeaderBottomBorder={false}
              title={this.state?.selectedItem?.code}
              size="lg"
            >
              <div style={{ minHeight: "60vh" }}>
                <PrintableLabel
                  handleCloseModal={this.handleCloseModal.bind(
                    this,
                    "showLabelModal"
                  )}
                  productId={this.state?.selectedItem?._id}
                />
              </div>
            </Modal>
          </>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewLabel);
