import toastMessage from "../toastMessage";
import formatSelectData from "../formatSelectData";
import { getStorage } from "../storage";
import axios from "axios";
import language from "../../language";
import { defineRole } from "../handleAccessRoles";
import { ROLE_DONORS } from "../../constants/ROLES";
import api from "../api";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

const fetchData = async (lng = "english", params) => {
  const { page, limit, type } = params;
  try {
    const user = await getStorage();

    const canAccess = defineRole({
      roles: user.accessRole,
      menu: ROLE_DONORS,
      operation: "read,readOnAction",
      pass: user.role === "donor" || user.account_type === "donor",
    });

    if (!canAccess) return;

    let filters = {
      ...params,
    };

    delete filters.type;

    if (!filters["blockInfo.blocked"]) {
      filters["blockInfo.blocked"] = false;
    }

    if (filters.id || filters.donorNumber || filters.q) {
      delete filters["blockInfo.blocked"];
      delete filters["startDate"];
      delete filters["endDate"];
    }

    const options = {
      method: "GET",
      url: `${API_URL}/donor`,
      params: {
        ...filters,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await api(options);

    let { data, count } = queryRes.data;

    if (type === "dropdown") {
      return formatSelectData(queryRes.data, "donorNumber", "_id");
      // ["donorNumber", "firstName", "lastName"]
    }

    return !page && !limit ? queryRes.data : { data, count };
  } catch (error) {
    toastMessage("error", error);
  }
};

export default fetchData;

export const deleteDonors = async (lng = "english", id) => {
  try {
    const user = await getStorage();

    const canAccess = defineRole({
      roles: user.accessRole,
      menu: ROLE_DONORS,
      operation: "delete",
    });

    if (!canAccess) return;

    const options = {
      method: "DELETE",
      url: `${API_URL}/donor`,
      data: {
        id,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    await axios(options);

    toastMessage("success", language[lng].success_delete_donors);
  } catch (error) {
    toastMessage("error", error);
  }
};

export const countDonorsPerCategories = async (
  lng = "english",
  params = {}
) => {
  try {
    const user = await getStorage();

    const canAccess = defineRole({
      roles: user.accessRole,
      menu: ROLE_DONORS,
      operation: "read",
    });

    if (!canAccess) return;

    const options = {
      method: "GET",
      url: `${API_URL}/donor/countDonorsCategories`,
      params,
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const data = await axios(options);
    return data.data || 0;
  } catch (error) {
    toastMessage("error", error);
  }
};
