import toastMessage from "../../toastMessage";
import { getStorage } from "../../storage";
import api from "../../api";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

export const fetchDonationWithWrongVitalSign = async (
  lng = "english",
  params
) => {
  try {
    const user = await getStorage();

    const options = {
      method: "GET",
      url: `${API_URL}/bloodDonation/wrong-vitalsign`,
      params,
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await api(options);

    return queryRes.data;
  } catch (error) {
    toastMessage("error", error);
  }
};

export const correctDonationWithWrongVitalSign = async () => {
  try {
    const user = await getStorage();

    const options = {
      method: "POST",
      url: `${API_URL}/bloodDonation/correct-wrong-vitalsign`,
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const data = await api(options);

    toastMessage("success", `${data.data.count} data corrected `);
  } catch (error) {
    toastMessage("error", error);
  }
};

export const handleDonationSyncInformation = async (params) => {
  try {
    const user = await getStorage();

    const options = {
      method: "POST",
      url: `${API_URL}/bloodDonation/sync-donation`,
      data: {
        ...params,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const data = await api(options);

    toastMessage("success", data.data.message);
  } catch (error) {
    toastMessage("error", error);
  }
};
