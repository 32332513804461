import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../Input";
import { Button } from "../Button";
import language from "../../language";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import bloodCenterQuery from "../../utils/queries/bloodCenterQuery";
import bloodCenterSiteQuery from "../../utils/queries/bloodCenterSiteQuery";
import icons from "../../constants/icons";
import { getStorage, setStorage } from "../../utils/storage";
// import { donorNumber } from "../../utils/uniqueID";
import calculateAge from "../../utils/calculateAge";
import validatePassword from "../../utils/validatePassword";
import documentIDNumberTypes, {
  nationalities,
} from "../../constants/documentIDNumberTypes";
import CheckboxQuestionnaire from "../Input/CheckboxQuestionnaire";
import { fetchNIDAInfo } from "../../utils/queries/nidaQuery";
import moment from "moment";
import validateEmail from "../../utils/validateEmail";
import { defineRoleCenterSite } from "../../utils/handleAccessRoles";
import { generateStrongPassword } from "../../utils/randomPassword";
import { Separator } from "../Separator";
import {
  getCells,
  getDistricts,
  getProvinces,
  getSectors,
  getVillages,
} from "../../utils/rwanda";
import bloodGroups from "../../constants/bloodGroups";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

class NewDonor extends React.Component {
  state = {
    email: "",
    firstName: "",
    lastName: "",
    dateOfBirth: null,
    phoneNumber: "",
    error: {},
    isSubmitting: false,
    centers: [],
    accessRoles: [],
    roles: [],
    showAccessRoles: false,
    password: "",
    centerSites: [],
    donorNumber: "",
    profileData: {},
    placeOfBirth: "",
    password: "",
    category: "repeatDonor",
  };

  componentDidMount = async () => {
    this.setState({
      // donorNumber: donorNumber(8),
      password: generateStrongPassword(16),
    });

    const { dateOfBirth } = await getStorage("pre-assessment");

    this.setState({ dateOfBirth });

    this.getCenters();

    if (this.props._id) {
      this.setState({
        ...this.props,
        lastDonationDate: this.props.lastDonationDate
          ? moment(this.props.lastDonationDate).format("YYYY-MM-DD")
          : null,
        identityDocNumberType: documentIDNumberTypes(
          this.props.defaultLanguage
        ).find((el) => el.value === this.props.identityDocNumberType),
        center: {
          label: this.props?.center?.name,
          value: this.props?.center?._id,
        },
        centerSite: {
          label: this.props?.centerSite?.name,
          value: this.props?.centerSite?._id,
        },
        nationality: this.props.nationality
          ? {
              label: this.props.nationality,
              value: this.props.nationality,
              disabled: true,
            }
          : null,
        lastDonationSite: this.props.lastDonationSite
          ? {
              label: this.props.lastDonationSite?.name,
              value: this.props.lastDonationSite?._id,
            }
          : null,
        province: this.props?.province
          ? {
              label: this.props?.province,
              value: this.props?.province,
            }
          : null,
        district: this.props?.district
          ? {
              label: this.props?.district,
              value: this.props?.district,
            }
          : null,
        sector: this.props?.sector
          ? {
              label: this.props?.sector,
              value: this.props?.sector,
            }
          : null,
        cell: this.props?.cell
          ? {
              label: this.props?.cell,
              value: this.props?.cell,
            }
          : null,
        village: this.props?.village
          ? {
              label: this.props?.village,
              value: this.props?.village,
            }
          : null,
      });
    }
  };

  getCenters = async (q) => {
    try {
      this.setState({
        isFetchingCenter: true,
      });

      const data = await bloodCenterQuery(this.props.defaultLanguage, {
        type: "dropdown",
        page: 1,
        limit: 50,
        q: q !== "" ? q : undefined,
      });

      this.setSelectedCenter(data);

      let center = null;
      const { centerRole } = await defineRoleCenterSite();
      if (!this.props._id && centerRole) {
        data.forEach((el) => {
          if (centerRole.includes(el._id)) {
            center = el;
          }
        });

        this.setState({ center }, () =>
          this.getCenterSites({ center: center?._id || undefined })
        );
      }

      this.setState({
        centers: data,
        isFetchingCenter: false,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingCenter: false });

      return [];
    }
  };

  getCenterSites = async (search = {}) => {
    if (!search.center) return [];

    try {
      this.setState({
        isFetchingCenterSite: true,
      });

      const data = await bloodCenterSiteQuery(this.props.defaultLanguage, {
        type: "dropdown",
        page: 1,
        limit: 50,
        ...search,
      });

      this.setSelectedCenterSites(data);

      const { centerSiteRole } = await defineRoleCenterSite();
      let centerSite = null;

      if (!this.props._id && centerSiteRole) {
        data.forEach((el) => {
          if (centerSiteRole.includes(el._id)) {
            centerSite = el;
          }
        });

        this.setState({ centerSite });
      }

      this.setState({
        centerSites: data,
        isFetchingCenterSite: false,
      });

      return data;
    } catch (error) {
      console.log(error);
      this.setState({ isFetchingCenterSite: false });

      return [];
    }
  };

  getLastDonationCenterSites = async (search = {}) => {
    try {
      this.setState({
        isFetchingLastDonationCenterSite: true,
      });

      const data = await bloodCenterSiteQuery(this.props.defaultLanguage, {
        type: "dropdown",
        page: 1,
        limit: 50,
        ...search,
      });

      this.setState({
        lastDonationSites: data,
        isFetchingLastDonationCenterSite: false,
      });

      return data;
    } catch (error) {
      console.log(error);
      this.setState({ isFetchingLastDonationCenterSite: false });

      return [];
    }
  };

  setSelectedCenter(centers) {
    const { center } = this.props;
    if (center) {
      const selectedCenter = centers.find(
        (el) => el.value === (center._id || center)
      );

      if (selectedCenter?._id) {
        this.setState(
          {
            center: {
              label: selectedCenter.name,
              value: selectedCenter._id,
            },
          },
          () => {
            this.getCenterSites({ center: selectedCenter._id });
          }
        );
      }
    }
  }

  setSelectedRole(roles) {
    const { accessRole } = this.props;
    if (accessRole) {
      const selectedRole = roles.find(
        (el) => el._id === accessRole._id || accessRole
      );

      if (selectedRole._id) {
        this.setState({
          accessRole: {
            label: selectedRole.name,
            value: selectedRole._id,
          },
        });
      }
    }
  }

  setSelectedCenterSites(centerSites) {
    const { centerSite } = this.props;
    if (centerSite) {
      const selectedData = centerSites.find(
        (el) => el._id === (centerSite?._id || centerSite)
      );

      if (selectedData?._id) {
        this.setState({
          centerSite: {
            label: selectedData.name,
            value: selectedData._id,
          },
        });
      }
    }
  }

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e.target ? e?.target?.value : e;

    delete error[field];

    if (
      field === "password" &&
      validatePassword(this.props.defaultLanguage, inputValue)
    ) {
      error[field] = validatePassword(this.props.defaultLanguage, inputValue);
    }

    await this.setState({
      [field]: inputValue,
      error,
    });

    if (field === "center") {
      this.getCenterSites({ center: inputValue.value });
    }

    if (field === "identityDocNumber") {
      this.setState({ profileData: {} });
    }

    if (field === "identityDocNumberType") {
      if (e.value.toLowerCase() !== "passport") {
        this.setState({
          nationality: {
            label: e.nationality,
            value: e.nationality,
            disabled: true,
          },
        });

        return;
      }

      this.setState({
        nationality: null,
      });
    }

    // if (
    //   field === "identityDocNumber" &&
    //   this.state.identityDocNumberType.search === "NIDA" &&
    //   this.state.identityDocNumberType.value === "Rwanda National Id" &&
    //   inputValue.length === 16
    // ) {
    //   this.handleFetchNIDAInfo();
    // }
  };

  handleCheck(field, value) {
    let { error } = this.state;

    delete error[field];

    this.setState({ [field]: value, error });
  }

  validateForm() {
    let {
      gender,
      error,
      donorNumber,
      firstName,
      lastName,
      dateOfBirth,
      password,
      _id,
      center,
      centerSite,
      identityDocNumber,
      identityDocNumberType,
      email,
      nationality,
      category,
    } = this.state;

    if (!identityDocNumberType) {
      error.identityDocNumberType =
        language[this.props.defaultLanguage].identityDocNumberType_required;
    }

    if (!gender || gender === "") {
      error.gender = language[this.props.defaultLanguage].gender_required;
    }

    if (category === "repeatDonor" && donorNumber === "") {
      error.donorNumber =
        language[this.props.defaultLanguage].donor_number_required;
    }

    if (identityDocNumberType?.value === "Rwanda National Id") {
      if (identityDocNumber === "" || !identityDocNumber) {
        error.identityDocNumberType =
          language[
            this.props.defaultLanguage
          ].rwanda_national_id_number_required;
      } else if (identityDocNumber.length > 16) {
        error.identityDocNumberType =
          language[
            this.props.defaultLanguage
          ].invalid_rwanda_national_id_number;
      }
    }

    if (identityDocNumberType?.value === "Foreigner") {
      if (identityDocNumber === "" || !identityDocNumber) {
        error.identityDocNumberType =
          language[this.props.defaultLanguage].passport_number_required;
      }
    }

    // if (phoneNumber === "") {
    //   error.phoneNumber =
    //     language[this.props.defaultLanguage].phone_number_required;
    // }
    //  else if (!validatePhone(phoneNumber)) {
    //   error.phoneNumber =
    //     language[this.props.defaultLanguage].phone_number_invalid;
    // }

    if (!center) {
      error.center = language[this.props.defaultLanguage].center_required;
    }

    if (!centerSite) {
      error.centerSite =
        language[this.props.defaultLanguage].center_site_required;
    }

    if (firstName === "") {
      error.firstName =
        language[this.props.defaultLanguage].first_name_required;
    }

    if (!nationality) {
      error.nationality =
        language[this.props.defaultLanguage].nationality_required;
    }

    if (lastName === "") {
      error.lastName = language[this.props.defaultLanguage].last_name_required;
    }

    if (email !== "" && !validateEmail(email)) {
      error.email = language[this.props.defaultLanguage].invalid_email;
    }

    const age = calculateAge(dateOfBirth);

    if (!dateOfBirth) {
      error.dateOfBirth =
        language[this.props.defaultLanguage].date_birth_required;
    } else if (age < 17) {
      error.dateOfBirth = language[this.props.defaultLanguage].below_year_old;
    } else if (age > (category === "repeatDonor" ? 65 : 60)) {
      error.dateOfBirth = language[this.props.defaultLanguage].above_year_old;
    }

    if (!_id || _id === "") {
      if (password === "") {
        error.password = language[this.props.defaultLanguage].password_required;
      } else if (validatePassword(this.props.defaultLanguage, password)) {
        error.password = `${validatePassword(
          this.props.defaultLanguage,
          password
        )} `;
      }
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let {
          firstName,
          lastName,
          phoneNumber,
          email,
          donorNumber,
          _id,
          password,
          lastDonationDate,
          howManyTimes,
          lastDonationSite,
          dateOfBirth,
          placeOfBirth,
          center,
          centerSite,
          identityDocNumber,
          identityDocNumberType,
          gender,
          imageUrl,
          nationality,
          village,
          sector,
          cell,
          province,
          district,
          category,
          bloodGroup,
        } = this.state,
        url = `${API_URL}/donor`,
        method = "POST",
        user = await getStorage();

      let requestedBody = {
        email,
        firstName,
        lastName,
        phoneNumber,
        password,
        donorNumber,
        dateOfBirth,
        placeOfBirth,
        imageUrl,
        center: center.value,
        centerSite: centerSite?.value || undefined,
        identityDocNumber,
        nationality: nationality?.value,
        identityDocNumberType: identityDocNumberType.value,
        lastDonationSite: lastDonationSite?.value || lastDonationSite,
        donationCount: howManyTimes,
        isPreviousDonate: howManyTimes > 0,
        gender,
        lastDonationDate,
        province: province.value,
        district: district.value,
        sector: sector.value,
        cell: cell.value,
        village: village.value,
        category,
      };

      if (category === "repeatDonor") {
        requestedBody.bloodGroup = bloodGroup?.value;
      }

      if (_id && _id !== "") {
        method = "PUT";
        requestedBody.id = _id;
      }

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
        data: requestedBody,
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            language[this.props.defaultLanguage][
              method === "PUT" ? "update_donor_success" : "add_donor_success"
            ]
          );

          if (this.props.handleCloseModal && this.props.getData) {
            this.props.getData(true);
            this.props.handleCloseModal();

            // window.location.href = `${this.props.route}/donors/${
            //   data.data._id
            // }/${data.data.firstName + " " + data.data.lastName}`;

            return;
          }

          this.onLogin();
        })
        .catch((error) => {
          this.setState({ isSubmitting: false });

          toastMessage("error", error);
        });
    }
  };

  onLogin = async () => {
    const { email, password } = this.state;

    const options = {
      method: "POST",
      url: `${API_URL}/authentication/donor`,
      data: {
        email,
        password,
      },
    };

    axios(options)
      .then((data) => {
        this.setState({ isSubmitting: false });

        if (data.data.status === "error")
          return toastMessage("error", data.data.message);

        let userInfo = {
          ...data.data,
          token: data?.data?.token,
          email,
          role: data.data.role,
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          dateOfBirth: this.state.dateOfBirth,
        };

        if (data.data?.id) {
          userInfo.id = data.data?.id;
        }

        userInfo.displayName = this.state.firstName + " " + this.state.lastName;

        this.onLoginSuccess(userInfo);
      })
      .catch((error) => {
        console.log("error login", error);
        this.setState({ isSubmitting: false });

        toastMessage(
          "error",
          language[this.props.defaultLanguage].wrong_email_password
        );
      });
  };

  onLoginSuccess = async (data) => {
    await setStorage(data);

    this.setState({ isSubmitting: false });

    window.location.href = "/user/home";
  };

  handleFetchNIDAInfo = async () => {
    try {
      const { identityDocNumberType, identityDocNumber, error } = this.state;

      if (identityDocNumberType?.value === "Rwanda National Id") {
        if (identityDocNumber === "" || !identityDocNumber) {
          error.identityDocNumber =
            language[this.props.defaultLanguage].identity_number_required;
        } else if (identityDocNumber.length > 16) {
          error.identityDocNumber =
            language[this.props.defaultLanguage].invalid_national_id_number;
        }

        if (error.identityDocNumber) {
          return this.setState({
            error,
          });
        }
      }

      this.setState({
        isFetchingNIDAInfo: true,
      });

      if (!error.identityDocNumber) {
        const data = await fetchNIDAInfo(this.props.defaultLanguage, {
          documentNumber: identityDocNumber,
        });

        this.setState({ isFetchingNIDAInfo: false });

        if (!data.documentNumber) {
          return toastMessage("error", data.message);
        }

        let gender = data.sex === "M" ? "male" : "female";
        var momentObj = moment(data.dateOfBirth, "DD/MM/YYYY");

        this.setState({
          dateOfBirth: moment(momentObj).format("YYYY-MM-DD"),
          placeOfBirth: data.placeOfBirth,
          imageUrl: data.photo,
          firstName: data.surnames,
          lastName: data.foreName,
          profileData: {
            documentNumber: data.documentNumber,
            name: `${data.foreName} ${data.surnames}`,
            sex: data.sex,
            dateOfBirth: moment(momentObj).format("YYYY-MM-DD"),
          },
          gender,
        });
      }
    } catch (error) {
      this.setState({
        isFetchingNIDAInfo: false,
      });

      toastMessage("error", error);
    }
  };

  render() {
    const mockData = [
      { value: "site1", label: "Site 1" },
      { value: "site2", label: "Site 2" },
    ];
    return (
      <div>
        <div className="card-body">
          <CheckboxQuestionnaire
            required
            questionText={language[this.props.defaultLanguage].category}
            answers={[
              {
                key: "newDonor",
                value: language[this.props.defaultLanguage].new_donor,
                onPress: () => this.handleCheck("category", "newDonor"),
              },
              {
                key: "repeatDonor",
                value: language[this.props.defaultLanguage].repeat_donor,
                onPress: () => this.handleCheck("category", "repeatDonor"),
              },
            ]}
            checked={this.state.category}
            error={this.state.error.category}
          />
          <hr />
          {this.state.imageUrl && (
            <center style={{ paddingBottom: "1.5rem" }}>
              <div className="donor-profile-image-container">
                <img src={`data:image/jpeg;base64,${this.state.imageUrl}`} />
              </div>
            </center>
          )}
          <div className="row">
            {this.state.category === "repeatDonor" && (
              <div className="col-md-12">
                <Input
                  placeholder={
                    language[this.props.defaultLanguage]
                      .donor_number_placeholder
                  }
                  label={language[this.props.defaultLanguage].donor_number}
                  required
                  value={this.state.donorNumber}
                  onChange={(e) => this.onChangeText("donorNumber", e)}
                  error={this.state.error.donorNumber}
                  // disabled
                />
              </div>
            )}
            <div className="col-md-12">
              <Select
                options={documentIDNumberTypes(this.props.defaultLanguage)}
                leftIcon={icons.country}
                placeholder={language[this.props.defaultLanguage].select}
                label={
                  language[this.props.defaultLanguage].identityDocNumberType
                }
                required
                value={this.state.identityDocNumberType}
                onChange={(e) => this.onChangeText("identityDocNumberType", e)}
                error={this.state.error.identityDocNumberType}
              />
            </div>
          </div>

          {this.state.identityDocNumberType &&
            this.state.identityDocNumberType.requiredInput && (
              <div className="row">
                <div className="col-md-12">
                  <Select
                    options={nationalities(this.props.defaultLanguage)}
                    leftIcon={icons.nationality}
                    placeholder={language[this.props.defaultLanguage].select}
                    label={language[this.props.defaultLanguage].nationality}
                    required
                    value={this.state.nationality}
                    onChange={(e) => this.onChangeText("nationality", e)}
                    error={this.state.error.nationality}
                  />
                </div>
                <div className="col-md-12">
                  <Input
                    placeholder={
                      this.state.identityDocNumberType.requiredInput.placeholder
                    }
                    label={this.state.identityDocNumberType.requiredInput.label}
                    required
                    value={
                      this.state[
                        this.state.identityDocNumberType.requiredInput.field
                      ]
                    }
                    onChange={(e) =>
                      this.onChangeText(
                        this.state.identityDocNumberType.requiredInput.field,
                        e
                      )
                    }
                    error={
                      this.state.error[
                        this.state.identityDocNumberType.requiredInput.field
                      ]
                    }
                    type={
                      this.state.identityDocNumberType.requiredInput.fieldType
                    }
                    minLength={
                      this.state.identityDocNumberType.requiredInput.minLength
                    }
                    maxLength={
                      this.state.identityDocNumberType.requiredInput.maxLength
                    }
                    rightButtonIcon={
                      this.state.identityDocNumberType?.search === "NIDA" &&
                      icons.search
                    }
                    rightButtonPressed={() =>
                      this.state.identityDocNumberType?.search === "NIDA"
                        ? this.handleFetchNIDAInfo()
                        : null
                    }
                    rightButtonSubmitting={this.state.isFetchingNIDAInfo}
                    profileData={this.state.profileData}
                  />
                </div>
              </div>
            )}
          <div className="row">
            <div className="col-md-6">
              <Select
                // options={this.state.centers}
                loadOptions={(e) => this.getCenters(e)}
                leftIcon={icons.center}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].center}
                required
                value={this.state.center}
                onChange={(e) => this.onChangeText("center", e)}
                error={this.state.error.center}
                isLoading={this.state.isFetchingCenter}
              />
            </div>
            <div className="col-md-6">
              <Select
                key={this.state?.center?.value || "no-center"}
                isDisabled={!this.state?.center?.value}
                loadOptions={(e) =>
                  this.getCenterSites({
                    q: e,
                    center: this.state?.center?.value,
                  })
                }
                leftIcon={icons.site}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].center_site}
                value={this.state.centerSite}
                onChange={(e) => this.onChangeText("centerSite", e)}
                error={this.state.error.centerSite}
                isLoading={this.state.isFetchingCenterSite}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Input
                leftIcon={icons.user}
                placeholder={
                  language[this.props.defaultLanguage].first_name_placeholder
                }
                label={language[this.props.defaultLanguage].first_name}
                required
                value={this.state.firstName}
                onChange={(e) => this.onChangeText("firstName", e)}
                error={this.state.error.firstName}
                inputStyles={{ textTransform: "capitalize" }}
              />
            </div>

            <div className="col-md-6">
              <Input
                leftIcon={icons.user}
                placeholder={
                  language[this.props.defaultLanguage].last_name_placeholder
                }
                label={language[this.props.defaultLanguage].last_name}
                required
                value={this.state.lastName}
                onChange={(e) => this.onChangeText("lastName", e)}
                error={this.state.error.lastName}
                inputStyles={{ textTransform: "capitalize" }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Input
                leftIcon={icons.phone}
                placeholder={
                  language[this.props.defaultLanguage].phone_number_placeholder
                }
                label={language[this.props.defaultLanguage].phone_number}
                value={this.state.phoneNumber}
                onChange={(e) => this.onChangeText("phoneNumber", e)}
                error={this.state.error.phoneNumber}
              />
            </div>
            <div className="col-md-6">
              <Input
                leftIcon={icons.email}
                placeholder={
                  language[this.props.defaultLanguage].email_placeholder
                }
                label={language[this.props.defaultLanguage].email_address}
                value={this.state.email}
                onChange={(e) => this.onChangeText("email", e)}
                error={this.state.error.email}
              />
            </div>

            <div className="col-md-12">
              <CheckboxQuestionnaire
                questionText={language[this.props.defaultLanguage].gender}
                error={this.state.error.gender}
                required
                answers={[
                  {
                    field: "gender",
                    value: "male",
                    onPress: () => this.handleCheck("gender", "male"),
                  },
                  {
                    field: "gender",
                    value: "female",
                    onPress: () => this.handleCheck("gender", "female"),
                  },
                ]}
                checked={this.state.gender}
              />
            </div>
            <div className="col-md-6">
              <Input
                leftIcon={icons.calendar}
                label={language[this.props.defaultLanguage].date_birth_date}
                required
                value={this.state.dateOfBirth}
                onChange={(e) => this.onChangeText("dateOfBirth", e)}
                error={this.state.error.dateOfBirth}
                type="date"
              />
            </div>
            <div className="col-md-6">
              <Input
                leftIcon={icons.location}
                label={language[this.props.defaultLanguage].place_of_birth}
                placeholder={
                  language[this.props.defaultLanguage]
                    .place_of_birth_placeholder
                }
                required
                value={this.state.placeOfBirth}
                onChange={(e) => this.onChangeText("placeOfBirth", e)}
                error={this.state.error.placeOfBirth}
              />
            </div>
            <Separator
              title={language[this.props.defaultLanguage].donor_current_address}
            />
            <div className="row">
              <div className="col-md-6">
                <Select
                  options={getProvinces(this.state.province)}
                  placeholder={language[this.props.defaultLanguage].select}
                  label={language[this.props.defaultLanguage].province}
                  required
                  value={this.state.province}
                  onChange={(e) => this.onChangeText("province", e)}
                  error={this.state.error.province}
                />
              </div>
              <div className="col-md-6">
                <Select
                  options={getDistricts(this.state.province?.value)}
                  placeholder={language[this.props.defaultLanguage].select}
                  label={language[this.props.defaultLanguage].district}
                  required
                  value={this.state.district}
                  onChange={(e) => this.onChangeText("district", e)}
                  error={this.state.error.district}
                />
              </div>
              <div className="col-md-6">
                <Select
                  options={getSectors(
                    this.state.province?.value,
                    this.state.district?.value
                  )}
                  placeholder={language[this.props.defaultLanguage].select}
                  label={language[this.props.defaultLanguage].sector}
                  required
                  value={this.state.sector}
                  onChange={(e) => this.onChangeText("sector", e)}
                  error={this.state.error.sector}
                />
              </div>
              <div className="col-md-6">
                <Select
                  options={getCells(
                    this.state.province?.value,
                    this.state.district?.value,
                    this.state.sector?.value
                  )}
                  placeholder={language[this.props.defaultLanguage].select}
                  label={language[this.props.defaultLanguage].cell}
                  required
                  value={this.state.cell}
                  onChange={(e) => this.onChangeText("cell", e)}
                  error={this.state.error.cell}
                />
              </div>
              <div className="col-md-6">
                <Select
                  options={getVillages(
                    this.state.province?.value,
                    this.state.district?.value,
                    this.state.sector?.value,
                    this.state.cell?.value
                  )}
                  placeholder={language[this.props.defaultLanguage].select}
                  label={language[this.props.defaultLanguage].village}
                  required
                  value={this.state.village}
                  onChange={(e) => this.onChangeText("village", e)}
                  error={this.state.error.village}
                />
              </div>
            </div>
            {(this.state.category === "repeatDonor" || this.props._id) && (
              <>
                <Separator
                  title={
                    language[this.props.defaultLanguage]
                      .in_case_donor_has_donated_previously
                  }
                />
                <div className="col-md-6">
                  <Input
                    leftIcon={icons.count}
                    label={
                      language[this.props.defaultLanguage].yes_how_many_times
                    }
                    placeholder={
                      language[this.props.defaultLanguage]
                        .yes_how_many_times_placeholder
                    }
                    value={this.state.howManyTimes}
                    onChange={(e) => this.onChangeText("howManyTimes", e)}
                    error={this.state.error.howManyTimes}
                    type="number"
                  />
                </div>

                <div className="col-md-6">
                  <Input
                    leftIcon={icons.count}
                    label={
                      language[this.props.defaultLanguage].last_donation_date
                    }
                    placeholder={
                      language[this.props.defaultLanguage].yes_how_many_times
                    }
                    value={this.state.lastDonationDate}
                    onChange={(e) => this.onChangeText("lastDonationDate", e)}
                    error={this.state.error.lastDonationDate}
                    type="date"
                  />
                </div>

                <div className="col-md-6">
                  <Select
                    loadOptions={(e) =>
                      this.getLastDonationCenterSites({
                        q: e,
                      })
                    }
                    leftIcon={icons.site}
                    placeholder={language[this.props.defaultLanguage].select}
                    label={
                      language[this.props.defaultLanguage].last_donation_site
                    }
                    value={this.state.lastDonationSite}
                    onChange={(e) => this.onChangeText("lastDonationSite", e)}
                    error={this.state.error.lastDonationSite}
                    isLoading={this.state.isFetchingLastDonationCenterSite}
                  />
                </div>
                <div className="col-md-6">
                  <Select
                    options={bloodGroups}
                    leftIcon={icons.bloodGroup}
                    placeholder={language[this.props.defaultLanguage].select}
                    label={language[this.props.defaultLanguage].blood_group}
                    value={this.state.bloodGroup}
                    onChange={(e) => this.onChangeText("bloodGroup", e)}
                    error={this.state.error.bloodGroup}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          <Button
            text={language[this.props.defaultLanguage].submit}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewDonor);
