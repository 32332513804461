import React from "react";
import { connect } from "react-redux";
import language from "../../language/index.js";
import { Table } from "../Table/index.js";
import { CSVLink } from "react-csv";
import {
  fetchBloodRecipient,
  deleteBloodRecipient,
} from "../../utils/queries/bloodRecipient.js";
import exportPDF from "../../utils/exportPDF.js";
import handleSearch from "../../utils/handleSearch.js";
import NewRecipient from "./NewRecipient";
import { DeleteModal, Modal } from "../Modal/index.js";
import { defineRole } from "../../utils/handleAccessRoles.js";
import icons from "../../constants/icons.js";
import { getStorage } from "../../utils/storage.js";

let copyData = [];

class BloodRecipient extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selectedData: {},
    delete_value: "",
    error: {},
    csvData: [],
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });

    await this.getData(true);
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e?.target?.value,
      error,
    });
  }

  getData = async (isLoading) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const { data, count } = await fetchBloodRecipient(defaultLanguage, {
        page,
        limit,
      });

      this.setState({
        data: data,
        totalPageCount: count,
        isLoading: false,
      });

      copyData = data.slice(0);
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleShowModal(modal, modalTitle, selectedData = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selectedData,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].hospital,
        key: "hospital.name",
      },
      {
        title: language[this.props.defaultLanguage].first_name,
        key: "firstName",
      },
      {
        title: language[this.props.defaultLanguage].last_name,
        key: "lastName",
      },
      {
        title: language[this.props.defaultLanguage].contact,
        key: "contact",
      },
      {
        title: language[this.props.defaultLanguage].identity_number,
        key: "identityNumber",
      },
      {
        title: language[this.props.defaultLanguage].province,
        key: "province",
      },
      {
        title: language[this.props.defaultLanguage].district,
        key: "district",
      },
      {
        title: language[this.props.defaultLanguage].sector,
        key: "sector",
      },
      {
        title: language[this.props.defaultLanguage].cell,
        key: "cell",
      },
      {
        title: language[this.props.defaultLanguage].village,
        key: "village",
      },
      {
        title: language[this.props.defaultLanguage].created_at,
        key: "createdAt",
        isMoment: true,
        formatTime: "lll",
      },
      {
        title: language[this.props.defaultLanguage].actions,
        key: "action",
      },
    ];

    return headers;
  }

  onDelete = async () => {
    try {
      this.setState({ isDeleting: true });

      await deleteBloodRecipient(
        this.props.defaultLanguage,
        this.state.selectedData._id
      );

      this.setState({ isDeleting: false });

      this.getData(true);
      this.handleCloseModal("showDeleteModal");
    } catch (error) {
      console.log(error);
      this.setState({
        isDeleting: false,
      });
    }
  };

  render() {
    return (
      <>
        <Table
          data={this.state.data}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          handleSearch={(e) =>
            handleSearch(this, {
              copyData,
              value: e?.target?.value,
            })
          }
          showAdd
          addButtonText={language[this.props.defaultLanguage].add}
          handleAddPressed={this.handleShowModal.bind(
            this,
            "showModal",
            language[this.props.defaultLanguage].add
          )}
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
          rowPress={(item) =>
            this.handleShowModal("showModal", item.name, item)
          }
          // filters={[
          //   {
          //     type: "export",
          //     title: language[this.props.defaultLanguage].export_,
          //     button_type: "dropdown",
          //     icon: "bx bxs-download",
          //     options: [
          //       {
          //         name: "PDF",
          //         onPress: this.downloadPDF.bind(this),
          //       },
          //       {
          //         name: "CSV",
          //         onPress: this.downloadExcel.bind(this),
          //       },
          //     ],
          //   },
          // ]}
          actions={[
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: "BloodRecipients",
                operation: "update",
              }),
              name: language[this.props.defaultLanguage].edit,
              onPress: this.handleShowModal.bind(
                this,
                "showModal",
                language[this.props.defaultLanguage].edit
              ),
              icon: icons.edit,
            },
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: "BloodRecipients",
                operation: "update",
              }),
              name: language[this.props.defaultLanguage].delete,
              onPress: this.handleShowModal.bind(
                this,
                "showDeleteModal",
                language[this.props.defaultLanguage].delete
              ),
              icon: icons.delete,
            },
          ]}
        />
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showModal")}
          show={this.state.showModal}
          title={this.state.modalTitle}
          showHeaderBottomBorder={false}
        >
          <NewRecipient
            handleCloseModal={this.handleCloseModal.bind(this, "showModal")}
            getData={this.getData.bind(this)}
            {...this.state.selectedData}
          />
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showDeleteModal")}
          show={this.state.showDeleteModal}
          title={this.state.modalTitle}
        >
          <DeleteModal
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showDeleteModal"
            )}
            error={this.state.error.delete_password}
            value={this.state.delete_value}
            onDelete={this.onDelete.bind(this)}
            onChange={(e) => this.onChangeText("delete_value", e)}
            isDeleting={this.state.isDeleting}
            name={this.state.selectedData.firstName}
            getData={this.getData.bind(this)}
          />
        </Modal>
        <CSVLink
          ref="csvDownload"
          filename={"Blood Usage" + new Date().getTime()}
          data={this.state.csvData}
        ></CSVLink>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(BloodRecipient);
