import React from "react";
import "./styles.css";
import bloodCenterSiteQuery from "../../../utils/queries/bloodCenterSiteQuery";
import { Loading } from "../../Loader";
import icons from "../../../constants/icons";
import { Modal } from "../../Modal";
import CenterDetails from "./CenterDetails";
import HeaderSearch from "./HeaderSearch";
import language from "../../../language";
import { connect } from "react-redux";
import { Empty } from "../../Empty";
import { Button } from "../../Button";

let copyData = [];

class Sites extends React.Component {
  state = {
    data: [],
    page: 1,
    limit: 10,
    selectedData: {},
  };

  componentDidMount = async () => {
    await this.getData(true);

    const { searchText } = this.props;

    if (searchText) {
      this.setState({ searchText });

      this.handleSearch(searchText);
    }
  };

  getData = async (isLoading, search = {}) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage, centerId } = this.props;

      if (!centerId) return language[this.props.defaultLanguage].center_missing;

      this.setState({ isLoading });

      const { data, count } = await bloodCenterSiteQuery(defaultLanguage, {
        page,
        limit,
        center: centerId,
        ...search,
      });

      this.setState({
        data: data,
        totalPageCount: count,
        isLoading: false,
      });

      copyData = data.slice(0);
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleShowModal(modal, modalTitle, selectedData) {
    this.setState({
      [modal]: true,
      modalTitle,
      selectedData,
    });
  }

  handleCloseModal = (modal) => {
    this.setState({ [modal]: false });
  };

  handleChange(e) {
    const searchText = e?.target?.value;

    this.setState({
      searchText,
    });
  }

  handleSearch() {
    this.getData(true, { q: this.state.searchText });
  }

  render() {
    return (
      <>
        <div style={{ marginBottom: "2rem" }}>
          <Button
            text={language[this.props.defaultLanguage].back}
            icon="keyboard_backspace"
            className="btn-default"
            onPress={() => (window.location.href = "/centers")}
          />
        </div>

        <div className="sites-landing-tabs-container">
          <HeaderSearch
            searchText={this.state.searchText}
            handleChange={this.handleChange.bind(this)}
            title={
              this.state.data.length > 0
                ? this.state.data[0]?.center?.name
                : language[this.props.defaultLanguage].center_sites
            }
            description={
              language[this.props.defaultLanguage].sites_search_description
            }
            placeholder={language[this.props.defaultLanguage].search + "..."}
            handleSearch={this.handleSearch.bind(this)}
          />
          {this.state.isLoading ? (
            <center>
              <Loading />
            </center>
          ) : this.state.data.length > 0 ? (
            <>
              <div className="row">
                {this.state.data.map((item, i) => {
                  return (
                    <div key={i} className="col-md-4 mb-3">
                      <div
                        className="card centers"
                        onClick={() =>
                          this.handleShowModal("centerModal", item.name, item)
                        }
                      >
                        <div className="card-body">
                          <div className="center-icon-container">
                            <span className={process.env.REACT_APP_ICONS_TYPE}>
                              {icons.location}
                            </span>
                          </div>
                          <div className="center-info-container">
                            <h3 className="mb-1">{item.name}</h3>
                            <div className="address-container">
                              <p className="text-gray">
                                {item.address.province} {item.address.district}{" "}
                                {item.address.sector} {item.address.cell}{" "}
                                {item.address.village}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <Empty
              description={`${
                language[this.props.defaultLanguage].no_data_found
              }: ${this.state.searchText}`}
            />
          )}
        </div>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "centerModal")}
          show={this.state.centerModal}
          title={this.state.modalTitle}
        >
          <CenterDetails center={this.state.selectedData} />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Sites);
