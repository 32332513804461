import React from "react";
import { connect } from "react-redux";
import language from "../../../language/index.js";
import { Table } from "../../Table/index.js";
import { DeleteModal, Modal } from "../../Modal/index.js";
import { defineRole } from "../../../utils/handleAccessRoles.js";
import { getStorage } from "../../../utils/storage.js";
import icons from "../../../constants/icons.js";
import {
  fetchShippingInQueueData,
  deleteQueue,
} from "../../../utils/queries/request/shipping-queue.js";
import { ROLE_REQUEST } from "../../../constants/ROLES.js";
import { Empty } from "../../Empty/index.js";
import toastMessage from "../../../utils/toastMessage.js";
import handleSearch from "../../../utils/handleSearch.js";
import UpdateShipping from "./UpdateShipping.js";
import ShippingInQueue from "./ShippingInQueue.js";
import Button from "../../Button/Button.js";
import List from "./List.js";

let copyData = [];

class ShippingQueue extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 1000000,
    search_text: "",
    selectedData: {},
    delete_value: "",
    error: {},
    csvData: [],
    user: {},
    shipmentNumber: "",
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });

    await this.getData(true);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isRefreshing !== this.props.isRefreshing) {
      this.getData(true);
    }

    if (prevProps.refreshShipmentNumber !== this.props.refreshShipmentNumber) {
      this.getData(true);
    }
  }

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e.target ? e?.target?.value : e,
      error,
    });
  }

  getData = async (isLoading) => {
    try {
      const { page, limit } = this.state;
      const {
        defaultLanguage,
        shipmentNumber,
        donationNumber,
        status,
        addedBy,
      } = this.props;

      if (!shipmentNumber || shipmentNumber === "") return;

      this.setState({ isLoading });

      const requestBody = {
        page,
        limit,
        addedBy,
        shipmentNumber,
        status,
        donationNumber,
      };

      const { data, count } = await fetchShippingInQueueData(
        defaultLanguage,
        requestBody
      );

      this.setState({
        data: data,
        totalPageCount: count,
        isLoading: false,
      });

      copyData = data.slice(0);
    } catch (error) {
      this.setState({
        isLoading: false,
      });

      toastMessage("error", error);
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleShowModal(modal, modalTitle, selectedData = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selectedData,
      reason: selectedData.reason || "",
    });

    if (selectedData?.donation) {
      this.setState({ donation: selectedData.donation });
    }
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].shipment_number,
        key: "shipmentNumber",
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].product,
        key: "product",
      },
      {
        title: language[this.props.defaultLanguage].donation,
        key: "donation.donationNumber",
        type: "link",
        onPress: (item) =>
          (window.location.href = `/dashboard/donations/${item.donation._id}/${item.donation.donor}`),
      },
      {
        title: language[this.props.defaultLanguage].source,
        key: "from.name",
      },
      {
        title: language[this.props.defaultLanguage].destination,
        key: "to.name",
      },
      {
        title: language[this.props.defaultLanguage].date,
        key: "createdAt",
        isMoment: true,
        formatTime: "lll",
      },
      {
        title: language[this.props.defaultLanguage].status,
        key: "status",
        type: "status",
      },
      {
        title: language[this.props.defaultLanguage].actions,
        key: "action",
      },
    ];

    return headers;
  }

  onDelete = async () => {
    try {
      this.setState({ isDeleting: true });

      await deleteQueue(
        this.props.defaultLanguage,
        this.state.selectedData._id
      );

      this.setState({ isDeleting: false });

      this.getData(true);
      this.handleCloseModal("showDeleteModal");
    } catch (error) {
      this.setState({
        isDeleting: false,
      });
    }
  };

  render() {
    if (this.state.data.length === 0) return;
    return (
      <>
        <List
          data={this.state.data}
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          showAdd
          addButtonText={language[this.props.defaultLanguage].create}
          handleAddPressed={() => this.handleShowModal("showPrint")}
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isSearching}
          getData={this.getData.bind(this, true)}
          selectedData={this.state.data?.length > 0 ? this.state.data[0] : {}}
          addedBy={this.props.addedBy}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { refreshShipmentNumber } = state.ShipmentNumber;
  return {
    defaultLanguage,
    refreshShipmentNumber,
  };
};

export default connect(mapStateToProps)(ShippingQueue);
