import React from "react";
import "./styles.css";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "../../utils/withRouter";
import { Logo } from "../Logo";
import icons from "../../constants/icons";
import NotificationBadgeCount from "../Notifications/NotificationBadgeCount";

class Sidebar extends React.Component {
  constructor() {
    super();

    this.mobileNavbarRef = React.createRef();

    this.setState({
      menus: [],
    });
  }

  checkMobileSize() {
    return !window.matchMedia("only screen and (min-width: 1200px)").matches;
  }

  componentDidMount = async () => {
    this.setState({
      menus: this.props.menus,
    });

    const isMobile = this.checkMobileSize();

    if (isMobile) {
      this.handleHideSidebar();
    }

    window.addEventListener("resize", this.handleResize.bind(this));
  };

  componentDidUpdate(prevProps) {
    if (prevProps.defaultLanguage !== this.props.defaultLanguage) {
      this.setState({
        menus: this.state.menus,
        // sidebarMenus(this.props.defaultLanguage),
      });
    }
  }

  handleShowSidebar() {
    const container = document.getElementById("sidebar-container");
    if (container && container?.classList) {
      let nav_class_list = container.classList;
      if (container && nav_class_list.contains("hide")) {
        nav_class_list.add("show");
        nav_class_list.remove("hide");
      }
    }
  }

  handleHideSidebar() {
    const container = document.getElementById("sidebar-container");

    if (container && container?.classList) {
      let nav_class_list = container.classList;

      if (container && nav_class_list.contains("show")) {
        nav_class_list.add("hide");
        nav_class_list.remove("show");
      }
    }
  }

  handleClickSidenav(e) {
    e.preventDefault();

    const isMobile = this.checkMobileSize();

    if (isMobile) {
      this.handleHideSidebar();
    }
  }

  handleToggleSubMenus(menuIndex) {
    let { menus } = this.state;
    menus[menuIndex].submenusVisibility = !menus[menuIndex].submenusVisibility;

    this.setState({ menus });
  }

  handleResize() {
    const { innerWidth } = window;

    if (innerWidth > 1200) {
      this.handleShowSidebar();
    } else {
      this.handleHideSidebar();
    }
  }

  render() {
    return (
      <div
        className="sidebar-container show"
        id="sidebar-container"
        ref={this.mobileNavbarRef}
        onClick={this.handleClickSidenav.bind(this)}
      >
        <div className="mobile-sidebar-header-container hidden-sm hidden-xs">
          <div style={{ flex: 1 }}>
            <Logo handleMenuIconClicked={this.handleHideSidebar.bind(this)} />
          </div>
          <div className="icon-container">
            <span className={process.env.REACT_APP_ICONS_TYPE}>
              {icons.close}
            </span>
          </div>
        </div>
        <ul>
          {this.state?.menus?.map((el, i) => {
            let splitUrlRoutePath = this.props.routeParams["*"].split("/");
            return (
              <li key={i}>
                <Link
                  to={el.parentPath + "" + el.path}
                  className={`${
                    splitUrlRoutePath.includes(el?.name?.toLowerCase())
                      ? "active"
                      : ""
                  }`}
                >
                  <div className="menu-icon-container">
                    <span className={process.env.REACT_APP_ICONS_TYPE}>
                      {el.icon}
                    </span>
                  </div>
                  <div className="name-container">
                    <span style={{ flex: 1 }}>{el.name}</span>
                    {el.showNotification &&
                      !splitUrlRoutePath.includes(el.name.toLowerCase()) &&
                      el?.notificationStorageName &&
                      this.props[el.notificationStorageName] &&
                      this.props[el.notificationStorageName]?.length > 0 && (
                        <NotificationBadgeCount
                          count={this.props[el.notificationStorageName]?.length}
                        />
                      )}
                  </div>
                  {el.menus && el.menus.length > 0 && (
                    <div
                      className="toggle-container"
                      onClick={() => this.handleToggleSubMenus(i)}
                    >
                      <span className={process.env.REACT_APP_ICONS_TYPE}>
                        {el.submenusVisibility
                          ? icons.chevronUp
                          : icons.chevronDown}
                      </span>
                    </div>
                  )}
                </Link>
                {!el.submenusVisibility &&
                  el.menus &&
                  el.menus.length > 0 &&
                  el.menus.map((subM, m) => {
                    return (
                      <ul key={m} className="nested">
                        <li>
                          <Link
                            to={el.parentPath + "" + el.path + "" + subM.path}
                            className={`${
                              splitUrlRoutePath.includes(
                                subM.name.toLowerCase()
                              )
                                ? "active"
                                : ""
                            }`}
                          >
                            <div style={{ flex: 1 }}>{subM.name}</div>
                          </Link>
                        </li>
                      </ul>
                    );
                  })}
              </li>
            );
          })}
        </ul>

        {/* <div className="footer-powered-by">
          <span>
            Powered By <span className="text-body text-primary">Huzalabs</span>
          </span>
        </div> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { notifications } = state.Notifications;
  return {
    defaultLanguage,
    notifications,
  };
};

export default connect(mapStateToProps)(withRouter(Sidebar));
