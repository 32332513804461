import React from "react";
import { connect } from "react-redux";
import DonationWithWrongVitalSign from "../../../components/ExceptionalCases/DonationWithWrongVitalSign";
import { Tabs } from "../../../components/Tabs";
import DonationWithoutDonor from "../../../components/ExceptionalCases/DonationWithoutDonor";
import DonationMissingFromProduction from "../../../components/ExceptionalCases/DonationMissingFromProduction";
import language from "../../../language/index";

class DonationWithWrongVitalSignScreen extends React.Component {
  render() {
    return (
      <div>
        <Tabs
          options={[
            {
              title:
                language[this.props.defaultLanguage]
                  .donation_with_wrong_vital_sign,
              data: (
                <div className="card-dashboard-content">
                  <div className="card-body">
                    <DonationWithWrongVitalSign />
                  </div>
                </div>
              ),
            },
            {
              title: language[this.props.defaultLanguage].donation_no_donor,
              data: (
                <div className="card-dashboard-content">
                  <div className="card-body">
                    <DonationWithoutDonor />
                  </div>
                </div>
              ),
            },
            {
              title: language[this.props.defaultLanguage].unavailable_donation,
              data: (
                <div className="card-dashboard-content">
                  <div className="card-body">
                    <DonationMissingFromProduction />
                  </div>
                </div>
              ),
            },
          ]}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(DonationWithWrongVitalSignScreen);
