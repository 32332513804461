import React from "react";
import { connect } from "react-redux";
import language from "../../../language";
import { Table } from "../../Table";
import {
  deleteInventory,
  fetchInventories,
} from "../../../utils/queries/production/inventoryQuery";
import bloodDonationQuery from "../../../utils/queries/bloodDonationQuery";
import { DeleteModal, Modal } from "../../Modal";
import {
  defineRole,
  defineRoleCenterSite,
} from "../../../utils/handleAccessRoles";
import { getStorage } from "../../../utils/storage";
import icons from "../../../constants/icons";
import ViewInventory from "./ViewInventory";
import exportPDF from "../../../utils/exportPDF";
import CardCount from "../../CardCount/CardCount";
import { BloodGroupFilter, CentersFilter, DateFilter } from "../../Filters";
import { CSVLink } from "react-csv";
import { ROLE_INVENTORY } from "../../../constants/ROLES";
import moment from "moment";
import handleFilters from "../../../utils/handleFilters";

class Inventories extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selectedData: {},
    delete_value: "",
    error: {},
    csvData: [],
    user: {},
    suspendReason: "",
    counts: {
      labelled: 0,
      transformed: 0,
      dispatched: 0,
      pending: 0,
      returned: 0,
      transferred: 0,
    },
    isDisabled: true,
    filters: {
      startDate: moment()
        .startOf("D")
        .subtract(30, "days")
        .format("YYYY-MM-DD"),
      endDate: moment().endOf("D").format("YYYY-MM-DD"),
    },
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });

    this.fetchData(true, this.state.filters);
  };

  fetchData(isLoading, search = {}) {
    this.getData(isLoading, { ...search });
    this.getCountsData(isLoading, search, "pending");
    this.getCountsData(isLoading, search, "labelled");
    this.getCountsData(isLoading, search, "transformed");
    this.getCountsData(isLoading, search, "returned");
    this.getCountsData(isLoading, search, "transferred");
  }

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e.target ? e?.target?.value : e,
      error,
    });
  }

  returnFilters = async (search = {}) => {
    const { page, limit } = this.state;
    const { centerRole, centerSiteRole } = await defineRoleCenterSite();
    const filters = handleFilters(search);
    return {
      // ...filters,
      page,
      limit,
      donation: this.props.donationId,
      id: this.props.inventoryId,
      center: centerRole || filters?.centers?.map((el) => el.value),
      centerSite: centerSiteRole || filters?.centerSite?.map((el) => el.value),
      ...search,
    };
  };

  getData = async (isLoading, search = {}) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const filters = await this.returnFilters(search);

      if (search?.donationNumber) {
        const donation = await bloodDonationQuery(defaultLanguage, {
          ...search,
        });

        if (donation?.length > 0) {
          filters.donation = donation[0]?._id || undefined;
        } else {
          this.setState({ isLoading: false });
          return;
        }
      }

      const { data, count } = await fetchInventories(defaultLanguage, filters);

      this.setState({
        data: data,
        totalPageCount: count,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  getCountsData = async (isLoadingCounts, search, type) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoadingCounts });

      const filters = await this.returnFilters();

      if (search?.donationNumber) {
        const donation = await bloodDonationQuery(defaultLanguage, {
          ...search,
        });

        if (donation?.length > 0) {
          filters.donation = donation[0]?._id || undefined;
        } else {
          this.setState({ isLoading: false });
          return;
        }
      }

      let params = {
        ...filters,
        ...search,
        isCount: true,
        status: type,
      };

      const { data } = await fetchInventories(defaultLanguage, params);

      let { counts } = this.state;

      counts[type] = data;

      this.setState({
        counts,
        isLoadingCounts: false,
      });
    } catch (error) {
      this.setState({
        isLoadingCounts: false,
      });
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleShowModal(modal, modalTitle, selectedData = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selectedData,
      reason: selectedData.reason || "",
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].donation_number,
        key: "donation.donationNumber",
        // type: "link",
        // onPress: (item) =>
        //   (window.location.href = `/dashboard/production/center/${item?.center?._id}/${item?.center?.name}/${item?.centerSite?._id}/${item?.centerSite?.name}`),
      },
      {
        title: language[this.props.defaultLanguage].code,
        key: "code",
      },
      {
        title: language[this.props.defaultLanguage].product,
        key: "name",
      },
      {
        title: language[this.props.defaultLanguage].blood_group,
        key: "bloodGroup",
      },
      {
        title: language[this.props.defaultLanguage].volume,
        key: "volume",
      },
      {
        title: language[this.props.defaultLanguage].phlebotomy_date,
        key: "donation.collectionDate",
        formatTime: "ll",
        isMoment: true,
      },
      {
        title: language[this.props.defaultLanguage].expired_date,
        key: "expiredDate",
        formatTime: "lll",
        isMoment: true,
      },

      {
        title: language[this.props.defaultLanguage].labelled_by,
        key: "verifiedBy.pin",
      },
      {
        title: language[this.props.defaultLanguage].status,
        key: "status",
        type: "status",
      },

      {
        title: language[this.props.defaultLanguage].source,
        key: "sourceStock.name",
      },
      {
        title: language[this.props.defaultLanguage].current_stock,
        key: "stock.name",
      },

      {
        title: language[this.props.defaultLanguage].reason,
        key: "reason",
      },

      {
        title: language[this.props.defaultLanguage].dispatched_date,
        key: "transferDate",
        formatTime: "lll",
        isMoment: true,
      },
      {
        title: language[this.props.defaultLanguage].actions,
        key: "action",
      },
    ];

    return headers;
  }

  onDelete = async () => {
    try {
      this.setState({ isDeleting: true });

      await deleteInventory(
        this.props.defaultLanguage,
        this.state.selectedData._id
      );

      this.setState({ isDeleting: false });

      this.getData(true);
      this.handleCloseModal("showDeleteModal");
    } catch (error) {
      this.setState({
        isDeleting: false,
      });
    }
  };

  downloadExcel = async () => {
    try {
      this.setState({
        isLoading: true,
      });

      const { defaultLanguage } = this.props;

      const filters = await this.returnFilters();

      const { data } = await fetchInventories(defaultLanguage, filters);

      this.setState({
        isLoading: false,
        csvData: data.map((el) => {
          return {
            ...el,
            center: el?.center?.name,
            centerSite: el?.centerSite?.name,
          };
        }),
      });

      this.refs.csvDownload?.link.click();
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  downloadPDF = async () => {
    this.setState({
      isLoading: true,
    });

    const { defaultLanguage } = this.props;

    const filters = await this.returnFilters();

    const { data } = await fetchInventories(defaultLanguage, filters);

    this.setState({
      isLoading: false,
    });

    exportPDF("Inventories", this.returnTableHeaders(), data);
  };

  handleRemoteSearch = async (search) => {
    this.setState({
      searching: true,
    });
    await this.getData(true, {
      donationNumber: search || search !== "" ? search : undefined,
    });

    this.setState({
      searching: false,
    });
  };

  handleSearch = async (e) => {
    const search = e?.target?.value;
    this.setState({ search_text: search });
  };

  render() {
    return (
      <>
        <div className="row">
          <div style={{ marginBottom: 16 }}>
            <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
              <CentersFilter
                handleApplyFilters={(isLoading, filters) =>
                  this.fetchData(isLoading, filters)
                }
              />

              <BloodGroupFilter
                handleApplyFilters={(isLoading, filters) =>
                  this.fetchData(isLoading, filters)
                }
              />
              <DateFilter
                handleApplyFilters={(isLoading, filters) =>
                  this.fetchData(isLoading, filters)
                }
                startDate={this.state.startDate}
                endDate={this.state.endDate}
              />
            </div>
          </div>
          <div className="col-5th ">
            <CardCount
              count={this.state.counts.pending}
              title={language[this.props.defaultLanguage].pending}
              color="orange"
              icon="schedule"
              isLoading={this.state.isLoadingCounts}
              onPress={() =>
                this.getData(true, {
                  ...this.state.filters,
                  status: "pending",
                })
              }
              // onPress={() =>
              //   (window.location.href = `/dashboard/production/inventory/byStatus/${this.state.filters.startDate}/${this.state.filters.endDate}/pending`)
              // }
            />
          </div>
          <div className="col-5th ">
            <CardCount
              count={this.state.counts.labelled}
              title={language[this.props.defaultLanguage].labelled}
              color="#078ECE"
              icon="print"
              isLoading={this.state.isLoadingCounts}
              onPress={() =>
                this.getData(true, {
                  ...this.state.filters,
                  status: "labelled",
                })
              }
              // onPress={() =>
              //   (window.location.href = `/dashboard/production/inventory/byStatus/${this.state.filters.startDate}/${this.state.filters.endDate}/labelled`)
              // }
            />
          </div>
          <div className="col-5th ">
            <CardCount
              count={this.state.counts.transformed}
              title={language[this.props.defaultLanguage].transformed}
              color="gray"
              icon="restart_alt"
              isLoading={this.state.isLoadingCounts}
              onPress={() =>
                this.getData(true, {
                  ...this.state.filters,
                  status: "transformed",
                })
              }
              // onPress={() =>
              //   (window.location.href = `/dashboard/production/inventory/byStatus/${this.state.filters.startDate}/${this.state.filters.endDate}/transformed`)
              // }
            />
          </div>
          <div className="col-5th ">
            <CardCount
              count={this.state.counts.returned}
              title={language[this.props.defaultLanguage].returned}
              color="turquoise"
              icon="keyboard_return"
              isLoading={this.state.isLoadingCounts}
              onPress={() =>
                this.getData(true, {
                  ...this.state.filters,
                  status: "returned",
                })
              }
              // onPress={() =>
              //   (window.location.href = `/dashboard/production/inventory/byStatus/${this.state.filters.startDate}/${this.state.filters.endDate}/returned`)
              // }
            />
          </div>
          <div className="col-5th">
            <CardCount
              count={this.state.counts.transferred}
              title={language[this.props.defaultLanguage].direct_transfer}
              color="purple"
              icon={icons.transfer}
              isLoading={this.state.isLoadingCounts}
              onPress={() =>
                this.getData(true, {
                  ...this.state.filters,
                  status: "transferred",
                })
              }
              // onPress={() =>
              //   (window.location.href = `/dashboard/production/inventory/byStatus/${this.state.filters.startDate}/${this.state.filters.endDate}/transferred`)
              // }
            />
          </div>
        </div>

        <Table
          data={this.state.data}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          addSearchButton
          search_text={this.state.search_text}
          searching={this.state.searching}
          handleSearch={(e) => this.onChangeText("search_text", e)}
          handlePressSearch={() =>
            this.handleRemoteSearch(this.state.search_text)
          }
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
          filters={[
            {
              type: "button",
              title: language[this.props.defaultLanguage].refresh,
              button_type: "button",
              icon: icons.refresh,
              onPress: (item) => this.fetchData(true),
            },
            // {
            //   type: "dropdown",
            //   filterName: "date_range",
            //   title: language[this.props.defaultLanguage].date_range,
            //   button_type: "dropdown",
            //   handleApplyFilters: () => this.getData(true),
            //   startDate: moment().startOf("D").format("YYYY-MM-DD"),
            //   endDate: moment().endOf("D").format("YYYY-MM-DD"),
            // },
            {
              type: "export",
              title: language[this.props.defaultLanguage].export_,
              button_type: "dropdown",
              icon: "bx bxs-download",
              options: [
                {
                  name: "PDF",
                  onPress: this.downloadPDF.bind(this),
                },
                {
                  name: "CSV",
                  onPress: this.downloadExcel.bind(this),
                },
              ],
            },
          ]}
          actions={[
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_INVENTORY,
                operation: "read",
              }),
              name: language[this.props.defaultLanguage].view,
              icon: icons.eye_on,
              onPress: (item) =>
                this.handleShowModal("showModal", item.code, item),
            },
          ]}
        />
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showModal")}
          show={this.state.showModal}
          title={this.state.modalTitle}
          showHeaderBottomBorder={false}
          size="lg"
        >
          <ViewInventory
            handleCloseModal={this.handleCloseModal.bind(this, "showModal")}
            getData={this.fetchData.bind(this)}
            {...this.state.selectedData}
            donationId={
              this.props.donationId || this.state?.selectedData?.donation?._id
            }
            disabled={this.state.isDisabled}
          />
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showDeleteModal")}
          show={this.state.showDeleteModal}
          title={this.state.modalTitle}
        >
          <DeleteModal
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showDeleteModal"
            )}
            error={this.state.error.delete_password}
            value={this.state.delete_value}
            onDelete={this.onDelete.bind(this)}
            onChange={(e) => this.onChangeText("delete_value", e)}
            isDeleting={this.state.isDeleting}
            name={this.state.selectedData._id}
            getData={this.getData.bind(this)}
          />
        </Modal>
        <CSVLink
          ref="csvDownload"
          filename={"Inventories" + new Date().getTime()}
          data={this.state.csvData}
        ></CSVLink>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(Inventories);
