import React from "react";
import { connect } from "react-redux";
import language from "../../language/index.js";
import { Input } from "../Input";
import { Button } from "../Button";
import { getStorage } from "../../utils/storage.js";
import { handleDonationSyncInformation } from "../../utils/queries/exceptionCases/donationWithWrongVitalSign.js";
import toastMessage from "../../utils/toastMessage";

class DonationSync extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selectedData: {},
    delete_value: "",
    error: {},
    csvData: [],
    user: {},
    donationNumber: "",
    donations: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();

    await this.setState({ user });
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e?.target?.value,
      error,
    });
  }

  getData = async (isLoading) => {
    try {
      const { defaultLanguage } = this.props;
      const { donationNumber } = this.state;

      this.setState({ isLoading });

      const data = await handleDonationSyncInformation({
        donationNumber,
      });

      if (data.length === 0) {
        toastMessage(
          "error",
          language[this.props.defaultLanguage].no_data_found
        );
      }

      this.setState({
        data,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleShowModal(modal, modalTitle, selectedData = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selectedData,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  handleSearch = async (e) => {
    const search = e?.target?.value;
    this.setState({ search_text: search });
  };

  validateDonationSearch() {
    let { error, donationNumber } = this.state;

    if (donationNumber === "") {
      error.donationNumber =
        language[this.props.defaultLanguage].donation_number_required;
    }

    this.setState({
      error,
    });
  }

  render() {
    return (
      <>
        <div className="card mb-3">
          <div className="card-body">
            <div className="col-md-12">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].search + "..."
                }
                label={language[this.props.defaultLanguage].donation_number}
                required
                value={this.state.donationNumber}
                error={this.state.error.donationNumber}
                onChange={(e) => this.onChangeText("donationNumber", e)}
                disabled={this.props._id}
              />
            </div>
            <div>
              <Button
                text={language[this.props.defaultLanguage].resolve}
                onPress={(e) => {
                  e.preventDefault();
                  this.getData(true);
                }}
                isSubmitting={this.state.isLoading}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(DonationSync);
