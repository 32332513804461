import React from "react";
import { connect } from "react-redux";
import language from "../../language";
import { Table } from "../Table";
import feedbackQuery from "../../utils/queries/feedbackQuery.js";
import handleSearch from "../../utils/handleSearch";

let copyData = [];

class Feedbacks extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
  };

  componentDidMount = async () => {
    await this.getData(true);
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e?.target?.value,
      error,
    });
  }

  getData = async (isLoading) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const { data, count } = await feedbackQuery(defaultLanguage, {
        page,
        limit,
      });

      this.setState({
        data: data,
        totalPageCount: count,
        isLoading: false,
      });

      copyData = data.slice(0);
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].name,
        key: "name",
      },
      {
        title: language[this.props.defaultLanguage].email_address,
        key: "email",
      },
      {
        title: language[this.props.defaultLanguage].phone_number,
        key: "phoneNumber",
      },
      {
        title: language[this.props.defaultLanguage].message,
        key: "message",
      },
      {
        title: language[this.props.defaultLanguage].created_at,
        key: "createdAt",
        isMoment: true,
        formatTime: "lll",
      },
    ];

    return headers;
  }

  render() {
    return (
      <>
        <Table
          data={this.state.data}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          handleSearch={(e) =>
            handleSearch(this, {
              copyData,
              value: e?.target?.value,
            })
          }
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Feedbacks);
