import { getStorage } from "../../../../utils/storage";
import toastMessage from "../../../../utils/toastMessage";
import axios from "axios";

// const validateForm = (that) => {
//   let { error, donation } = that.state;

//   if (!donation && !donation?._id) {
//     error.donation = "Donation is required";
//   }

//   that.setState({ error });
// };

export const onGenerateLabel = async (that, item, method = "POST", id) => {
  const user = await getStorage();

  console.log("i m here 1");

  let requestedBody = {
    donation: item.donation._id,
    addedBy: user.id,
    component: item?.component?._id,
    componentProfile: item?.componentProfile?._id,
    componentProfileCode: item.componentProfile.code,
    name: item.name,
    code: item.code,
    volume: item.volume,
    bloodGroup: item.donation.bloodGroup || "N/A",
    center: item.donation?.center?._id || item.donation.center,
    centerSite: item.donation?.centerSite?._id || item.donation.centerSite,
    status: "pending",
    // type,
    // productId: item.productId || item._id,
  };

  if (method === "PUT") {
    requestedBody.id = id;
  }

  console.log("i m here 2");

  const options = {
    method,
    url: `${process.env.REACT_APP_BBMS_BASE_API}/inventory`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    data: requestedBody,
  };
  await axios(options)
    .then((data) => {
      that.setState({
        ["isSubmitting_" + item.code]: false,
        ["isGenerated_" + item.code]: true,
        selectedItem: data.data,
      });

      toastMessage(
        "success",
        `Label for ${item.code} ${item.name} was generated successfully`
      );

      return data.data;
    })
    .catch((error) => {
      console.log(error);
      that.setState({
        ["isSubmitting_" + item.code]: false,
        ["isGenerated_" + item.code]: false,
      });
      toastMessage("error", error);

      return;
    });
};
