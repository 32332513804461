import { clearStorage, getStorage } from "../storage";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import toastMessage from "../toastMessage";

export const apiHandler = () => {
  const env = process.env.REACT_APP_BBMS_BASE_API;

  if (!env) return;

  return env;
};

const createAxiosInstance = () => {
  const baseURL = apiHandler();
  if (!baseURL) {
    throw new Error("API baseURL not configured");
  }

  const instance = axios.create({
    baseURL,
    // timeout: 10000,
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  });

  instance.interceptors.request.use(
    async (config) => {
      // Check for internet connectivity before making the request
      if (!navigator.onLine) {
        toastMessage("Application is offline");
        return Promise.reject(new Error("No internet connection"));
      }

      const userLoggedIn = await getStorage();
      let { token, role } = userLoggedIn || {};

      if (token) {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 30000;
        // const expiryTimestamp = await getStorage("lastLogin");

        // Check if token is expired
        if (
          decodedToken &&
          decodedToken?.exp &&
          decodedToken?.exp < currentTime
        ) {
          try {
            clearStorage();
            window.location.href =
              role === "superAdmin" || role === "account"
                ? "/adminLogin"
                : "/login";
          } catch (error) {
            console.error("Failed  token", error);
            // Redirect to login page or handle token refresh failure
            clearStorage();
            window.location.href =
              role === "superAdmin" || role === "account"
                ? "/adminLogin"
                : "/login";
            return Promise.reject(new Error("Token failed"));
          }
        }

        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const userLoggedIn = await getStorage();
      let { role } = userLoggedIn || {};

      // Check if error response exists and if the status is 401
      if (error.config && error.response) {
        const { status, request } = error.response;

        if (status === 401 && !request?.responseURL?.includes("auth")) {
          console.error(
            `Unauthorized access - 401. Redirecting to login. URL: ${error.config.url}`
          );

          // Clear local storage or any authentication data
          clearStorage();

          // Redirect user to the login page
          window.location.href =
            role === "superAdmin" || role === "account"
              ? "/adminLogin"
              : "/login";
        } else {
          console.error(`Error in request to URL: ${error.config.url}`, error);
        }
      } else {
        console.error("Request error", error);
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

const api = createAxiosInstance();

export default api;
