import React from "react";
import { connect } from "react-redux";
import { Tabs } from "../../../components/Tabs";
import language from "../../../language";
import { withRouter } from "../../../utils/withRouter";
import { DonorProfile } from "../../../components/Donors";
import { Appointments } from "../../../components/BookAppointment";
import { Breadcrumb } from "../../../components/Breadcrumb";
import { VitalSigns } from "../../../components/VitalSigns";
import PreAssessment from "../../../components/Donors/Pre-assessment/Answers/Index";
import BloodCollection from "../../../components/BloodCollection/BloodCollection";
import { getStorage } from "../../../utils/storage";
import accessMenu from "../../../utils/accessMenu";
import BlockedBloodCollection from "../../../components/BloodCollection/BlockedBloodCollection";
class DonorScreen extends React.Component {
  state = {
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });
  };
  render() {
    const {
      canReadAssessment,
      canReadVital,
      canReadCollection,
      canReadAppointment,
    } = accessMenu();

    let tabOptions = [],
      donorId = this.props?.routeParams?.donorId || this.props.donorId,
      donorName = this.props?.routeParams?.donorName || this.props.donorName;

    if (canReadAppointment) {
      tabOptions.push({
        title: language[this.props.defaultLanguage].appointments,
        data: <Appointments route="/dashboard" donorId={donorId} />,
      });
    }

    if (canReadAssessment) {
      tabOptions.push({
        title: language[this.props.defaultLanguage].preassessments,
        data: <PreAssessment route="/dashboard" donorId={donorId} />,
      });
    }

    if (canReadVital) {
      tabOptions.push({
        title: language[this.props.defaultLanguage].vital_signs,
        data: <VitalSigns donorId={donorId} />,
      });
    }

    if (canReadCollection) {
      tabOptions.push(
        {
          title: language[this.props.defaultLanguage].collection,
          data: (
            <BloodCollection
              donorId={donorId}
              // customFilters={{ "blockInfo.blocked": false }}
            />
          ),
        },
        {
          title: language[this.props.defaultLanguage].blocked_donations,
          data: (
            <BlockedBloodCollection
              donorId={donorId}
              customFilters={{
                "blockInfo.blocked": true,
                status: ["suspended", "blocked"],
              }}
            />
          ),
        }
      );
    }
    return (
      <div>
        <div className="card-dashboard-content">
          <div className="card-body">
            <Breadcrumb
              homeUrl="/dashboard/home"
              data={[
                {
                  name: language[this.props.defaultLanguage].donors,
                  url: "/dashboard/donors",
                },
                {
                  name: donorName,
                  active: true,
                },
              ]}
            />
            <DonorProfile donorId={donorId} />
            <Tabs options={tabOptions} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(withRouter(DonorScreen));
