import toastMessage from "../../toastMessage";
import formatSelectData from "../../formatSelectData";
import { getStorage } from "../../storage";
import language from "../../../language";
import { defineRole } from "../../handleAccessRoles";
import {
  ROLE_BLOOD_TEST_BLOOD_GROUPING,
  ROLE_BLOOD_TEST_SEROLOGY,
} from "../../../constants/ROLES";
import api from "../../api";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

export const fetchBloodTestHistory = async (
  lng = "english",
  { page, limit, type, bloodTest, questionnaireType, code, testBy, status }
) => {
  try {
    const user = await getStorage();

    const canAccess = defineRole({
      roles: user.accessRole,
      menu: [ROLE_BLOOD_TEST_BLOOD_GROUPING, ROLE_BLOOD_TEST_SEROLOGY],
      operation: "read",
    });

    if (!canAccess) return;

    const options = {
      method: "GET",
      url: `${API_URL}/bloodTestHistory`,
      params: {
        page: page,
        limit: limit,
        bloodTest,
        questionnaireType,
        code,
        testBy,
        status,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await api(options);

    let { data, count } = queryRes.data;

    if (type === "dropdown") {
      return formatSelectData(queryRes.data, "code", "_id");
    }

    return !page && !limit ? queryRes.data : { data, count };
  } catch (error) {
    toastMessage("error", error);
  }
};

export const deleteBloodTestHistory = async (
  lng = "english",
  { id, bloodTest }
) => {
  try {
    const user = await getStorage();

    const options = {
      method: "DELETE",
      url: `${API_URL}/bloodTestHistory`,
      data: {
        id,
        bloodTest,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    await api(options);

    toastMessage("success", language[lng].success_delete_data);
  } catch (error) {
    toastMessage("error", error);
  }
};
