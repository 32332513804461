import React from "react";
import { DropdownButton } from "../Button";

import departmentQuery from "../../utils/queries/departmentQuery";
import { Loading } from "../Loader";
import icons from "../../constants/icons";
import { CardHeader } from "../Header";
import { connect } from "react-redux";
import language from "../../language";
import { Checkbox } from "../Input";
import { onAddFilter } from "../../actions/Filters";

let copyData = [];

class DepartmentFilter extends React.Component {
  state = {
    isLoading: true,
    data: [],
  };

  componentDidMount = async () => {
    await this.getData(true);
  };

  getData = async (isLoading) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const data = await departmentQuery(defaultLanguage, {});

      this.setState({
        data: data,
        isLoading: false,
      });

      copyData = data.slice(0);
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  onCheck(el) {
    let { filters } = this.props;
    let { departments = [] } = filters;

    const index = departments.findIndex((sEl) => sEl.value === el._id);

    if (index === -1) {
      departments.push({
        value: el._id,
        label: el.name,
      });
    } else {
      departments.splice(index, 1);
    }

    // this.props.dispatch(
    //   onAddFilter({
    //     departments,
    //   })
    // );
    this.props.handleApplyFilters &&
      this.props.handleApplyFilters(true, { departments });
  }

  handleSearch(e) {
    const searchValue = e?.target?.value;
    this.setState({ searchValue });

    let results = [];
    copyData.forEach((el) => {
      if (
        JSON.stringify(el).toLowerCase().indexOf(searchValue.toLowerCase()) !==
        -1
      ) {
        results.push(el);
      }
    });

    this.setState({ data: results });
  }

  render() {
    return (
      <DropdownButton
        leftIcon={icons.department}
        text="Departments"
        className="btn-transparent bordered-md w-sm-100 btn-sm"
        selectedData={this.props?.filters?.departments}
        clickOutside
      >
        <CardHeader
          title={language[this.props.defaultLanguage].filter_by_department}
          borderBottom
          closeButton
          isSearch
        />
        <div className="dropdown-content">
          {this.state.isLoading ? (
            <center>
              <Loading />
            </center>
          ) : (
            this.state?.data?.map((el, i) => {
              return (
                <Checkbox
                  label={el.name}
                  key={i}
                  onCheck={() => this.onCheck(el)}
                  checked={this.props?.filters?.departments?.find(
                    (sEl) => sEl.value === el._id
                  )}
                />
              );
            })
          )}
        </div>
      </DropdownButton>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(DepartmentFilter);
