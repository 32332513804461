import React from "react";
import "./styles.css";

class DropdownButton extends React.Component {
  constructor() {
    super();

    this.state = {
      isOpen: false,
    };

    this.search_drop_ref = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside.bind(this));

    const { isOpen } = this.props;
    if (isOpen) {
      this.setState({ isOpen });
    }
  }

  componentDidUpdate(nxtProps) {
    if (this.props.isOpen !== nxtProps.isOpen) {
      this.setState({ isOpen: nxtProps.isOpen });
    }
  }

  componentWillUnmount() {
    document.removeEventListener(
      "mousedown",
      this.handleClickOutside.bind(this)
    );
  }

  handleClickOutside(e) {
    if (
      this.search_drop_ref &&
      this.search_drop_ref.current &&
      !this.search_drop_ref.current.contains(e.target)
    ) {
      this.setState({
        isOpen: false,
      });
    }
  }

  handleOpen() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  onSelectDropdownItem(item) {
    if (this.props.isMulti) {
      let default_key = this.props.default_key
        ? this.props.default_key
        : "value";

      let new_item = this.props.selected_item;
      if (!this.props.selected_item.includes(item[default_key])) {
        new_item.push(item[default_key]);
      } else {
        new_item.splice(new_item.indexOf(item[default_key]), 1);
      }
      this.props.onSelectDropdownItem(new_item);

      return;
    }

    this.props.onSelectDropdownItem(item);

    this.setState({
      isOpen: false,
    });
  }

  onReset() {
    if (this.props.isMulti) {
      this.props.onSelectDropdownItem([]);
    } else {
      this.props.onSelectDropdownItem({});
    }

    this.props.handleReset && this.props.handleReset();

    this.setState({
      isOpen: false,
    });
  }

  returnLabel(value) {
    let label = "",
      data = this.props.options ? this.props.options : [];

    for (let i = 0; i < data.length; i++) {
      if (data[i].value === value || data[i].label === value) {
        label = data[i].label;
        break;
      }
    }

    return label;
  }
  render() {
    let props = this.props;
    let title = "";

    return (
      <>
        <div className="dropdown " style={{ position: "initial" }}>
          <button
            className={`btn btn-primary dropdown-toggle dropdown-toggle-split ${
              props.className
            } ${props.isSubmitting && "btn-disabled"}`}
            id={props.id ? props.id : "dropdownMenuButton1"}
            data-bs-toggle="dropdown"
            aria-expanded="false"
            data-bs-auto-close="outside"
            styles={props.styles}
          >
            <div
              onClick={this.handleOpen.bind(this)}
              style={{ display: "flex", alignItems: "center" }}
              title={title}
            >
              {this.props.leftIcon && (
                <span className={`${process.env.REACT_APP_ICONS_TYPE} icon`}>
                  {this.props.leftIcon}
                </span>
              )}

              {this.props.leftImage && (
                <div className="left-img-container">
                  <img
                    className="left-img"
                    alt={title}
                    src={this.props.leftImage}
                  />
                </div>
              )}

              <span style={{ marginLeft: 5, marginRight: 5 }}>
                {props.text}
              </span>
              {props.selected_item && title && (
                <div
                  className="search-dropdown-text-selected"
                  style={{ marginRight: 5 }}
                >
                  <b>: {title}</b>
                </div>
              )}
            </div>
          </button>
          <div
            className="dropdown-menu"
            role="menu"
            aria-labelledby="dropdownMenuClickableInside"
          >
            {this.props.children}
          </div>
        </div>
      </>
    );
  }
}

export default DropdownButton;
