import toastMessage from "../../toastMessage";
import { getStorage } from "../../storage";
import language from "../../../language";
import { defineRole } from "../../handleAccessRoles";
import { ROLE_APPLICATION_CONFIGURATION } from "../../../constants/ROLES";
import api from "../../api";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

export const fetchConfigurationsData = async () => {
  try {
    const user = await getStorage();

    const canAccess = defineRole({
      roles: user.accessRole,
      menu: ROLE_APPLICATION_CONFIGURATION,
      operation: "read",
    });

    if (!canAccess) return;

    const options = {
      method: "GET",
      url: `${API_URL}/configuration`,
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await api(options);

    return queryRes.data;
  } catch (error) {
    toastMessage("error", error);
  }
};

export const deleteConfigurations = async (lng = "english", params) => {
  try {
    const user = await getStorage();

    const options = {
      method: "PUT",
      url: `${API_URL}/configuration`,
      data: {
        ...params,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    await api(options);

    toastMessage("success", language[lng].success_delete_data);
  } catch (error) {
    toastMessage("error", error);
  }
};
