import React from "react";
import { connect } from "react-redux";
import language from "../../language/index.js";
import { Table } from "../Table/index.js";
import { CSVLink } from "react-csv";
import bloodUsageQuery, {
  deleteBloodUsage,
} from "../../utils/queries/bloodUsageQuery.js";
import exportPDF from "../../utils/exportPDF.js";
import handleSearch from "../../utils/handleSearch.js";
import NewUsage from "./NewUsage.js";
import { DeleteModal, Modal } from "../Modal/index.js";
import { defineRole } from "../../utils/handleAccessRoles.js";
import { getStorage } from "../../utils/storage.js";
import RecipientInfo from "../BloodRecipient/RecipientInfo.js";

let copyData = [];

class BloodUsage extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selectedData: {},
    delete_value: "",
    error: {},
    csvData: [],
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });
    await this.getData(true);
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e?.target?.value,
      error,
    });
  }

  getData = async (isLoading) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const { data, count } = await bloodUsageQuery(defaultLanguage, {
        page,
        limit,
      });

      this.setState({
        data: data,
        totalPageCount: count,
        isLoading: false,
      });

      copyData = data.slice(0);
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleShowModal(modal, modalTitle, selectedData = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selectedData,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].product,
        key: "product.code",
      },
      {
        title: language[this.props.defaultLanguage].donor,
        key: "donation.donor.donorNumber",
        type: "link",
        onPress: (item) =>
          (window.location.href = `/dashboard/donors/${item.donation.donor._id}/${item.donation.donor.firstName} ${item.donation.donor.lastName}`),
      },
      {
        title: language[this.props.defaultLanguage].donation,
        key: "donation.donationNumber",
        type: "link",
        onPress: (item) =>
          (window.location.href = `/dashboard/donations/${item.donation._id}/${item.donation.donor._id}/${item.donation.donor.firstName} ${item.donation.donor.lastName}`),
      },
      {
        title: language[this.props.defaultLanguage].patient_id,
        key: "patient.patientId",
        type: "link",
        onPress: (item) =>
          (window.location.href = `/dashboard/patients/${item.patient._id}`),
      },
      {
        title: language[this.props.defaultLanguage].reason,
        key: "reason",
      },
      {
        title: language[this.props.defaultLanguage].hospital,
        key: "hospital.name",
      },
      {
        title: language[this.props.defaultLanguage].usage_date,
        key: "usageDate",
        isMoment: true,
        formatTime: "lll",
      },
      {
        title: language[this.props.defaultLanguage].actions,
        key: "action",
      },
    ];

    return headers;
  }

  downloadExcel = async () => {
    try {
      this.setState({
        isLoading: true,
      });

      const { page, limit } = this.state;
      const { defaultLanguage } = this.props;

      const { data } = await bloodUsageQuery(defaultLanguage, {
        page,
        limit,
      });

      this.setState({
        isLoading: false,
        csvData: data,
      });

      this.refs.csvDownload?.link.click();
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  downloadPDF = async () => {
    this.setState({
      isLoading: true,
    });

    const { page, limit } = this.state;
    const { defaultLanguage } = this.props;

    const headers = this.returnTableHeaders();

    const { data } = await bloodUsageQuery(defaultLanguage, {
      page,
      limit,
    });

    this.setState({
      isLoading: false,
    });

    exportPDF("Blood Usage", headers, data);
  };

  onDelete = async () => {
    try {
      this.setState({ isDeleting: true });

      await deleteBloodUsage(
        this.props.defaultLanguage,
        this.state.selectedData._id
      );

      this.setState({ isDeleting: false });

      this.getData(true);
      this.handleCloseModal("showDeleteModal");
    } catch (error) {
      console.log(error);
      this.setState({
        isDeleting: false,
      });
    }
  };

  render() {
    console.log("this.state?.selectedData", this.state?.selectedData);
    return (
      <>
        <Table
          data={this.state.data}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          handleSearch={(e) =>
            handleSearch(this, {
              copyData,
              value: e?.target?.value,
            })
          }
          showAdd
          addButtonText={language[this.props.defaultLanguage].add}
          handleAddPressed={this.handleShowModal.bind(
            this,
            "showModal",
            language[this.props.defaultLanguage].blood_usage
          )}
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
          rowPress={(item) =>
            this.handleShowModal("showModal", item.name, item)
          }
          // filters={[
          //   {
          //     type: "export",
          //     title: language[this.props.defaultLanguage].export_,
          //     button_type: "dropdown",
          //     icon: "bx bxs-download",
          //     options: [
          //       {
          //         name: "PDF",
          //         onPress: this.downloadPDF.bind(this),
          //       },
          //       {
          //         name: "CSV",
          //         onPress: this.downloadExcel.bind(this),
          //       },
          //     ],
          //   },
          // ]}
          actions={[
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: "BloodUsage",
                operation: "update",
              }),
              name: language[this.props.defaultLanguage].edit,
              onPress: (item) =>
                this.handleShowModal(
                  "showModal",
                  language[this.props.defaultLanguage].edit,
                  item
                ),
            },
            ,
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: "BloodUsage",
                operation: "delete",
              }),
              name: language[this.props.defaultLanguage].delete_,
              onPress: this.handleShowModal.bind(
                this,
                "showDeleteModal",
                language[this.props.defaultLanguage].delete_account
              ),
            },
          ]}
        />
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showModal")}
          show={this.state.showModal}
          title={this.state.modalTitle}
          showHeaderBottomBorder={false}
        >
          <NewUsage
            handleCloseModal={this.handleCloseModal.bind(this, "showModal")}
            getData={this.getData.bind(this)}
            {...this.state.selectedData}
          />
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(
            this,
            "showRecipientInfoModal"
          )}
          show={this.state.showRecipientInfoModal}
          title={this.state.modalTitle}
          showHeaderBottomBorder={false}
        >
          <div className="card-body">
            <RecipientInfo id={this.state?.selectedData?._id} />
          </div>
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showDeleteModal")}
          show={this.state.showDeleteModal}
          title={this.state.modalTitle}
        >
          <DeleteModal
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showDeleteModal"
            )}
            error={this.state.error.delete_password}
            value={this.state.delete_value}
            onDelete={this.onDelete.bind(this)}
            onChange={(e) => this.onChangeText("delete_value", e)}
            isDeleting={this.state.isDeleting}
            name={this.state.selectedData.name}
            getData={this.getData.bind(this)}
          />
        </Modal>
        <CSVLink
          ref="csvDownload"
          filename={"Blood Usage" + new Date().getTime()}
          data={this.state.csvData}
        ></CSVLink>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(BloodUsage);
