import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../../Input/index.js";
import language from "../../../language/index.js";
import icons from "../../../constants/icons.js";
import { Button } from "../../Button/index.js";
import toastMessage from "../../../utils/toastMessage.js";
import { fetchBloodBanks } from "../../../utils/queries/bloodBank.js";
import { getStorage } from "../../../utils/storage.js";
import axios from "axios";

const statuses = [
  {
    label: "Pending",
    value: "pending",
  },
];

class UpdateShipping extends React.Component {
  state = {
    error: {},
    data: [],
    isDoneSearching: false,
    donationNumber: "",
    donation: {},
    inventories: [],
    sourceStocks: [],
    inventory: "",
  };

  componentDidMount() {
    if (this.props._id) {
      this.setState({
        ...this.props,
        donation: this.props.donation
          ? {
              ...this.props.donation,
              label: this.props.donation.donationNumber,
              value: this.props.donation._id,
            }
          : null,
        source: this.props.from
          ? {
              label: this.props.from.code + " " + this.props.from.name,
              value: this.props.from._id,
            }
          : null,
        bloodBank: this.props.to
          ? {
              label: this.props.to.code + " " + this.props.to.name,
              value: this.props.to._id,
            }
          : null,
        product: this.props.product,
        donationNumber: this.props.donation.donationNumber,
        status: this.props.status
          ? {
              label: this.props.status,
              value: this.props.status,
            }
          : null,
      });
    }
  }

  getBloodBanks = async (isFetchingBloodBanks, search = {}) => {
    try {
      this.setState({
        isFetchingBloodBanks,
      });

      const data = await fetchBloodBanks(this.props.defaultLanguage, {
        page: 1,
        limit: 50,
        ...search,
        type: "dropdown",
      });

      this.setState({
        isFetchingBloodBanks: false,
        sourceStocks: data,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingBloodBanks: false });
      toastMessage("error", error);
      console.log(error);
    }
  };

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e.target ? e?.target?.value : e;

    delete error[field];

    this.setState({
      [field]: inputValue,
      isSearching: false,
    });
  };

  handleShowModal(modal, title, item = {}) {
    this.setState({
      [modal]: true,
      modalTitle: title,
      ...item,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  onReset() {
    this.setState({
      donationNumber: "",
      donation: {},
      inventories: [],
    });
  }

  validateTransfer = () => {
    const { error, donation, bloodBank, product, shipmentNumber, source } =
      this.state;

    if (!donation || !donation?._id) {
      error.source = "No donation found";
    }

    if (!bloodBank) {
      error.bloodBank = "Destination stock not found";
    }

    if (!product) {
      error.product = "Product is required";
    }

    if (shipmentNumber === "") {
      error.shipmentNumber = "Shipment number is required";
    }

    if (!source) {
      error.source = "Inventory is required";
    }

    this.setState({
      error,
    });
  };

  onSubmit = async () => {
    try {
      const {
        error,
        bloodBank,
        product,
        source,
        shipmentNumber,
        donation,
        status,
      } = this.state;

      const user = await getStorage();

      const { _id } = this.props;

      await this.validateTransfer();

      if (Object.keys(error).length > 0) return;

      try {
        this.setState({ isSubmitting: true, isRefreshing: false });

        let method = "POST",
          requestBody = {
            donation: donation._id,
            shipmentNumber,
            product,
            to: bloodBank.value,
            from: source.value,
            inventory: source._id,
            status: status?.value,
          };

        if (_id || _id !== "") {
          method = "PUT";
          requestBody.id = _id;
        }

        const options = {
          method,
          url: `${process.env.REACT_APP_BBMS_BASE_API}/shipping-queue`,
          data: requestBody,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + user.token,
          },
        };

        await axios(options);

        toastMessage(
          "success",
          language[this.props.defaultLanguage][
            _id ? "update_data_success" : "add_data_success"
          ]
        );

        this.setState({
          isSubmitting: false,
          isRefreshing: true,
        });
        this.props.handleCloseModal && this.props.handleCloseModal();
      } catch (error) {
        toastMessage("error", error);

        this.setState({
          isSubmitting: false,
        });
      }

      this.setState({ isSubmitting: false });
      this.props.getData();
    } catch (error) {
      toastMessage("error", error);
    }
  };

  render() {
    return (
      <>
        <div className="card-body">
          <form className="row">
            <div className="col-md-6">
              <Input
                placeholder={
                  language[this.props.defaultLanguage]
                    .shipment_number_placeholder
                }
                label={language[this.props.defaultLanguage].shipment_number}
                value={this.state.shipmentNumber}
                onChange={(e) => this.onChangeText("shipmentNumber", e)}
                error={this.state.error.shipmentNumber}
                disabled
              />
            </div>
            <div className="col-md-6">
              <Input
                placeholder={
                  language[this.props.defaultLanguage]
                    .donation_number_placeholder
                }
                label={language[this.props.defaultLanguage].donation_number}
                value={this.state.donationNumber}
                onChange={(e) => this.onChangeText("donationNumber", e)}
                error={this.state.error.donationNumber}
                disabled
              />
            </div>
            <div className="col-md-6">
              <Select
                options={this.state.sourceStocks}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].source}
                value={this.state.source}
                onChange={(e) => this.onChangeText("source", e)}
                error={this.state.error.source}
                leftIcon={icons.source}
              />
            </div>
            <div className="col-md-6">
              <Select
                loadOptions={(e) =>
                  this.getBloodBanks(true, {
                    q: e,
                  })
                }
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].destination}
                value={this.state.bloodBank}
                onChange={(e) => this.onChangeText("bloodBank", e)}
                error={this.state.error.bloodBank}
                leftIcon={icons.bloodBank}
              />
            </div>
            <div className="col-md-12">
              <Input
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].product}
                value={this.state.product}
                onChange={(e) => this.onChangeText("product", e)}
                error={this.state.error.product}
                leftIcon={icons.product}
              />
            </div>
            <div className="col-md-12">
              <Select
                options={statuses}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].status}
                value={this.state.status}
                onChange={(e) => this.onChangeText("status", e)}
                error={this.state.error.status}
                leftIcon={icons.status}
              />
            </div>
            <div className="modal-footer">
              <Button
                text={language[this.props.defaultLanguage].cancel}
                onPress={(e) => {
                  e.preventDefault();
                  this.props.handleCloseModal && this.props.handleCloseModal();
                }}
                className="btn-default"
                type="button"
              />
              <Button
                text={language[this.props.defaultLanguage].submit}
                isSubmitting={this.state.isSubmitting}
                onPress={(e) => {
                  e.preventDefault();
                  this.onSubmit();
                }}
              />
            </div>
          </form>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(UpdateShipping);
