import React from "react";
import { connect } from "react-redux";
import Button from "./Button";
import language from "../../language";
import toastMessage from "../../utils/toastMessage";

class ButtonToClipboard extends React.Component {
  state = {
    loading: false,
  };

  copyToClipboard = async () => {
    try {
      console.log("Attempting to copy to clipboard...");

      // Ensure Clipboard API support
      if (!navigator.clipboard) {
        throw new Error("Clipboard API is not supported on this browser.");
      }

      // Attempt to copy the text to clipboard
      await navigator.clipboard.writeText(this.props.textToCopy);

      this.setState({ loading: false });
      toastMessage("success", "Text copied to clipboard!");
    } catch (error) {
      console.error("Error copying text to clipboard:", error);
      toastMessage("error", `Failed to copy: ${error.message || error}`);
      this.setState({ loading: false });
    }
  };

  render() {
    return (
      <div>
        <Button
          className="btn-xsm"
          text={language[this.props.defaultLanguage].copy}
          onPress={this.copyToClipboard.bind(this)}
          isSubmitting={this.state.loading}
        />
      </div>
    );
  }
}

const mapPropsTopProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapPropsTopProps)(ButtonToClipboard);
