import React from "react";
import { connect } from "react-redux";
import { Input } from "../Input";
import language from "../../language";
import bloodDonationQuery from "../../utils/queries/bloodDonationQuery";
import icons from "../../constants/icons";
import { Button } from "../Button";
import BarcodeScannerInput from "../Barcode/BarcodeScannerInput";
import toastMessage from "../../utils/toastMessage";
import { getStorage } from "../../utils/storage";
import axios from "axios";
import { generateRandomCode } from "../../utils/random";

class NewBloodTest extends React.Component {
  state = {
    error: {},
    data: {},
    isDoneSearching: false,
    donation: "",
  };

  validateForm() {
    let { error, donation } = this.state;

    if (donation === "") {
      error.donation =
        language[this.props.defaultLanguage].donation_number_required;
    }

    this.setState({ error });
  }

  handleDonationSearch = async (isSubmitting) => {
    await this.validateForm();

    try {
      if (Object.keys(this.state.error).length > 0) return;

      const { donation } = this.state;

      this.setState({
        isSubmitting,
        isDoneSearching: false,
      });

      const data = await bloodDonationQuery(this.props.defaultLanguage, {
        type: "dropdown",
        donationNumber: donation?.replace(/\s+/g, ""),
      });

      this.setState({
        isSubmitting: false,
        isDoneSearching: true,
      });

      let res = {};

      if (data.length > 0) {
        res = data[0];
      }

      this.setState({
        data: res,
      });
    } catch (error) {
      this.setState({ isSubmitting: false, isDoneSearching: false });

      toastMessage("error", error);
    }
  };

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e.target ? e?.target?.value : e;

    delete error[field];

    this.setState({
      [field]: inputValue,
      isDoneSearching: false,
    });
  };

  onReset() {
    this.setState({
      data: {},
      donationNumber: "",
    });
  }

  validateAsyncForm() {
    const { data, error } = this.state;

    if (!data._id) {
      error.donorNumber =
        language[this.state.defaultLanguage].donor_number_required;
      error.donor = language[this.state.defaultLanguage].donor_required;
    }

    this.setState({
      error,
    });
  }

  onSubmit = async (e) => {
    e.preventDefault();

    await this.validateAsyncForm();

    const { error, data } = this.state;

    if (Object.keys(error).length > 0) return;

    const user = await getStorage();

    this.setState({
      isSubmitting: true,
    });

    const options = {
      url: `${process.env.REACT_APP_BBMS_BASE_API}/bloodTest`,
      method: "POST",
      data: {
        code: generateRandomCode(8),
        centerSite: data?.centerSite._id,
        center: data.center._id,
        donor: data.donor._id,
        donation: data._id,
      },
      headers: {
        authorization: `Bearer ${user.token}`,
      },
    };

    await axios(options)
      .then((data) => {
        this.setState({
          isSubmitting: false,
        });

        toastMessage(
          "success",
          language[this.props.defaultLanguage].add_data_success
        );

        this.props.handleCloseModal();
        this.props.getData(true);
      })
      .catch((error) => {
        this.setState({
          isSubmitting: false,
        });

        toastMessage("success", error);
      });
  };

  render() {
    return (
      <div>
        <div>
          {this.state.error.errorMessage && (
            <div className="alert alert-danger">
              <span>{this.state.error.errorMessage}</span>
            </div>
          )}
          <div className="row">
            <form>
              <div className="card-body">
                <div className="col-md-12">
                  <Input
                    placeholder={
                      language[this.props.defaultLanguage]
                        .donation_number_placeholder
                    }
                    label={language[this.props.defaultLanguage].donation_number}
                    value={this.state.donation}
                    onChange={(e) => this.onChangeText("donation", e)}
                    error={this.state.error.donation}
                    leftIcon={icons.user}
                  />
                  {!this.state?.data?._id && (
                    <Button
                      text={language[this.props.defaultLanguage].search}
                      isSubmitting={this.state.isSubmitting}
                      onPress={this.handleDonationSearch.bind(this)}
                    />
                  )}
                </div>
              </div>
            </form>
            {this?.state?.data?._id && (
              <form>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <Input
                        placeholder={
                          language[this.props.defaultLanguage]
                            .donation_number_placeholder
                        }
                        label={
                          language[this.props.defaultLanguage].donation_number
                        }
                        value={this.state.data?.donationNumber}
                        onChange={(e) => this.onChangeText("donationNumber", e)}
                        error={this.state.error.donationNumber}
                        disabled
                      />
                    </div>
                    <div className="col-md-6">
                      <Input
                        placeholder={
                          language[this.props.defaultLanguage]
                            .donor_number_placeholder
                        }
                        label={
                          language[this.props.defaultLanguage].donor_number
                        }
                        value={this.state.data.donor?.donorNumber}
                        onChange={(e) => this.onChangeText("donorNumber", e)}
                        error={this.state.error.donorNumber}
                        disabled
                      />
                    </div>
                    <div className="col-md-6">
                      <Input
                        placeholder={
                          language[this.props.defaultLanguage].donor_placeholder
                        }
                        label={language[this.props.defaultLanguage].first_name}
                        value={this.state.data.donor?.firstName}
                        onChange={(e) => this.onChangeText("firstName", e)}
                        error={this.state.error.donor}
                        disabled
                      />
                    </div>
                    <div className="col-md-6">
                      <Input
                        placeholder={
                          language[this.props.defaultLanguage].donor_placeholder
                        }
                        label={language[this.props.defaultLanguage].last_name}
                        value={this.state.data.donor?.lastName}
                        onChange={(e) => this.onChangeText("lastName", e)}
                        error={this.state.error.lastName}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <Button
                    text={language[this.props.defaultLanguage].reset}
                    onPress={this.onReset.bind(this)}
                    className="btn-default"
                  />
                  <Button
                    text={language[this.props.defaultLanguage].submit}
                    isSubmitting={this.state.isSubmitting}
                    onPress={(e) => this.onSubmit(e)}
                  />
                </div>
              </form>
            )}
          </div>
        </div>
        <BarcodeScannerInput onScan={(txt) => this.handleScan(txt)} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewBloodTest);
