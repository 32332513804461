import React from "react";
import icons from "../../../constants/icons";
import { Button } from "../../Button";
import { Input } from "../../Input";
import { Title } from "../Title";
import "./styles.css";
import language from "../../../language";
import centersVector from "../../../assets/hospital-vector.png";
import { connect } from "react-redux";

class WhereDonate extends React.Component {
  state = {
    searchText: "",
  };

  onChangeText(e) {
    this.setState({ searchText: e?.target?.value });
  }

  render() {
    return (
      <div className="where-donate-container">
        <div className="row">
          <div className="col-sm-12 col-md-4">
            <Title
              title={language[this.props.defaultLanguage].where_can_donate}
              description={
                language[this.props.defaultLanguage].find_below_sites
              }
              className="light"
            />
            <Input
              leftIcon={icons.search}
              placeholder={
                language[this.props.defaultLanguage].find_nearest_sites
              }
              value={this.state.searchText}
              onChange={(e) => this.onChangeText(e)}
            />
            <Button
              styles={{ marginBottom: "1rem" }}
              className="btn-lg w-100"
              text={language[this.props.defaultLanguage].search}
              onPress={() =>
                (window.location.href = "/centers?q=" + this.state.searchText)
              }
            />
            <Button
              className="btn-lg w-100 btn-transparent light"
              text={language[this.props.defaultLanguage].view_all}
              onPress={() =>
                (window.location.href = "/centers" + this.state.searchText)
              }
            />
          </div>
          <div className="col-md-4 hidden-sm hidden-xs"></div>
          <div className="col-sm-12 col-md-4">
            <div className="landing-section-img">
              <img src={centersVector} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(WhereDonate);
