import toastMessage from "../../../toastMessage";
import { getStorage } from "../../../storage";
import language from "../../../../language";
import api from "../../../api";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

export const fetchAllComponents = async (lng = "english", params) => {
  try {
    const user = await getStorage();

    if (!params.code) {
      delete params.code;
    }

    const options = {
      method: "GET",
      url: `${API_URL}/components`,
      params,
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await api(options);

    let { data, count } = queryRes.data;

    if (params.inputType === "dropdown") {
      return (queryRes?.data?.data || queryRes?.data).map((el) => {
        return {
          ...el,
          label: el.code + "-" + el.name,
          value: el._id,
        };
      });
    }

    return !params.page && !params.limit ? queryRes.data : { data, count };
  } catch (error) {
    toastMessage("error", error);
  }
};

export const fetchComponentsSeparation = async (lng = "english", params) => {
  try {
    const user = await getStorage();

    const options = {
      method: "GET",
      url: `${API_URL}/components`,
      params,
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await api(options);

    let { data, count } = queryRes.data;

    if (params.inputType === "dropdown") {
      return (queryRes?.data?.data || queryRes?.data).map((el) => {
        return {
          ...el,
          label: el.code + " - " + el.name,
          value: el._id,
        };
      });
    }

    return !params.page && !params.limit ? queryRes.data : { data, count };
  } catch (error) {
    toastMessage("error", error);
  }
};

export const deleteComponentsSeparation = async (lng = "english", id) => {
  try {
    const user = await getStorage();

    const options = {
      method: "DELETE",
      url: `${API_URL}/components`,
      data: {
        id,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    await api(options);

    toastMessage("success", language[lng].delete_data_success);
  } catch (error) {
    toastMessage("error", error);
  }
};
